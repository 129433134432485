import React from 'react'

const PageNotFound = () => {
    return (
        <>
            <div className='page-not-found'>
                <div>Page Not Found</div>
            </div>
        </>
    )
}

export default PageNotFound


import React, { useEffect, useState, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form';
import { detailsField } from '../../services/employeeFieldType';
import './addTeamProject.css'
import CloseIcon from '@mui/icons-material/Close';
import FetchApi from '../../fetch-api/fetch-api';
import { useSelector } from 'react-redux';
import { createTeam, searchEmployeeByLetter, updateTeamDetails } from '../../api-services/employee-services';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { SnackbarService } from '../../services/snackBehaviorSubject/snackBehaviorSubject';
import { TokenService } from '../../services/token.service';
import { Role } from '../../model/token-model/role.model';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import ButtonWrapper from '../../widgets/button/button';

interface FieldValueProps {
  projectId?: string,
  projectName: string,
  teamName: string,
  projectClient: string,
  projectDetails: string,
  projectDemoDate: string,
  projectWorkingStatus: string,
  projectStartDate: string,
  projectDeliveryDate: string,
  projectLead: any,
  projectFrontEndMembers: string[],
  projectBackEndMembers: string[],
  teamMembers: string[],
  projectRemark: string,
}

interface FieldProps {
  name: string;
  type: string;
  label: string;
  id: number;
}

const fieldMapping = {
  projectFrontEndMembers: 'projectFrontEndMembers',
  projectBackEndMembers: 'projectBackEndMembers',
  teamMembers: 'teamMembers',
  projectLead: 'projectLead'
};

interface Option {
  name: string;
}

interface addTeamProjectProps {
  onClose: () => void;
  actionStatus?: boolean;
  data?: any;
  handleModalClose?: (flag: boolean) => void;
}

const AddTeamProject = (props: addTeamProjectProps) => {
  const snackbarService = SnackbarService()
  const state = useSelector((state: any) => state.team)
  const fetchApi = FetchApi()
  const [isSearchCall, setIsSearchCall] = useState<boolean>(false)
  const [fieldValue, setFieldValue] = useState<FieldValueProps>({
    projectId: '',
    projectName: "",
    teamName: "",
    projectClient: "",
    projectDetails: "",
    projectDemoDate: "",
    projectWorkingStatus: "",
    projectStartDate: "",
    projectDeliveryDate: "",
    projectLead: { "name": '', "employee_id": '' },
    projectFrontEndMembers: [],
    projectBackEndMembers: [],
    teamMembers: [],
    projectRemark: ''
  })

  const [optionData, setOptionData] = useState<any[]>([]);
  const [propsDatas, setPropsData] = useState<FieldValueProps>()
  const field: FieldProps[] = detailsField().newProjectdetails
  const { control, handleSubmit } = useForm();
  const [propsState, setProps] = useState<any>()
  const [loader, setLoader] = useState<boolean>(false);
  const [fieldItem, setFieldItem] = useState<any[]>([]);


  const getSelectedValues = (fieldName: any, fieldValue: any) => {
    switch (fieldName) {
      case fieldMapping.projectFrontEndMembers:
        return fieldValue.projectFrontEndMembers;
      case fieldMapping.projectBackEndMembers:
        return fieldValue.projectBackEndMembers;
      case fieldMapping.teamMembers:
        return fieldValue.teamMembers;
      case fieldMapping.projectLead:
        return [fieldValue.projectLead];
      default:
        return [];
    }
  };

  useEffect(() => {
    setProps(props)
  }, [props])

  console.log(props, "Props")

  useEffect(() => {
    const filteredDetails = field.filter((detail: FieldProps) => {
      return state.team_config.some((condition: any) => {
        return detail.id === condition.id && detail.name === condition.name;
      });
    });

    console.log(filteredDetails);
    setFieldItem(filteredDetails)
  }, [state])


  useEffect(() => {
    if (propsDatas !== undefined) {
      const output_data1: FieldValueProps = state.team_config.reduce((result: FieldValueProps, item: any) => {
        if (item.name === "projectLead" && Array.isArray(propsDatas[item.name as keyof FieldValueProps])) {
          result[item.name as keyof FieldValueProps] = propsDatas[item.name as keyof FieldValueProps][0];
        } else {
          result[item.name as keyof FieldValueProps] = propsDatas[item.name as keyof FieldValueProps];
        }

        return result;
      }, {});
      output_data1.projectId = propsDatas["projectId"];
      setFieldValue(output_data1)

    }
    console.log(propsDatas)
  }, [propsDatas])

  useEffect(() => {
    if (propsState?.data && !isSearchCall) {
      setIsSearchCall(true)
      const propsData = { ...propsState?.data }
      searchApiCall(propsData.projectLead, true).then((res: any) => {
        propsData.projectLead = res
        setPropsData(propsData)
      })
    } else {
    }
  }, [propsState])

  const handleField = (e: any) => {
    const { value, name } = e.target
    const selectedValues = value
    if (name !== 'projectMembersId' || name !== 'projectLeadId') {
      setFieldValue((preav: any) => {
        return { ...preav, [name]: value }
      })
    } else if (name === 'projectMembersId') {
      setFieldValue((prevPersonName) => ({
        ...prevPersonName,
        projectMembersId: Array.isArray(selectedValues) ? selectedValues : [selectedValues]
      }));
    } else {
      setFieldValue((prevPersonName) => ({
        ...prevPersonName,
        projectLeadId: selectedValues
      }));
    }

  }

  const onSubmit = (data: any) => {
    if (!propsState.actionStatus) {
      const extractedData: { [key: string]: any } = {};
      state.team_config.forEach((item: any) => {
        if (fieldValue.hasOwnProperty(item.name)) {
          extractedData[item.name] = fieldValue[item.name as keyof FieldValueProps];
        }
      });

      createTeam(extractedData).then((response: any) => {
        if (response !== null && response?.data.success) {
          snackbarService.next({ data: { open: true, severity: 'success', message: response.data.message } })
          setFieldValue({
            projectName: "",
            teamName: "",
            projectClient: "",
            projectDetails: "",
            projectDemoDate: "",
            projectWorkingStatus: "",
            projectStartDate: "",
            projectDeliveryDate: "",
            projectLead: [],
            projectFrontEndMembers: [],
            projectBackEndMembers: [],
            teamMembers: [],
            projectRemark: ''
          })
          fetchApi.fetchAdminTeam()
        } else {

          snackbarService.next({ data: { open: true, severity: 'error', message: response?.data.message } })
        }
      })
    } else {
      updateTeamDetails(fieldValue.projectId, fieldValue).then((response: any) => {
        if (response?.data.success) {
          snackbarService.next({ data: { open: true, severity: 'success', message: response.data.message } })
          if (TokenService.getRole() === Role.ADMIN || Role.COREMEMBERS) {
            fetchApi.fetchAdminTeam()
          } else {
            fetchApi.fetchUserTeam()
          }

          props.onClose()
        } else {
          snackbarService.next({ data: { open: true, severity: 'error', message: response.data.message } })
        }
      })
    }

  }

  const handleAutocompleteChange = (e: any, newValue: any, field: any) => {
    if (field === 'projectLead') {
      setFieldValue((prevPersonName) => ({
        ...prevPersonName,
        projectLead: newValue
      }));
    } else if (field === 'projectFrontEndMembers') {
      setFieldValue((prevPersonName) => ({
        ...prevPersonName,
        projectFrontEndMembers: newValue
      }));
    } else if (field === 'teamMembers') {
      setFieldValue((prevPersonName) => ({
        ...prevPersonName,
        teamMembers: newValue
      }));
    }
    else {
      setFieldValue((prevPersonName) => ({
        ...prevPersonName,
        projectBackEndMembers: newValue
      }));
    }



  }
  const searchApiCall = async (newValue: any, flag?: boolean) => {
    const result = newValue
    let val;



    try {
      const response: any = await searchEmployeeByLetter(result);

      if (response && response?.data.success) {
        setLoader(false)
        if (flag === true) {
          val = response.data.payload;
        } else {
          setOptionData(response.data.payload);
        }
      } else {
        setOptionData([]);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
    if (flag === true) {
      return val
    }
  }

  const getData = (d1: any, d2: any) => {
    searchApiCall(d1, false)
  }
  const debounceTime = function <T>(fn: Function, delay: number) {
    let timer: NodeJS.Timeout;
    return function (this: T, ...args: any[]) {
      setLoader(true)
      clearTimeout(timer);
      timer = setTimeout(() => {
        fn.apply(this, args);
      }, delay);
    }
  }

  const onSearchData = debounceTime(getData, 1000);

  const handleInputChange = (e: any, newValue: any) => {
    if (e && newValue !== "") {
      onSearchData(newValue)
    } else {
      setOptionData([])
    }
  }

  console.log()

  let selectedValues: Option[];

  return (
    <div className='main'>
      <div className='modal-heading'>
        <h4>{!propsState?.actionStatus ? 'Add' : 'Edit'} Project Details</h4>
        <CloseIcon
          className='modal-close-button'
          onClick={propsState?.onClose}
        />
      </div>
      <div className='modal-body'>
        <form onSubmit={handleSubmit(onSubmit)} className='container-100'>
          <div className='team-create-form-container'>

            {fieldItem.map((ele: any, index: number) => {
              return (
                <React.Fragment key={index}>
                  {ele.type !== 'select' ? <>
                    < label className='team-form-container-label'>{ele.label}</label>
                    <Controller
                      name={ele.name}
                      control={control}
                      render={({ field }) => (

                        <>
                          <input
                            type={ele.type}
                            {...field}
                            onChange={handleField}
                            value={fieldValue[ele.name as keyof FieldValueProps]}
                            required={ele.name !== 'projectDemoDate'}
                          />

                        </>
                      )}
                    />
                  </> : <>
                    {ele.type === 'select' && !ele.options ? <div className='select-type-container'>
                      <label className='label'>{ele.label}</label>
                      <Autocomplete
                        limitTags={2}
                        multiple={ele.type === 'select' && ele.name !== 'projectLead'}
                        id="checkboxes-tags-demo"
                        options={loader === true ? [{ name: 'Loading...' }] : optionData.filter((option) => {
                          selectedValues = getSelectedValues(ele.name, fieldValue);
                          return !selectedValues.some((selected: { name: any; }) => selected?.name === option.name);
                        })}
                        disableCloseOnSelect
                        disablePortal
                        getOptionLabel={(option) => option?.name || ''}
                        onChange={(e, newValue) => handleAutocompleteChange(e, newValue, ele.name)}
                        onInputChange={(e, newValue) => handleInputChange(e, newValue)}
                        value={ele.type === 'select' ? fieldValue[ele.name as keyof FieldValueProps] : ''}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            {option.name}
                          </li>
                        )}
                        renderInput={(params) => (
                          <TextField {...params}
                            required={ele.type === 'select' &&
                              ((ele.name !== 'teamMembers' || fieldValue.teamMembers.length === 0) && ((!fieldValue[ele.name as keyof FieldValueProps] || fieldValue[ele.name as keyof FieldValueProps].length <= 0) || !fieldValue.projectLead || !fieldValue.projectLead.name || !fieldValue.projectLead.employee_id))}
                          />
                        )}
                      />


                    </div> :
                      <div className='select-type-container'>
                        <label id="demo-simple-select-label" className='label'>Status</label>
                        <Select
                          style={{ height: '40px' }}
                          name={ele.name}
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={ele.type === 'select' ? fieldValue[ele.name as keyof FieldValueProps] : ''}
                          onChange={handleField}
                          required
                        >
                          {
                            ele.options.map((element: any, ind: number) => {
                              return <MenuItem value={element}>{element}</MenuItem>
                            })
                          }

                        </Select>
                      </div>
                    }
                  </>}
                </React.Fragment>
              )
            })

            }
          </div>
          <div className='team-buttom-container'>
            {/* <button
              type='submit'
              className='button-style'
            >{propsState?.actionStatus ? 'Update' : 'Submit'}</button> */}
            <ButtonWrapper title={`${propsState?.actionStatus ? 'Update' : 'Submit'}`} id='submit-button' variant='contained' type='submit' />
          </div>
        </form>
      </div>
    </div >
  )
}

export default AddTeamProject
import React, { useEffect, useMemo, useState } from 'react'
import './addTask.css'
import CloseIcon from '@mui/icons-material/Close';
import { FormControl, InputLabel, MenuItem, PopperProps, Select, styled, TextareaAutosize } from '@mui/material';
import { useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { TokenService } from '../../services/token.service';
import { Role } from '../../model/token-model/role.model';
import ButtonWrapper from '../../widgets/button/button';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { createTask } from '../../api-services/employee-services';
import { SnackbarService } from '../../services/snackBehaviorSubject/snackBehaviorSubject';
import { message } from 'antd';
import FetchApi from '../../fetch-api/fetch-api';
import { Popper } from '@mui/material';
import { ProjectStatusModel } from '../../model/project-status-model/project-status-model';
import { detailsField } from '../../services/employeeFieldType';

interface AddTaskProps {
    onClose: () => void;
    actionStatus?: boolean;
    data?: any;
    handleModalClose?: (flag: boolean) => void;
    title: string;
    getTask?: () => void;
}

const CustomPopper = styled((props: PopperProps) => (
    <Popper {...props} placement="top-start" />
))({
    zIndex: 1300, // Adjust this value as needed to ensure it appears above the modal
});

const AddTask = (props: AddTaskProps) => {

    console.log(props.actionStatus, "Action Sattaussssssssssssss")

    const fetchApi = FetchApi()
    const role = TokenService.getRole()
    const snackbarService = SnackbarService()
    const { control, handleSubmit, register } = useForm();
    const state = useSelector((state: any) => state.team)
    const [project, setProject] = useState<any[]>([])
    const [selectedProject, setSelectedProject] = useState<string>('')
    const [taskDetails, setTaskDetails] = useState<string>('')
    const [completionDate, setCompletionDate] = useState<string>('')
    const [startDate, setStartDate] = useState<string>('')
    const token = TokenService.extractToken()
    const addField = useMemo(() => detailsField().addTaskField, [detailsField])

    const [formSchema, setFormSchema] = useState<any>([])

    useEffect(() => {
        if (addField.length > 0) {
            setFormSchema(addField)
        }
    }, [addField])




    const handleChange = (e: any) => {
        setSelectedProject(e.target.value)
    }

    const handleField = (e: any) => {
        setTaskDetails(e.target.value)
    }

    useEffect(() => {
        if (role === Role.COREMEMBERS) {
            const filteredProjects = state.admin_team.filter((element: { projectWorkingStatus: ProjectStatusModel; }) => element.projectWorkingStatus !== ProjectStatusModel.CLOSED);
            setProject(filteredProjects);
        } else {
            const filteredProjects = state.user_team.filter((element: { projectWorkingStatus: ProjectStatusModel; }) => element.projectWorkingStatus !== ProjectStatusModel.CLOSED);
            setProject(filteredProjects);
        }
    }, [role, state.admin_team, state.user_team])

    function formatDateToCustomFormat(inputDate: any) {
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const customFormat = `${year}-${month}-${day}`; return customFormat;
    }

    const handleDate = (e: any, field: string) => {
        var value: string = formatDateToCustomFormat(e.$d.toLocaleDateString("en-US")).toString()
        if (field === 'startDate') {
            setStartDate(value)
        } else {
            setCompletionDate(value)
        }

    }

    const onSubmit = (data: any) => {

        const task = [{
            username: token?.sub,
            employeeName: token?.name,
            projectId: project && project.length > 0 && project[0].projectName === selectedProject ? project[0].projectId : null,
            projectName: selectedProject,
            description: taskDetails,
            completionTargets: [
                {
                    completionDate: completionDate

                }
            ],
            startDate: startDate

        }]

        createTask(task).then((result: any) => {
            if (result.data && token && props.getTask) {
                if (result.data.success) {
                    snackbarService.next({ data: { open: true, severity: 'success', message: result.data.message } })
                } else {
                    snackbarService.next({ data: { open: true, severity: 'error', message: result.data.message } })
                }
                props.getTask()

            }
        })
        props.onClose()

    }

    return (
        <div className='task-create-container'>
            <div className="modal-heading">
                <h4>{props.title}</h4>
                <CloseIcon
                    className='modal-close-button'
                    onClick={props.onClose}
                />
            </div>
            <div className="modal-body">
                <form onSubmit={handleSubmit(onSubmit)} className='container-100' >
                    <div className='leave-application-form-container'>
                        {
                            formSchema.map((item: any, index: number) => {
                                return <>
                                    {item.type === 'textArea' ? <FormControl required sx={{ minHeight: '20px' }} fullWidth>
                                        <Controller
                                            name={item.name}
                                            control={control}
                                            render={({ field }) => (
                                                <TextareaAutosize
                                                    {...field}
                                                    value={taskDetails}
                                                    aria-label="empty textarea"
                                                    minRows={5}
                                                    maxRows={5}
                                                    maxLength={200}
                                                    style={{ padding: '4px', paddingLeft: '12px' }}
                                                    placeholder={item.label}
                                                    onChange={handleField}
                                                    required={item.required}
                                                />
                                            )}
                                        />
                                    </FormControl> : item.type === 'select' ? <FormControl sx={{ mt: 1 }} fullWidth>
                                        <InputLabel id="demo-simple-select-label">{item.label}</InputLabel>
                                        <Controller
                                            name={item.name}
                                            control={control}
                                            render={({ field }) => (
                                                <Select
                                                    name={item.name}
                                                    labelId="project-label"
                                                    id="projetc"
                                                    label={item.label}
                                                    defaultValue=''
                                                    onChange={handleChange}
                                                    value={selectedProject}
                                                    required={item.required}
                                                >
                                                    <MenuItem value='Untitle'>
                                                        Untitle
                                                    </MenuItem>
                                                    {project?.map((item: any, index: number) => (
                                                        <MenuItem key={index} value={item.projectName}>
                                                            {item.projectName}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />

                                    </FormControl> : item.type === 'date' ?
                                        <FormControl fullWidth>

                                            <LocalizationProvider dateAdapter={AdapterDayjs} >
                                                <DemoContainer components={['DatePicker']}>
                                                    <DatePicker
                                                        label={item.label}
                                                        format='DD/MM/YYYY'
                                                        onChange={(e) => handleDate(e, item.name)}
                                                        value={dayjs(item.name)}
                                                        sx={{ width: '100%' }}
                                                        components={{
                                                            Popper: CustomPopper
                                                        }}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </FormControl> : ''}
                                </>
                            })
                        }
                    </div>
                    <div className='leave-application-button-container'>
                        <ButtonWrapper title='submit' id='submit-button' variant='contained' type='submit' />
                    </div>
                </form>
            </div>

        </div>
    )
}

export default AddTask

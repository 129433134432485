import React, { useEffect, useState } from 'react'
import './workFromHome.css'
import { getWorkFromHomeForUser } from '../../../../api-services/employee-services';
import { Stack, width } from '@mui/system';
import { FcApproval } from 'react-icons/fc';
import { AiFillCloseCircle, AiOutlineRedo } from 'react-icons/ai';
import WorkFromHomeApplication from '../../../../componant/workFromHomeApplication/workFromHomeApplication';
import CustomDetailsCellRender from '../../../custom-details-cell-render/custom-details-cell-render';
import ButtonWrapper from '../../../../widgets/button/button';
import ModalWrapper from '../../../../widgets/modal/modal';
import AgGridContainer from '../../../../componant/gridContainer/gridContainer';
import { formateHifDate } from '../../../../util_service/dateFormater/dateFormater';
import sortFromDateToDate from '../../../../util_service/sortFromDateTodate/sortFromDateToDate';

interface workFromHomeDataProps {
    wfhId: number,
    fromDate: string
    toDate: string,
    reasonForWFH: string,
    noOfDays: number,
    leaveStatus: string
}





const UserWorkFromHome = () => {

    const [rawData, setRawData] = useState<workFromHomeDataProps[]>([])
    const [openModal, setOpen] = useState<boolean>(false);
    const handleOpen = () => {
        setOpen(true)
    };
    const handleClose = () => setOpen(false);

    const fetchData = () => {
        getWorkFromHomeForUser().then((response: any) => {
            if (response.data?.success) {
                const sortedData = sortFromDateToDate(response.data.payload.wfhDetails)
                setRawData(sortedData)
            }
        }).catch((err) => {

        });
    }

    useEffect(() => {
        fetchData()
    }, [])

    const [columnDefs] = useState<any[]>([
        {
            headerName: "From Date",
            field: "fromDate",
            // width: 130,
            minWidth: 100,
            flex: 1,
            resizable: true,
            filter: true,
            pinned: 'left',
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            lockPinned: true,
            cellRenderer: (params: any) => {
                const formattedValue = formateHifDate(params.value);
                return formattedValue;
            },
            cellClass: 'flex-start-center',
        },
        {
            headerName: "To Date",
            field: "toDate",
            minWidth: 100,
            flex: 1,
            resizable: true,
            filter: true,
            pinned: 'left',
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            lockPinned: true,
            cellRenderer: (params: any) => {
                const formattedValue = formateHifDate(params.value);
                return formattedValue;
            },
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Days",
            field: "noOfDays",
            minWidth: 100,
            flex: 1,
            filter: true,
            lockPinned: true,
        },
        {
            headerName: "Reason",
            field: "reasonForWFH",
            cellRenderer: (params: any) => (<CustomDetailsCellRender data={params} flag={true} title='Reason For Work From Home' />),
            minWidth: 100,
            flex: 1,
            resizable: true,
            filter: true,
            lockPinned: true,
        },
        {
            headerName: "Leave Status",
            field: "leaveStatus",
            cellRenderer: (params: any) => (
                <div>{params.data.leaveStatus === 'approved' ? <FcApproval /> : params.data.leaveStatus === 'rejected' ? <AiFillCloseCircle color='var(--dangerColor)' /> : <AiOutlineRedo color="var(--secondaryColor)" />}{params.data.leaveStatus}</div>

            ),
            minWidth: 100,
            flex: 1,
            resizable: true,
            filter: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
        }
    ]);

    const handleWorkFromHome = () => {
        handleOpen()
    }

    const handleEditEmployees = (params: any) => {
        if (params)
            fetchData()
    }

    return (
        <>
            <div className='main'>
                <div className='main-container'>
                    <div className='heading heading-center-space-between height-40'>
                        <h2>Work From Home</h2>
                        <ButtonWrapper buttonType='circle' className='circular-add-button' tooltipTitle='Apply Work From Home' tooltipPlacement='left' onClick={handleWorkFromHome} />
                        <ModalWrapper open={openModal} close={handleOpen} children={<WorkFromHomeApplication onClose={handleClose} handleEditEmployees={handleEditEmployees} />} />
                    </div>
                    <div className='cal-40'>
                        <AgGridContainer rowData={rawData} columnDefs={columnDefs} />
                    </div>
                </div>

            </div >
        </>
    )
}

export default UserWorkFromHome

import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import './viewWorkFromHomeReasonDetails.css'

interface ViewWorkFromHomeReasonDetailsProps {
    data: any;
    onClose: () => void;
    flag?: boolean;
    title?: string;
}


const ViewWorkFromHomeReasonDetails = (props: ViewWorkFromHomeReasonDetailsProps) => {
    const [reason, setReason] = useState('')
    useEffect(() => {
        if (props.flag) {
            setReason(props.data.reasonForWFH)
        } else {
            setReason(props.data.reasonForLeave)
        }
    }, [props])
    return (
        <>
            <div className='container-100'>
                <div className="modal-heading">
                    <h4>{props.title}</h4>
                    <CloseIcon
                        className='modal-close-button'
                        onClick={props?.onClose}
                    />
                </div>

                <div className='modal-body'>{reason}</div>
            </div>
        </>
    )
}

export default ViewWorkFromHomeReasonDetails
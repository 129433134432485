import React, { useEffect, useState } from 'react'
import './dashboard.css'
import { useNavigate } from 'react-router';
import { getAbsentEmployeeSummaryDetails, getBirthdaySummaryDetails, getLeaveSummaryDetails, getProjectSummaryDetails, getSummaryPendingLeave, getWorkFromHomeSummaryDetails } from '../../../api-services/employee-services';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import CardContainer from '../../../widgets/card/card';
import { DashboardModel } from '../../../model/dashboard-model/dashboard.model';
import DashboardCardTitle from '../../../componant/dashboardCardTitle/dashboardCardTitle';
import DashboardCardBodyItem from '../../../componant/dashboardCardBody/dashboardCardBody';

interface ApiFlags {
    [key: string]: boolean;
}



const AdminDashboard = () => {


    const state = useSelector((state: any) => state.team)
    const [fieldValue, setFieldValue] = useState<any[]>([])
    const navigate = useNavigate()

    const fetchData = async () => {
        try {
            const apiFlags: ApiFlags = state.dashboard_config[0];

            const apiCalls: { [key: string]: () => Promise<any> } = {
                pendingLeave: getSummaryPendingLeave,
                birthday: getBirthdaySummaryDetails,
                leave: getLeaveSummaryDetails,
                project: getProjectSummaryDetails,
                team: getProjectSummaryDetails,
                absent: getAbsentEmployeeSummaryDetails,
                workFromHome: getWorkFromHomeSummaryDetails
            };

            const promises: Promise<any>[] = [];
            for (const [result, flag] of Object.entries(apiFlags)) {
                if (flag && apiCalls[result]) {
                    promises.push(apiCalls[result]());
                }
            }

            const results = await Promise.allSettled(promises);

            for (const result of results) {
                handleApiResult(result);
            }
        } catch (error) {
            console.error('One or more API calls failed:', error);
        }
    };

    const handleApiResult = (result: any) => {
        if (result.status === 'fulfilled') {
            if (result.value !== null) {
                const data = result.value.data;
                console.log(data, "Daaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa")
                if (data.payload.name === 'project') {
                    console.log(data.payload, "Dataaaaaaaaaaaaaaa")

                    const currentDate = new Date();
                    const thirtyDaysLater = new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000);

                    // Custom sorting function
                    const sortedProjects = data.payload.summaryDetails?.sort((a: any, b: any) => {
                        // Function to check if projectDemoDate is within next 30 days from today
                        const isWithinNext30Days = (demoDate: string): boolean => {
                            if (!demoDate) return false;
                            const demoDateObj = new Date(demoDate);
                            return demoDateObj >= currentDate && demoDateObj <= thirtyDaysLater;
                        };

                        // Handle projects with Demo In Progress first
                        if (a.status === "Demo In Progress" && b.status !== "Demo In Progress") {
                            return -1;
                        }
                        if (a.status !== "Demo In Progress" && b.status === "Demo In Progress") {
                            return 1;
                        }

                        // Both have Demo In Progress status or neither
                        if (a.status === "Demo In Progress" && b.status === "Demo In Progress") {
                            // Check if projectDemoDate is within next 30 days for both
                            const aWithin30Days = isWithinNext30Days(a.projectDemoDate);
                            const bWithin30Days = isWithinNext30Days(b.projectDemoDate);

                            // Sort by projectDemoDate if both are within next 30 days
                            if (aWithin30Days && bWithin30Days) {
                                return new Date(a.projectDemoDate).getTime() - new Date(b.projectDemoDate).getTime();
                            }
                            // Put project with projectDemoDate within next 30 days first
                            if (aWithin30Days && !bWithin30Days) {
                                return -1;
                            }
                            if (!aWithin30Days && bWithin30Days) {
                                return 1;
                            }
                        }

                        // Otherwise, maintain the original order
                        return 0;
                    });

                }
                if (data) {
                    setFieldValue((prev) => [...prev, data.payload]);
                } else {
                    const updatedPayload = {
                        ...data.payload,
                        summaryDetails:
                            data.payload.summaryDetails !== null
                                ? data.payload.summaryDetails
                                : []
                    };
                    setFieldValue((prev) => [...prev, updatedPayload]);
                }
            }
        } else {
            console.error('API call failed:', result.reason);
        }
    };



    useEffect(() => {
        fetchData()
    }, [state])

    console.log(fieldValue, "FieldVAlue")

    useEffect(() => {
        const updateFieldValues = (fieldName: any, fieldArray: any) => {
            if (fieldArray.length > 0) {
                setFieldValue((prevValue) => [...prevValue, ...fieldArray]);
            }
        };

        // updateFieldValues('attendance', field.leave);
        // updateFieldValues('project', field.project);
        // updateFieldValues('absent', field.absent);
    }, []);


    console.log(fieldValue, "field value")

    const getMonthName = (monthNumber: any) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);
        return date.toLocaleString('en-US', { month: 'long' });
    }
    const formateDateWithOutYear = (opt: any) => {
        const newDate = new Date(opt)
        const date = newDate.getDate()
        const month = getMonthName(newDate.getMonth() + 1)
        const updateDate = `${date} ${month}`
        return updateDate;
    }

    const formateDate = (opt: any) => {
        const splitDate = opt.split('-')
        const year = splitDate[0]
        const month = splitDate[1]
        const date = splitDate[2]
        const updateDate = `${date}-${month}-${year}`
        return updateDate;
    }

    const onViewDetails = (field: any) => {
        let path = '';

        switch (field) {
            case 'pendingLeaveForApproval':
                path = '/admin/leaveReview';
                break;
            case 'workFromHome':
                path = '/admin/workFromHome'
                break;
            default:
                path = '/admin/teamManagement';
                break;
        }
        navigate(path);
    }

    return (
        <div className='main'>
            <div className='main-container'>
                <div className='heading heading-start-center height-40'>
                    <h2>Dashboard</h2>
                </div>
                <div className='sub-container cal-30'>
                    <div className='sub-container-wrapper'>

                        <Grid container spacing={1}>
                            {
                                fieldValue.map((element: DashboardModel, index: number) => {
                                    return <>
                                        <Grid item xs={12} sm={6} md={4}>
                                            <div className='main-card-container'>
                                                <CardContainer title={
                                                    <DashboardCardTitle label={element.label} name={element.name} />
                                                } body={

                                                    <div className='card-body'>
                                                        <div className='card-sub-body'>
                                                            {
                                                                element.summaryDetails?.map((ele: any, ind: number) => {
                                                                    return <DashboardCardBodyItem element={element} subElement={ele} key={ind} />
                                                                })
                                                            }
                                                        </div>

                                                    </div>


                                                } />
                                            </div>
                                        </Grid>
                                    </>
                                })
                            }
                        </Grid>
                    </div>

                </div>

            </div>
        </div >
    )
}

export default AdminDashboard
import React from 'react'
import './teamMembersDetails.css'
import CloseIcon from '@mui/icons-material/Close';

interface TeamMembersDetailsProps {
    onClose: () => void
    members: any;
    actionStatus: boolean;
    title?: string
}
const TeamMembersDetails = (props: TeamMembersDetailsProps) => {
    console.log(props.members)
    return (
        <>
            <div className='team-members-main-container'>
                <div className='modal-heading'>
                    <h4>{props.title}</h4>
                    <CloseIcon
                        className='modal-close-button'
                        onClick={() => { props.onClose() }}
                    />
                </div>
                <div className='modal-body'>
                    <div className='modal-sub-body'>
                        <ol className="list" >
                            {
                                props.members.map((ele: any, index: number) => {
                                    return (
                                        <li className='list-item'>{ele.name ? ele.name : ele}</li>
                                    )
                                })
                            }

                        </ol>

                    </div>
                </div>
            </div>
        </>
    )
}

export default TeamMembersDetails
import * as React from 'react';
import './drawer.css'
import { Drawer, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DrawerService } from '../../services/snackBehaviorSubject/snackBehaviorSubject';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface SwipeableTemporaryDrawerProps {
    data: {
        open: boolean
        anchor: Anchor
        children: React.ReactNode
    }
}

export default function SwipeableTemporaryDrawer(props: SwipeableTemporaryDrawerProps) {
    const [open, setOpen] = React.useState(false);

    const drawerService = DrawerService()


    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
        drawerService.next({ data: { open: newOpen, anchor: props.data.anchor, children: null } })

    };

    React.useEffect(() => {
        setOpen(props.data.open)
    }, [props])



    return (
        <div style={{ border: '1px solid blue' }}>
            <React.Fragment >
                <Drawer
                    anchor={props.data.anchor}
                    open={open}
                    onClose={toggleDrawer(false)}
                    variant="temporary"
                >
                    <div className='drawer-container' >
                        <div className='drawer-header'>
                            <Typography variant="h6" noWrap component="div"  >
                                Task History
                            </Typography>

                            <ArrowForwardIcon className='drawer-close-icon' onClick={() => drawerService.next({ data: { open: false, anchor: props.data.anchor, children: null } })} />
                        </div>
                        <div className='drawer-body'>
                            {props.data.children}
                        </div>
                    </div>
                </Drawer>

            </React.Fragment>
        </div >
    );
}

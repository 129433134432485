import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import React from 'react';

interface DateType {
    startDate?: string,
    endDate?: string,
    completionDate?: string
}

interface DatePickerProps {
    label?: string,
    name?: keyof DateType,
    selectedDate: DateType,
    handleDate?: (date: string, name: keyof DateType, params?: any) => void,
    params?: any,
    minDate?: any
}

const CustomDatePicker = (props: DatePickerProps) => {
    console.log(props.selectedDate);
    console.log(props, "Props");

    const handleDateChange = (date: Dayjs | null) => {
        if (date && props.name && props.handleDate) {
            if (props.params) {
                props.handleDate(date.format('YYYY-MM-DD'), props.name, props.params);
            } else {
                props.handleDate(date.format('YYYY-MM-DD'), props.name);
            }
        }
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
                sx={{ height: '100%' }}
                label={props.label}
                format='DD/MM/YYYY'
                minDate={props.minDate !== undefined ? dayjs(props.minDate) : undefined}
                onChange={handleDateChange}
                // value={props.name && props.selectedDate[props.name] ? dayjs(props.selectedDate[props.name]) : null}
                value={props.name && dayjs(props.selectedDate[props.name])}
                closeOnSelect={true}
            />
        </LocalizationProvider>
    );
}

export default CustomDatePicker;

import { ProjectStatusModel } from "../model/project-status-model/project-status-model"


export let detailsField = () => {

    // function generateSalarySlipYears(startYear: number) {
    //     const currentYear = new Date().getFullYear();
    //     const salarySlipYear = [];

    //     for (let year = startYear; year <= currentYear + 1; year++) {
    //         salarySlipYear.push({ name: `${year}`, value: year });
    //     }

    //     return salarySlipYear
    // }

    // const startYear = 2022;

    // const salarySlipYear = generateSalarySlipYears(startYear)
    // salarySlipYear.sort((a: any, b: any) => b.value - a.value)

    const salarySlipMonth = [
        { name: 'January', value: 1 },
        { name: 'February', value: 2 },
        { name: 'March', value: 3 },
        { name: 'April', value: 4 },
        { name: 'May', value: 5 },
        { name: 'June', value: 6 },
        { name: 'July', value: 7 },
        { name: 'August', value: 8 },
        { name: 'September', value: 9 },
        { name: 'October', value: 10 },
        { name: 'November', value: 11 },
        { name: 'December', value: 12 },
    ]


    const personalDetailsFields: any[] = [
        { label: 'Employee Id', type: "text", name: "employee_id", isEnable: false },
        { label: 'Name', type: "text", name: "name", isEnable: false },
        { label: 'Fathers Name', type: "text", name: "Fathers_Name", isEnable: false },
        { label: 'DOB', type: "date", name: "dateOfBirth", isEnable: false },
        { label: 'Emergency Contact No', type: "number", name: "contact_no", isEnable: false },
        { label: 'Blood Group', type: "text", name: "blood_group", isEnable: false },
        { label: 'Personal Email', type: "email", name: "personalEmail", isEnable: false }

    ]

    const permanentAddressField = [
        { label: "Street", type: "text", name: "Permanent_Street", isEnable: true },
        { label: "City", type: "text", name: "Permanent_City", isEnable: true },
        { label: "State", type: "text", name: "Permanent_State", isEnable: true },
        { label: "Country", type: "text", name: "Permanent_Country", isEnable: true },
        { label: "Pincode", type: "text", name: "Permanent_Pincode", isEnable: true }
    ]
    const residentialAddressField = [
        { label: "Street", type: "text", name: "Residential_Street", isEnable: true },
        { label: "City", type: "text", name: "Residential_City", isEnable: true },
        { label: "State", type: "text", name: "Residential_State", isEnable: true },
        { label: "Country", type: "text", name: "Residential_Country", isEnable: true },
        { label: "Pincode", type: "text", name: "Residential_Pincode", isEnable: true }
    ]

    const employeeDetailsField = [
        { label: 'Date Of Joining', type: "date", name: "date_of_joining", isEnable: false },
        { label: 'Designation', type: "text", name: 'designation', isEnable: false },
        { label: 'Employee Id', type: "text", name: 'employee_id', isEnable: false },
        { label: 'Employee Type', type: "text", name: 'employee_type', isEnable: false },
        {
            label: 'Employee Role', type: "select", name: 'roles', isEnable: false,
            options: [
                { value: "ADMIN", label: 'Admin' },
                { value: "USER", label: 'User' },
                { value: "COREMEMBERS", label: 'Core Members' },
            ]
        },
        { label: 'Agenda', type: "checkbox", name: 'agendaRequired', isEnable: false },
        { label: 'Work Update', type: "checkbox", name: 'workupdateRequired', isEnable: false },
        { label: 'Official Email', type: "email", name: 'officialEmail', isEnable: false }
    ]


    const newEmployeeDetails = [
        { name: "name", type: "text", label: "Name" },
        { name: "contact_no", type: "number", label: "Contact Number" },
        { name: "officialEmail", type: "email", label: "Email" },
        { name: "date_of_joining", type: "date", label: "Date of Joining" },
        { name: "grossSalary", type: "number", label: "Gross Salary" },
        {
            name: "roles", type: "select", label: "Role",
            options: [
                { value: 'ADMIN', label: 'Admin' },
                { value: 'USER', label: 'User' },
                { value: "COREMEMBERS", label: 'Core Members' },
            ]
        }

    ]

    const newProjectdetails = [
        { name: 'projectName', type: "text", label: "Project Name", id: 1 },
        { name: 'teamName', type: "text", label: "Team Name", id: 2 },
        { name: 'projectClient', type: 'text', label: 'Client Name', id: 3 },
        { name: 'projectDetails', type: 'text', label: 'Details', id: 4 },
        { name: 'projectDemoDate', type: 'date', label: 'Demo', id: 5 },
        { name: 'projectWorkingStatus', type: 'select', label: 'Status', id: 6, options: [ProjectStatusModel.IN_PROGRESS, ProjectStatusModel.CANCELLED, ProjectStatusModel.DEMO_COMPLETED, ProjectStatusModel.CLOSED, ProjectStatusModel.DEMO_IN_PROGRESS] },
        { name: 'projectStartDate', type: 'date', label: 'Start Date', id: 7 },
        { name: 'projectDeliveryDate', type: 'date', label: 'End Date', id: 8 },
        { name: 'projectLead', type: 'select', label: 'Project Leader', id: 9 },
        { name: 'projectFrontEndMembers', type: 'select', label: 'Frontend Member', id: 10 },
        { name: 'projectBackEndMembers', type: 'select', label: 'Backend Member', id: 11 },
        { name: 'teamMembers', type: 'select', label: 'Team Member', id: 12 },
        { name: 'projectRemark', type: 'text', label: 'Remarks', id: 13 }
    ]

    const field = [
        { label: 'ProvidentFund' },
        { label: 'EmployeesStateInsurance' },
        { label: 'OverTime' },
    ]
    const salaryCalculation = [
        'BasicSalary',
        'DearnessAllowance',
        'HouseRentAllowance',
        'SpecialAllowance',
        'NetSalary'
    ]
    const createLeave = [
        { name: 'casual', type: 'number', label: 'Casual Leave' },
        { name: 'special', type: 'number', label: 'Special Leave' },
        { name: 'medical', type: 'number', label: 'Medical Leave' },
    ]

    const createHoliday = [
        { name: 'date', type: 'date', label: 'Date' },
        { name: 'reason', type: 'text', label: 'Holiday Description' }
    ]

    const createWFH = [
        { name: 'fromDate', type: 'date', label: 'From Date' },
        { name: 'toDate', type: 'date', label: 'To Date' },
        { name: 'noOfDays', type: 'number', label: 'No of Days' },
        { name: 'reasonForWFH', type: 'text', label: 'Reason' },
    ]
    const dateRangeField = [
        { name: 'startDate', label: 'Form Date', type: 'date' },
        { name: 'endDate', label: 'To Date', type: 'date' }
    ]


    const addTaskField = [
        { name: 'taskDetails', label: 'Task details', type: 'textArea', required: 'required' },
        { name: 'projectName', label: 'Select Project', type: 'select', required: 'required' },
        { name: 'startDate', label: 'Start Date', type: 'date' },
        { name: 'completionDate', label: 'Due Date', type: 'date' }
    ]

    const newCandidateField = [
        { name: 'name', label: 'Name', type: 'text' },
        { name: 'contactNo', label: 'Contact Number', type: 'number' },
        { name: 'emailId', label: 'Email', type: 'email' },
        { name: 'resume', label: 'Resume', type: 'file' },
    ]

    const scheduleCandidate = [
        { name: 'round', label: 'Round', type: 'number', disable: false },
        { name: 'scheduleDate', label: 'Date & Time', type: 'date', disable: false },
        { name: 'interviewer', label: 'Interview Panel Member', type: 'select', disable: false },
        { name: 'rescheduleReason', label: 'Reason', type: 'text', disable: false }
    ]



    return { personalDetailsFields, permanentAddressField, residentialAddressField, employeeDetailsField, newEmployeeDetails, field, salaryCalculation, salarySlipMonth, newProjectdetails, createLeave, createWFH, dateRangeField, createHoliday, addTaskField, newCandidateField, scheduleCandidate };




}




export let prefixString = () => {
    const prefix = 'TTPL000'
    return prefix;
}

export let currencyCode = () => {
    const india = 'INR'
    return india
}

export const userleaveDetails = () => {
    let leaveDetails = [
        { leaveType: "Casual", id: 1 },
        { leaveType: "Medical", id: 2 },
        { leaveType: "Special", id: 3 },
        { leaveType: "Vacation", id: 4 }
    ]
    return leaveDetails
}



import React, { useEffect, useState } from 'react'
import './timeline.css'
import { Timeline } from 'antd'
import { StatusModel } from '../../model/status-model/status.model'

interface TimelineComponentProps {
    item: any
}

const TimelineComponent = (props: TimelineComponentProps) => {
    console.log(props, "Timeline Props")
    const [mode, setMode] = useState<'left' | 'alternate' | 'right'>('left');
    const [items, setItems] = useState<any[]>([])
    useEffect(() => {
        if (props.item) {
            setItems(props.item)
        }
    }, [props.item])

    return (
        <>
            <Timeline
                mode={mode}
            >
                {
                    items.map((element: any, index: number) => {
                        return <>
                            {element.completionDate !== null ? <Timeline.Item key={index} color={element.color} className={element.completionDate ? 'timeline-completionDate-container' : (element.startDate ? 'timeline-start-date-container' : '')}>

                                <div style={{ display: 'flex', gap: '4px' }}>
                                    <div className='status-container'>{element.value ? element.value : (element.startDate ? element.startDate : element.completionDate)}</div>
                                    {element.statusUpdateDate || element.completionTargetUpdateDate ? <div className='status-update-main-container'><span className='status-update-date-container'>{element.statusUpdateDate ? element.statusUpdateDate : element.completionTargetUpdateDate}</span></div> : <div className='status-update-main-container'><span className='status-update-start-container'>Start</span></div>}
                                </div>
                                <div className='timeline-reason'>{element.reason}</div>


                            </Timeline.Item> : null}
                        </>
                    })
                }
            </Timeline>

        </>
    )
}

export default TimelineComponent

import { IDateFilterParams } from "ag-grid-community";
import { formateISODate } from "../../dateFormater/dateFormater";

export const filterParamsDate: IDateFilterParams = {
    comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
        console.log(cellValue, "cellValuecellValue")
        var dateAsString = cellValue;
        console.log(dateAsString, 'dateAsString')
        if (dateAsString == null) return -1;
        var dateParts = dateAsString.split("-");
        var cellDate = new Date(
            Number(dateParts[0]),
            Number(dateParts[1]) - 1,
            Number(dateParts[2]),
        );

        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
        }

        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        }

        if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
        return 0;
    },
    inRangeFloatingFilterDateFormat: "Do MMM YYYY",
};

export const filterParams: IDateFilterParams = {
    comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
        var dateAsString = formateISODate(cellValue);
        console.log(dateAsString, 'dateAsString')
        if (dateAsString == null) return -1;
        var dateParts = dateAsString.split("-");
        var cellDate = new Date(
            Number(dateParts[2]),
            Number(dateParts[1]) - 1,
            Number(dateParts[0]),
        );

        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
            return 0;
        }

        if (cellDate < filterLocalDateAtMidnight) {
            return -1;
        }

        if (cellDate > filterLocalDateAtMidnight) {
            return 1;
        }
        return 0;
    },
    inRangeFloatingFilterDateFormat: "Do MMM YYYY",
};
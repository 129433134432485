import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import './holiday.css'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomCellRender from '../../custom-cell-render/custom-cell-render';
import FetchApi from '../../../fetch-api/fetch-api';
import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Tooltip } from '@mui/material';
import CreateHoliday from '../../../componant/createHoliday/createHoliday';
import ModalWrapper from '../../../widgets/modal/modal';
import ButtonWrapper from '../../../widgets/button/button';
import AgGridContainer from '../../../componant/gridContainer/gridContainer';
import { deleteHoliday } from '../../../api-services/employee-services';
import { SnackbarService } from '../../../services/snackBehaviorSubject/snackBehaviorSubject';
import EditHoliday from '../../../componant/editHoliday/editHoliday';


const style = {

  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {
    xs: 0.9,
    sm: 0.8,
    md: 400
  },
  bgcolor: 'white',
  boxShadow: 24,
  p: 2,
  borderRadius: 2

};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    border: '1px solid rgb(39,112,222)',
    borderRadious: "2px",
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    // boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

interface rawDataProps {
  date: string,
  id: number,
  reason: string
}
const Holiday = () => {
  const snackbarService = SnackbarService()
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [openModal, setOpen] = useState<boolean>(false);
  const handleOpen = () => {
    setOpen(true)
  };
  const handleClose = () => {
    setOpen(false)
    setIsOpen(false)
  };
  const fetchApi = FetchApi()
  const [rowData, setRowData] = useState<rawDataProps[]>([])
  const [editableHoliday, setEditableHoliday] = useState<any>()
  const state = useSelector((state: any) => state.allEmployee)

  useEffect(() => {
    setRowData(state.holiday)
  }, [state])
  const fetchData = () => {
    fetchApi.fetchHoliday()
  }
  useEffect(() => {
    fetchData()
  }, [])


  console.log(rowData, "Row Data")

  const [columnDefs]: any[] = useState([
    {
      headerName: "S.No",
      field: "sno",
      width: 70,
      cellClass: 'flex-start-center',
    },
    {
      headerName: "Date",
      field: "date",
      width: 415,
      minWidth: 110,
      flex: 1,
      filter: true,
      cellClass: 'flex-start-center',
    },
    {
      headerName: "Reason",
      field: "reason",
      minWidth: 130,
      wrapText: true,
      autoHeight: true,
      flex: 1,
      filter: true,
      cellClass: 'flex-start-center',
    },
    {
      headerName: "Action",
      field: "",
      width: 90,
      headerClass: 'text-center',
      cellRenderer: (params: any) => (
        <CustomCellRender value={params} onDelete={handleDeleteHoliday} onEdit={handleEditHoliday} />
      ),
      cellClass: 'flex-start-center',
    }
  ])

  const handleDeleteHoliday = (holiday: any) => {
    deleteHoliday(holiday.id).then((response: any) => {
      if (response.data.success) {
        fetchData()
        snackbarService.next({ data: { open: true, severity: 'success', message: response.data.message } })
      } else {
        snackbarService.next({ data: { open: true, severity: 'error', message: response.data.message } })
      }
    })
  }

  const handleEditHoliday = (holiday: any) => {
    setEditableHoliday(holiday)
    setIsOpen(true)
    handleOpen()
  }


  const handleAddHoliday = () => {
    handleOpen()
  }

  return (
    <div className='main'>
      <div className='main-container'>
        <div className='heading heading-center-space-between height-40'>
          <h2>Holiday</h2>
          <div className='flex-end-center'>
            <ButtonWrapper buttonType='circle' onClick={handleAddHoliday} className='circular-add-button' tooltipTitle='Create Holiday' tooltipPlacement='left' />
          </div>
        </div>
        <div className='cal-40'>
          <div className="holiday-table-wrapper">
            <AgGridContainer rowData={rowData} columnDefs={columnDefs} />
          </div>
        </div>
        <ModalWrapper open={openModal} close={handleOpen} children={isOpen ? <EditHoliday onClose={handleClose} data={editableHoliday} /> : <CreateHoliday onClose={handleClose} />} />
      </div>
    </div>
  )
}

export default Holiday
import { Badge, Button } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './customTeamDetailsCell.css'
import Modal from '@mui/material/Modal'
import { Box } from '@material-ui/core'
import Typography from '@mui/material/Typography'
import TeamMembersDetails from '../../componant/teamMembersDetails/teamMembersDetails'
import ModalWrapper from '../../widgets/modal/modal'
import ButtonWrapper from '../../widgets/button/button'

enum Members {
  BACKEND_MEMBERS = 'Backend Members',
  FRONTENDMEMBERS = 'FrontEndMembers'
}

const CustomTeamDetailsCell = (props: { value: any, actionStatus?: boolean, title?: string }) => {
  console.log(props, "Props in Team Details")
  const [members, setMembers] = useState<any[]>([])
  const [open, setOpen] = useState<boolean>(false);
  const handleOpen = () => {

    setOpen(true)
  };
  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (props.title === Members.BACKEND_MEMBERS) {
      setMembers(props.value.data.projectBackEndMembers)
    } else {
      setMembers(props.value.data.projectFrontEndMembers)
    }
  }, [props.title])


  return (

    <>
      <div className='button-class'>
        <ButtonWrapper className={`view-button`} title={`view`} onClick={handleOpen} />
      </div>
      <ModalWrapper open={open} close={handleClose} children={<TeamMembersDetails onClose={handleClose} members={members} actionStatus={props.actionStatus ? props.actionStatus : false} title={props.title} />} />

    </>
  )
}

export default CustomTeamDetailsCell
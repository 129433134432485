import React, { useEffect, useState } from 'react'
import AgGridContainer from '../../../componant/gridContainer/gridContainer'
import { getAllUserTask } from '../../../api-services/employee-services'
import AuditCellRender from '../../audit-cell-render/audit-cell-render'
import { formateISODate, formateZoffsetDate } from '../../../util_service/dateFormater/dateFormater'
import CustomDatePicker from '../../../widgets/datePicker/datePicker'
import ModalWrapper from '../../../widgets/modal/modal'
import ViewTaskReason from '../../../componant/viewTaskReason/viewTaskReason'
import { getStatus } from '../../../util_service/status/status'
import { maxWidth, minWidth, width } from '@mui/system'
import { filterParams, filterParamsDate } from '../../../util_service/agGrid/dateFilter/dateFilter'
import LightTooltip from '../../../widgets/tooltip/tooltip'
import { GridApi } from 'ag-grid-community'
import { Flex } from 'antd'
import { filterModel } from '../../../util_service/agGrid/filterModel/filterModel'

const Task = () => {
    const [rowData, setRowData] = useState<any[]>([])
    const [openReasonModal, setOpenReasonModal] = useState<boolean>(false);
    const [reasonDetails, setReasonDetails] = useState<string>('')
    const [detailsTitle, setDetailsTitle] = useState<string>('')
    const [gridApi, setGridApi] = useState<GridApi | null>(null)

    const handleOpenReasonModal = () => {
        setOpenReasonModal(true)
    };

    const handleCloseReasonModal = () => {
        setOpenReasonModal(false);
    }

    const fetchData = () => {
        getAllUserTask().then((result: any) => {
            console.log(result.data.payload, "User taskkkkkkkkkkkkkkkkkk")
            const sortedTasks = result.data.payload.sort((a: { createdDate: string | number | Date }, b: { createdDate: string | number | Date }) => {
                return new Date(b.createdDate).getTime() - new Date(a.createdDate).getTime();
            });

            setRowData(sortedTasks)
        })
    }

    useEffect(() => {
        fetchData()
    }, [])


    const onReasonView = (value: any, field?: string) => {
        if (field === 'task') {
            setDetailsTitle('Task')
        } else {
            setDetailsTitle('Reason')
        }
        handleOpenReasonModal()
        setReasonDetails(value)
    }



    const columnDefs = [
        {
            headerName: 'Employee Name',
            field: 'employeeName',
            filter: true,
            resizable: true,
            minWidth: 130,
            flex: 1,
            pinned: 'left',
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
        },
        {
            headerName: 'Project Name',
            field: 'projectName',
            filter: true,
            minWidth: 200,
            flex: 1,
            lockPinned: true,
            cellClass: 'flex-start-center',
        },
        {
            headerName: 'Task',
            field: 'description',
            minWidth: 200,
            flex: 1,
            lockPinned: true,
            cellClass: 'flex-start-center',
            cellRenderer: (params: any) => {
                console.log(params, "Paar")
                const description = params.node.data.description
                return <LightTooltip title={description}>
                    <div className='reason-container' >
                        <div className='single-line-text'>{description}</div>
                    </div>
                </LightTooltip>
            }
        },
        {
            headerName: 'Creation Date',
            field: 'createdDate',
            filter: 'agDateColumnFilter',
            minWidth: 150,
            flex: 1,
            lockPinned: true,
            cellClass: 'flex-start-center',
            valueFormatter: (params: any) => {
                const value = formateISODate(params.value)
                return value
            },
            filterParams: filterParams
        },
        {
            headerName: 'Start Date',
            field: 'startDate',
            minWidth: 150,
            flex: 1,
            filter: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
            valueFormatter: (params: any) => {
                const value = params.value ? formateISODate(params.value) : null
                return value
            },
        },
        {
            headerName: 'Status',
            field: 'status',
            filter: true,
            minWidth: 150,
            flex: 1,
            lockPinned: true,
            cellClass: 'flex-start-center',
            cellRenderer: (params: any) => {
                const status = params.node.data.status[params.node.data.status.length - 1].value;
                return <div className='reason-main-container'>
                    {getStatus(status).value}
                </div>
            },
            "filterParams": { "maxNumConditions": 3, "defaultJoinOperator": "OR" },
            filterValueGetter: (params: any) => {
                const latestStatus = params.data.status[params.data.status.length - 1];
                return getStatus(latestStatus.value).value;
            },
        },
        {
            headerName: 'Status Change Reason',
            field: 'status',
            minWidth: 200,
            flex: 1,
            lockPinned: true,
            cellClass: 'flex-start-center',
            cellRenderer: (params: any) => {
                console.log(params, "Paar")
                const reason = params.node.data.status[params.node.data.status.length - 1].reason
                return <div className='reason-main-container' onClick={() => onReasonView(reason)}>
                    <div className='reason-container' >
                        <span className='single-line-text'> {reason}</span>
                    </div>
                </div>
            }
        },
        {
            headerName: 'Due Dates',
            field: 'completionTargets',
            minWidth: 170,
            flex: 1,
            lockPinned: true,
            cellClass: 'flex-start-center',
            filter: 'agDateColumnFilter',
            filterParams: filterParamsDate,
            valueFormatter: (params: any) => {
                const value = formateISODate(params.value)
                return value
            },
            valueGetter: (params: any) => params.data.completionTargets[params.data.completionTargets.length - 1].completionDate,
            valueSetter: (params: any) => params.data.completionTargets[params.data.completionTargets.length - 1].completionDate = params.newValue ? formateZoffsetDate(params.newValue) : params.data.completionTargets[params.data.completionTargets.length - 1].completionDate,
        },
        {
            headerName: 'Due Date Change Reason',
            field: 'completionTargets',
            minWidth: 200,
            flex: 1,
            lockPinned: true,
            cellClass: 'flex-start-center',
            cellRenderer: (params: any) => {
                console.log(params, "Paar")
                const reason = params.node.data.completionTargets[params.node.data.completionTargets.length - 1].reason
                return <div className='reason-main-container' onClick={() => onReasonView(reason)}>
                    <div className='reason-container' >
                        <span className='single-line-text'> {reason}</span>
                    </div>
                </div>
            }
        },
        {
            headerName: 'Action',
            field: '',
            width: 100,
            lockPinned: true,
            cellRenderer: (params: any) => {
                console.log(params, "Params")
                return <>
                    <AuditCellRender value={params.data} />
                </>
            },
        },
    ]

    useEffect(() => {
        const columnName = 'status'
        if (gridApi !== null) {
            const filterInstance = gridApi.getFilterInstance(columnName);
            if (filterInstance) {
                console.log(filterInstance, "Filter Instance")
                const [inProgressFilterModel, onHoldFilterModel, pendingFilterModel] = filterModel();
                filterInstance.setModel({
                    filterType: 'text',
                    operator: 'OR',
                    conditions: [
                        inProgressFilterModel,
                        onHoldFilterModel,
                        pendingFilterModel,
                    ],
                });
                gridApi.onFilterChanged();
            } else {
                console.log(`No filter instance found for column: ${columnName}`);
            }
        }
    }, [gridApi])

    const handleOnGridReady = (params: any) => {
        params.api.sizeColumnsToFit();
        setGridApi(params.api)
    }

    return (
        <div className='main'>
            <div className="main-container">
                <div className='heading heading-start-center height-40'>
                    <h2>Employee Task List</h2>
                </div>
                <div className="cal-40">
                    <div className="container-100">
                        <AgGridContainer rowData={rowData} columnDefs={columnDefs} onGrid={handleOnGridReady} />
                    </div>
                </div>
                <ModalWrapper open={openReasonModal} close={handleCloseReasonModal} children={<ViewTaskReason title={detailsTitle} reason={reasonDetails} onClose={handleCloseReasonModal} />} />
            </div>
        </div>
    )
}

export default Task

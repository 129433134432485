import { ActionTypes } from "../constants/constants";

export const setEmployee = (employee: any) => {
  return {
    type: ActionTypes.SET_EMPLOYEE,
    payload: employee,
  };
};

export const addEmployee = (newEmployee: any) => {
  return {
    type: ActionTypes.ADD_EMPLOYEE,
    payload: newEmployee,
  };
};

export const updateEmployee = (newEmployee: any) => {
  return {
    type: ActionTypes.UPDATE_EMPLOYEE,
    payload: newEmployee,
  };
};

export const setClockIn = (clockIn: any) => {
  return {
    type: ActionTypes.CLOCK_IN,
    payload: clockIn,
  };
};

export const setClockOut = (clockOut: any) => {
  return {
    type: ActionTypes.CLOCK_OUT,
    payload: clockOut,
  };
};

export const setUserLeave = (item: any) => {
  return {
    type: ActionTypes.SET_USER_LEAVE,
    payload: item
  }
}

export const setUserLeaveReview = (item: any) => {
  return {
    type: ActionTypes.SET_USER_LEAVE_REVIEW,
    payload: item,
  };
};

export const setAttendance = (item: any) => {
  return {
    type: ActionTypes.SET_ATTENDANCE,
    payload: item,
  };
};

export const setHoliday = (item: any) => {
  return {
    type: ActionTypes.SET_HOLIDAY,
    payload: item,
  };
};

export const setLeaveApplication = (item: any) => {
  return {
    type: ActionTypes.SET_LEAVE_APPLICATION,
    payload: item,
  };
};

export const updateHoliday = (item: any) => {
  return {
    type: ActionTypes.UPDATE_HOLIDAY,
    payload: item,
  };
};

export const setSalary = (item: any) => {
  return {
    type: ActionTypes.SET_SALARY,
    payload: item,
  };
};


export const setAdminTeam = (item: any) => {
  return {
    type: ActionTypes.SET_ADMIN_TEAM,
    payload: item
  }
}
export const setUserTeam = (item: any) => {
  return {
    type: ActionTypes.SET_USER_TEAM,
    payload: item
  }
}

export const setTeamConfig = (item: any) => {
  return {
    type: ActionTypes.SET_TEAM_CONFIG,
    payload: item
  }
}

export const setDashBoardConfig = (item: any) => {
  return {
    type: ActionTypes.SET_DASHBOARD_CONFIG,
    payload: item
  }
}

export const setOvertimeConfig = (item: any) => {
  return {
    type: ActionTypes.SET_OVERTIME_CONFIG,
    payload: item
  }
}
export const setLeaveConfig = (item: any) => {
  return {
    type: ActionTypes.SET_LEAVE_CONFIG,
    payload: item
  }
}
export const setLeaveReviewConfig = (item: any) => {
  return {
    type: ActionTypes.SET_LEAVE_REVIEW_CONFIG,
    payload: item
  }
}

export const setUserTask = (task: any) => {
  return {
    type: ActionTypes.SET_USER_TASK,
    payload: task
  }
}
import React, { useEffect, useState } from 'react'
import './taskCalender.css'
import { Badge, BadgeProps, Calendar, Drawer } from 'antd'
import { FitScreen } from '@mui/icons-material'
import { Dayjs } from 'dayjs'
import { DrawerService } from '../../../../services/snackBehaviorSubject/snackBehaviorSubject'
import History from '../../../../componant/auditTask/auditTask'
import { useSelector } from 'react-redux'
import FetchApi from '../../../../fetch-api/fetch-api'
import { TokenService } from '../../../../services/token.service'
import { SelectInfo } from 'antd/es/calendar/generateCalendar'

const TaskCalender = () => {
    const fetchApi = FetchApi()
    const token = TokenService.extractToken()
    const task = useSelector((state: any) => state.task.userTask)
    const [data, setData] = useState([])
    const [open, setOpen] = useState(false);

    console.log(data, "Data task")

    useEffect(() => {
        if (task.length > 0) {
            setData(task)
        }
    }, [task])

    useEffect(() => {
        if (token)
            fetchApi.getUserTask(token.sub)
    }, [])

    const drawerService = DrawerService()



    const showDrawer = (date: any, newMode: SelectInfo) => {
        const dates = convertDate(date.$d)
        if (newMode.source === 'date') {
            drawerService.next({ data: { open: true, anchor: 'right', children: <History date={dates} /> } })
        }
    };


    const onPanelChange = (newValue: Dayjs) => {
        drawerService.next({ data: { open: false, anchor: 'right', children: <History /> } })
    }

    const convertJson = (data: any) => {
        let dataList: any[] = []
        data.forEach((data: { status: any[]; description: any, createdDate: string }) => {
            const localdate = new Date(data.createdDate);
            const date = new Date(localdate.getTime() - (localdate.getTimezoneOffset() * 60000)).toISOString().split('T')[0];
            data.status.forEach(target => {
                const description = data.description;
                const status = target.value;
                function getStatusType(status: any) {
                    switch (status) {
                        case 'COMPLETED':
                            return 'success';
                        case 'IN_PROGRESS':
                            return 'processing';
                        case 'DISCARDED':
                            return 'error';
                        case 'ON_HOLD':
                            return 'warning';
                        default:
                            return 'default';
                    }
                }
                const type = getStatusType(status);
                const existingDateEntry = dataList.find(entry => entry.date === date);
                if (existingDateEntry) {
                    existingDateEntry.task.push({ type: type, content: description });
                } else {
                    dataList.push({
                        date: date,
                        task: [{ type: type, content: description }]
                    });
                }
            });
        });
        return dataList

    }

    function convertDate(date: Date) {
        const yyyy = date.getFullYear()
        const mm = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
        const dd = String(date.getDate()).padStart(2, '0');
        const formattedDate = `${yyyy}-${mm}-${dd}`;
        return formattedDate
    }

    const filterStatus = (date: any) => {
        let dataList: any[] = [];
        if (data.length > 0) {
            dataList = convertJson(data)
        }
        console.log(dataList, "Data List")
        if (dataList.length > 0) {
            const filterStatus = dataList.find((item: any) => {
                const convertDateValue = convertDate(date)
                return item.date === convertDateValue
            });
            console.log(filterStatus, "Task Status")
            return filterStatus;
        }
    }

    const cellRenderer = (date: any) => {
        const matchingData = filterStatus(date.$d)
        console.log(matchingData)
        return (
            <ol className='events'>
                {matchingData && matchingData.task.map((item: any) => (
                    <li key={item.content}>
                        <Badge status={item.type as BadgeProps['status']} text={item.content} />
                    </li>
                ))}
            </ol>
        );
    }

    return (
        <>
            <div className='main'>
                <div className="main-container">
                    <div className='heading heading-center-space-between height-40'>
                        <h2>Task Calender</h2>
                    </div>
                    <div className='cal-40' >
                        <div className='container-100 scroll-bar task-calender-container' style={{ paddingRight: '3px' }}>
                            <Calendar
                                fullscreen
                                cellRender={cellRenderer}
                                onSelect={showDrawer}
                                onPanelChange={onPanelChange}

                            />
                        </div>

                    </div>

                </div>
            </div>

        </>

    )
}

export default TaskCalender
import React, { useEffect, useState } from 'react'
import './addStatusChangeReason.css'
import CloseIcon from '@mui/icons-material/Close';
import TextArea from 'antd/es/input/TextArea';
import { FormControl } from '@mui/material';
import ButtonWrapper from '../../widgets/button/button';
import { useForm } from 'react-hook-form';
import { RefreshCellsParams } from 'ag-grid-community';
import { getStatus } from '../../util_service/status/status';
import { IoIosArrowRoundForward } from "react-icons/io";

interface AddStatusChangeReasonProps {
    onClose: () => void
    params: any,
    handleChangeStatusReason: (reason: any) => void
    isStatusChange: boolean,
    changeDetection: any[],
    type?: typeProps

}

type typeProps = {
    type: string;
    field: string
}



const AddStatusChangeReason = (props: AddStatusChangeReasonProps) => {
    const { handleSubmit } = useForm();
    console.log(props.changeDetection, "Add status change")
    console.log(props.type, "Type change")
    const [changeDetactionField, setChangeDetectionField] = useState<any[]>([])

    useEffect(() => {
        if (props.changeDetection.length > 0) {
            console.log(props.changeDetection, "Change detection")
            console.log(props.type, "Change detection")
            const changeDetectionCopy = JSON.parse(JSON.stringify(props.changeDetection));
            // if (props.type?.type === 'single') {
            //     if (props.changeDetection.length === 1) {

            //         if (props.type?.field === 'status') {

            //             changeDetectionCopy[changeDetectionCopy.length - 1].completion = undefined
            //             setChangeDetectionField(changeDetectionCopy)
            //         } else if (props.type?.field === 'completion') {
            //             changeDetectionCopy[changeDetectionCopy.length - 1].status = undefined
            //             setChangeDetectionField(changeDetectionCopy)
            //         } else {
            //             setChangeDetectionField(changeDetectionCopy)
            //         }
            //     }
            // } else {
            //     console.log(props.changeDetection, "For multiple")
            //     console.log(changeDetectionCopy, "Copy")

            //     console.log(changeDetectionCopy, "Change detection copy")

            //     setChangeDetectionField(changeDetectionCopy)
            // }
            setChangeDetectionField(changeDetectionCopy)
        }

    }, [props.changeDetection, props.type?.field, props.type?.type])

    const handleReasonChange = (e: any, taskId: any, name: string) => {
        const value = e.target.value

        changeDetactionField.forEach(element => {
            if (element.taskId === taskId) {
                if (element.status && element.status.name === name) {
                    element.status.value = value;
                } else if (element.completion && element.completion.name === name) {
                    element.completion.value = value;
                }
            }
        });


    }


    const onsubmit = (data: any) => {
        props.params.forEach((item: any, index: number) => {
            changeDetactionField.map((element: any, ind: number) => {
                if (item.node.data.id === element.taskId) {

                    item.node.data.status[0].reason = element.status ? element.status.value : item.node.data.status[0].reason
                    item.node.data.completionTargets[0].reason = element.completion ? element.completion.value : item.node.data.completionTargets[0].reason

                    let refresh: RefreshCellsParams = {
                        force: true,
                        rowNodes: [item.node]
                    }
                    item.api.refreshCells(refresh)
                }

            })
            setChangeDetectionField(changeDetactionField)

        })
        props.handleChangeStatusReason(changeDetactionField)
        props.onClose()

    }


    return (
        <div className='task-reason-main-container'>
            <div className="modal-heading">
                <h4>Reason</h4>
                <CloseIcon
                    className='modal-close-button'
                    onClick={props.onClose}
                />
            </div>
            <div className="modal-body">
                <form onSubmit={handleSubmit(onsubmit)}>
                    <div className='reason-field-container'>
                        {
                            changeDetactionField.map((element: any, index: number) => {
                                return <>
                                    {element.status ? <FormControl fullWidth>
                                        <div style={{ height: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline' }}>
                                            <label className='label-container single-line-text label-width'>{element.description}</label><div className='status-main-container'><div className='status-sub-container'><span className='preav-status-container'>{element.previousStatus}</span><span className='arrow-button' ><IoIosArrowRoundForward /></span></div><span className='present-status-container'>{element.presentStatus}</span></div></div>
                                        <TextArea
                                            aria-label="empty textarea"
                                            placeholder={`${element.status?.placeholder}`}
                                            maxLength={100}
                                            value={element.status.value}
                                            required={element.task === 'ON_HOLD'}
                                            onChange={(e) => handleReasonChange(e, element.taskId, element.status.name)}
                                        />
                                    </FormControl> : null}
                                    {element.completion ? <FormControl fullWidth>
                                        <div style={{ height: '100%', display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline' }}>
                                            <label className='label-container single-line-text label-width'>{element.description}</label><div className='status-main-container'><div className='status-sub-container'><span className='preav-status-container'>{element.previousDate}</span><span className='arrow-button' ><IoIosArrowRoundForward /></span></div><span className='present-status-container'>{element.presentDate}</span></div></div>
                                        <TextArea
                                            aria-label="empty textarea"
                                            placeholder={`${element.completion?.placeholder}`}
                                            maxLength={100}
                                            value={element.completion.value}
                                            required
                                            onChange={(e) => handleReasonChange(e, element.taskId, element.completion.name)}
                                        />
                                    </FormControl> : null}
                                </>

                            })
                        }
                    </div>

                    <div className='leave-application-button-container'>
                        <ButtonWrapper title='save' id='submit-button' variant='contained' type='submit' />

                    </div>


                </form>
            </div>
        </div>
    )
}

export default AddStatusChangeReason

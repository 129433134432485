import React, { useState } from 'react'
import DeleteIcon from '@mui/icons-material/Delete';
import EditNoteIcon from '@mui/icons-material/EditNote';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import swal from 'sweetalert';
import { ButtonList } from 'sweetalert/typings/modules/options/buttons';
import { deleteHoliday, deleteSingleEmployee } from '../../api-services/employee-services';
import FetchApi from '../../fetch-api/fetch-api';
import { Box } from '@mui/system';
import { Modal, Typography } from '@mui/material';
import EditHoliday from '../../componant/editHoliday/editHoliday';
import { SnackbarService } from '../../services/snackBehaviorSubject/snackBehaviorSubject';
import { modalStyle } from '../../style/style';
import ButtonWrapper from '../../widgets/button/button';
import ModalWrapper from '../../widgets/modal/modal';




const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        border: '1px solid rgb(39,112,222)',
        borderRadious: "2px",
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        // boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

interface CustomCellRendererProps {
    value: string;
    onEdit?: (param: any) => void;
    onDelete?: (param: any) => void;
    actionFlag?: boolean
}
const CustomCellRender: React.FC<CustomCellRendererProps> = ({ value, onEdit, onDelete, actionFlag }) => {
    console.log(value, "Valuewwwwww")
    const handleDelete = async (para: any) => {
        if (onDelete)
            onDelete(para.data)

    }

    const handleEdit = (para: any) => {
        if (onEdit)
            onEdit(actionFlag ? para : para.data)
    }
    return (
        <div style={{ display: "flex", gap: "5%" }} >
            {onDelete ? <ButtonWrapper buttonType='delete' onClick={() => handleDelete(value)} tooltipTitle='Delete' tooltipPlacement='left' /> : ''}
            <ButtonWrapper buttonType='edit' className='edit-employee-button' tooltipTitle="Edit" tooltipPlacement='right' onClick={() => handleEdit(value)} />
            {
                <>

                    {/* <ModalWrapper open={modalOpen} close={handleModalClose} children={<EditHoliday onClose={handleModalClose} data={value} />} /> */}
                </>
            }
        </div >
    )
}

export default CustomCellRender;


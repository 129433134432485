import React, { useEffect, useState } from 'react'
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import { SnackbarService } from '../../services/snackBehaviorSubject/snackBehaviorSubject';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

interface State extends SnackbarOrigin {
    open: boolean;
    severity: AlertColor
}
interface snackBarWidgetProps {

    data: {
        open: boolean,
        severity?: string,
        message?: string
    }

}
const SnackbarWidget = (props: snackBarWidgetProps) => {
    const snackbarService = SnackbarService()
    const [openSnackBar, setOpenSnackBar] = useState<State>({
        open: false,
        vertical: 'top',
        horizontal: 'right',
        severity: 'success',
    })
    const { vertical, horizontal, open } = openSnackBar;
    const [alertText, setAlertText] = useState<string | undefined>('')

    useEffect(() => {
        console.log(props)
        setOpenSnackBar((preav: any) => ({
            ...preav,
            open: props.data.open,
            severity: props.data.severity
        }))
        setAlertText(props.data.message)
    }, [props])
    console.log(props)

    const handleCloseSnackBar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        snackbarService?.next({ data: { open: false, message: '' } })
    }
    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={open}
                autoHideDuration={2000}
                onClose={handleCloseSnackBar}
                message="hi"
            >
                <Alert onClose={handleCloseSnackBar} severity={openSnackBar.severity} sx={{ width: '100%' }}>
                    {alertText}
                </Alert>
            </Snackbar>
        </div>
    )
}


export default SnackbarWidget

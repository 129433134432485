import React, { useState } from 'react'
import './account.css'
import Button from '@mui/material/Button'
import Modal from '@mui/material/Modal'
import { Box } from '@material-ui/core'
import Typography from '@mui/material/Typography'
import { JwtModel } from '../../../model/token-model/token.model'
import jwt from 'jwt-decode';
import ResetPassword from '../../../componant/reset-password/reset-password'
import { modalStyle } from '../../../style/style'
import ModalWrapper from '../../../widgets/modal/modal'
import { TokenService } from '../../../services/token.service'

const AdminAccount = () => {
    const style = modalStyle()
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const handleModalOpen = () => setModalOpen(true);
    const handleModalClose = () => setModalOpen(false);
    const extractToken = TokenService.extractToken()

    const onResetPassword = () => {
        handleModalOpen()
    }
    return (
        <div className='main'>
            <div className='main-container'>
                <div className='account-heading-container'>
                    <div className='heading'>
                        <h2>Account</h2>
                    </div>
                    <Button
                        id="submit-btn"
                        variant='contained'
                        onClick={onResetPassword}
                    >Change Password</Button>
                    <ModalWrapper open={modalOpen} close={handleModalClose} children={extractToken && <ResetPassword onClose={handleModalClose} user={extractToken} />} />

                </div>
                <div className='account-sub-container'>

                </div>
            </div>

        </div>
    )
}

export default AdminAccount
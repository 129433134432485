import { Dashboard } from "@mui/icons-material";
import { ActionTypes } from "../constants/constants";

const calculateWorkingHours = (clockIn: Date, clockOut: Date): string => {
  const diffInMilliseconds = clockOut.getTime() - clockIn.getTime();
  const hours = Math.floor(diffInMilliseconds / (1000 * 60 * 60));
  const minutes = Math.floor(
    (diffInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
  );
  return `${hours}h ${minutes}m`;
};

const initialEmployee: any = {
  employee: [],
  clockStatus: [],
  attendance: [],
  holiday: [],
  salary: [],
};
export const employeeReducer = (state = initialEmployee, action: any) => {
  switch (action.type) {
    case ActionTypes.SET_EMPLOYEE:
      return { ...state, employee: action.payload };
      break;
    case ActionTypes.ADD_EMPLOYEE:
      return { ...state, employee: [...state.employee, action.payload] };
      break;
    case ActionTypes.UPDATE_EMPLOYEE:
      {
        const updatedEmployeeArray = state.employee.map((item: any) =>
          item.employee_id === action.payload.employee_id
            ? action.payload
            : item
        );
        return { ...state, employee: updatedEmployeeArray };
      }
      break;
    case ActionTypes.CLOCK_IN:
      return {
        ...state,
        clockStatus: [
          ...state.clockStatus,
          {
            date: action.payload.toLocaleDateString(),
            clockIn: action.payload.toLocaleTimeString(),
          },
        ],
      };

      break;
    case ActionTypes.CLOCK_OUT:
      const updatedClockStatus = state.clockStatus.map((entry: any) =>
        entry.date === new Date().toLocaleDateString()
          ? {
            ...entry,
            clockOut: new Date().toLocaleTimeString(),
            workingHours: calculateWorkingHours(
              new Date(entry.date + " " + entry.clockIn),
              new Date()
            ),
          }
          : entry
      );
      return { ...state, clockStatus: updatedClockStatus };
      break;
    case ActionTypes.SET_ATTENDANCE:
      return {
        ...state,
        attendance: action.payload,
      };
    case ActionTypes.SET_HOLIDAY:
      return { ...state, holiday: action.payload };
      break;
    case ActionTypes.UPDATE_HOLIDAY:
      return { ...state, holiday: action.payload };
    case ActionTypes.SET_SALARY:
      return { ...state, salary: action.payload };
    default:
      return state;
  }
};

const initialTeamManagement: any = {
  admin_team: [],
  user_team: [],
  team_config: [],
  dashboard_config: {}
}

export const teamManagementReducer = (state = initialTeamManagement, action: any) => {
  switch (action.type) {
    case ActionTypes.SET_ADMIN_TEAM: return { ...state, admin_team: action.payload };
    case ActionTypes.SET_USER_TEAM: return { ...state, user_team: action.payload }
    case ActionTypes.SET_TEAM_CONFIG: return { ...state, team_config: action.payload }
    case ActionTypes.SET_DASHBOARD_CONFIG: return { ...state, dashboard_config: action.payload };
    default: return state
  }
}

const initialConfiguration: any = {
  overtime_config: false,
  leave_config: [],
  leave_review_config: []
}

export const Configuration = (state = initialConfiguration, action: any) => {
  switch (action.type) {
    case ActionTypes.SET_OVERTIME_CONFIG: return { ...state, overtime_config: action.payload };
    case ActionTypes.SET_LEAVE_CONFIG: return { ...state, leave_config: action.payload };
    case ActionTypes.SET_LEAVE_REVIEW_CONFIG: return { ...state, leave_review_config: action.payload };
    default: return state
  }
}


const initialTask: any = {
  userTask: []
}

export const task = (state = initialTask, action: any) => {
  switch (action.type) {
    case ActionTypes.SET_USER_TASK: return { ...state, userTask: action.payload }
    default: return state
  }
}


const initialLeave: any = {
  leaveReview: [],
  leave: [],
}

export const userLeaveDetails = (state = initialLeave, action: any) => {
  switch (action.type) {
    case ActionTypes.SET_USER_LEAVE_REVIEW: return { ...state, leaveReview: action.payload };
    case ActionTypes.SET_USER_LEAVE: return { ...state, leave: action.payload }
    default: return state
  }
}

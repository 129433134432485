export const getStatus = (value: any) => {
    console.log(value, "Valuessssssssssss")
    switch (value) {
        case 'PENDING':
            return { value: 'Pending', color: 'gray' };
        case 'COMPLETED':
            return { value: 'Completed', color: 'green' };
        case 'IN_PROGRESS':
            return { value: 'In Progress', color: 'blue' };
        case 'ON_HOLD':
            return { value: 'On Hold', color: 'var(--secondaryColor)' };
        case 'DISCARDED':
            return { value: 'Discarded', color: 'var(--dangerColor)' };
        default:
            return value
    }
};
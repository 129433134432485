import React, { useEffect, useMemo, useState } from 'react';
import './employee.css'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { useSelector } from 'react-redux';
import { Avatar, Stack, Tooltip } from '@mui/material';
import EmployeeRegistration from '../../../componant/employee-registration/employee-registration';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import CustomCellRender from '../../custom-cell-render/custom-cell-render';
import CurrencyCellRenderer from '../../currency-cell-render/currency-cell-render';
import { currencyCode } from '../../../services/constant';
import EditEmployee from '../../../componant/edit-employee/edit-employee';
import { EmployeeModel } from '../../../model/employee-model/employee.model';
import FetchApi from '../../../fetch-api/fetch-api';
import { Grid } from '@material-ui/core';
import ButtonWrapper from '../../../widgets/button/button';
import AgGridContainer from '../../../componant/gridContainer/gridContainer';
import { deleteSingleEmployee } from '../../../api-services/employee-services';
import { SnackbarService } from '../../../services/snackBehaviorSubject/snackBehaviorSubject';
import { minWidth } from '@mui/system';


const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        border: '1px solid rgb(39,112,222)',
        borderRadious: "2px",
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        // boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

const AdminEmployee = () => {
    const snackbarService = SnackbarService()
    const employeeState = useSelector((state: any) => state.allEmployee)
    const [rowData, setRowData] = useState<EmployeeModel[]>([]);
    const [renderEmployeeRegistration, setRenderEmployeeRegistration] = useState<boolean>(false);
    const [renderEditEmployee, setRenderEditEmployee] = useState<boolean>(false);
    const [editEmployee, setEditEmployee] = useState({});
    const [employeeName, setEmployeeName] = useState<string>("");
    const currency = currencyCode()

    const fetchApi = FetchApi()
    const fetchData = () => {
        fetchApi.fetchAllEmployee()
    }
    useEffect(() => {
        fetchData()
    }, [])
    useEffect(() => {
        if (employeeState) {
            setRowData(employeeState.employee)
        }
    }, [employeeState])



    const handleEditEmployees = (para: any) => {
        if (para !== undefined) {
            setRenderEditEmployee(true)
            setEditEmployee(para)
            setEmployeeName(para.name)

        }
    }

    const [columnDefs] = useState<any[]>([
        {
            headerName: "ID",
            field: "employee_id",
            minWidth: 80,
            flex: 1,
            resizable: true,
            filter: true,
            pinned: 'left',
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Name",
            field: "name",
            minWidth: 100,
            flex: 1,
            resizable: true,
            filter: true,
            pinned: 'left',
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Official Email",
            field: "officialEmail",
            resizable: true,
            filter: true,
            lockPinned: true,
            minWidth: 200,
            flex: 1,
            // minWidth: 150,
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellClass: 'flex-start-center',

        },
        {
            headerName: "Personal Email",
            field: "personalEmail",
            lockPinned: true,
            resizable: true,
            filter: true,
            minWidth: 200,
            flex: 1,
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Designation",
            field: "designation",
            lockPinned: true,
            resizable: true,
            filter: true,
            minWidth: 200,
            flex: 1,
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Contact No",
            field: "contact_no",
            lockPinned: true,
            filter: true,
            minWidth: 130,
            flex: 1,
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Blood Group",
            field: "blood_group",
            lockPinned: true,
            filter: true,
            minWidth: 140,
            flex: 1,
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Gross Salary",
            field: "grossSalary",
            lockPinned: true,
            filter: true,
            minWidth: 150,
            flex: 1,
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellRenderer: (params: any) => (
                <CurrencyCellRenderer value={params.value} currencyCode={currency} />
            ),
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Action",
            field: "",
            lockPinned: true,
            width: 90,
            cellRenderer: (params: any) => (
                <CustomCellRender value={params} onEdit={handleEditEmployees} onDelete={handleDeleteEmployee} />


            ),
            cellClass: 'flex-start-center',
        },
    ]);


    const handleDeleteEmployee = (employee: any) => {

        deleteSingleEmployee(employee.employee_id).then((response: any) => {
            if (response.data.success) {
                fetchData()
                snackbarService.next({ data: { open: true, severity: 'success', message: response.data.message } })
            } else {
                snackbarService.next({ data: { open: true, severity: 'error', message: response.data.message } })
            }
        })
    }

    const handleAddEmployee = () => {
        setRenderEmployeeRegistration(!renderEmployeeRegistration);
    }
    const handleBack = () => {
        setRenderEmployeeRegistration(false);
        setRenderEditEmployee(false);
    }

    const handleRenderEditEmployee = (flag: boolean) => {
        if (flag)
            setRenderEditEmployee(!flag)
    }


    return (
        <>
            <div className='main'>
                <div className='main-container'>
                    <div className='heading heading-center-space-between height-40'>
                        {
                            renderEmployeeRegistration || renderEditEmployee ?
                                <Grid container className='sub-heading-container'>
                                    <Grid item className='back-arrow  flex-start-center'><LightTooltip title="back" placement='right-start'>

                                        <ArrowBackIcon className='back-button' onClick={() => handleBack()} />

                                    </LightTooltip>
                                    </Grid>
                                    <Grid item className='flex-start-center'>
                                        {renderEmployeeRegistration ? <h2>Employee Registration</h2> : renderEditEmployee ? <h2 >Edit Employee</h2> : ""}
                                    </Grid>
                                </Grid> :
                                <Grid container >
                                    <Grid item xs={11} className='flex-start-center'>
                                        <h2>Employee List</h2>
                                    </Grid>
                                    <Grid item xs={1} className='flex-end-center'>
                                        <ButtonWrapper buttonType='circle' className='circular-add-button' onClick={handleAddEmployee} tooltipTitle='Add Employee' tooltipPlacement='left' />
                                    </Grid>
                                </Grid>
                        }
                    </div>
                    <div className='sub-container cal-40'>

                        {
                            renderEmployeeRegistration ? <EmployeeRegistration /> : renderEditEmployee ?
                                <EditEmployee editableEmployee={editEmployee} renderEditEmployee={renderEditEmployee} handleRenderEditEmployee={handleRenderEditEmployee} employeeName={employeeName} />
                                :
                                <Grid item style={{ height: '100%' }}>
                                    <div className='employee-container'>
                                        <AgGridContainer rowData={rowData} columnDefs={columnDefs} />
                                    </div>
                                </Grid>
                        }
                    </div>
                </div>

            </div >
        </>
    );
};

export default AdminEmployee




import React, { useState } from 'react'
import './forgot-password.css'
import TextField from '@mui/material/TextField';
import { Button } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { postForgotPassword } from '../../api-services/employee-services';
import CloseIcon from '@mui/icons-material/Close';
import { SnackbarService } from '../../services/snackBehaviorSubject/snackBehaviorSubject';
import { Stack } from '@mui/system';
import { FormControl } from '@mui/material';


interface ForgotPasswordProps {
    onClose: () => void
}

const ForgotPassword = (props: ForgotPasswordProps) => {
    const snackbarService = SnackbarService()
    const { control, handleSubmit } = useForm();
    const [userName, setUserName] = useState('')
    const onUserNameChange = (e: any) => {
        setUserName(e.target.value)
    }


    const onSubmit = (data: any) => {
        postForgotPassword(userName).then((response: any) => {
            if (response?.data.success) {
                snackbarService.next({ data: { open: true, severity: 'success', message: response.data.message } })
                setTimeout(() => {
                    props.onClose()
                }, 1000)
            } else {
                snackbarService.next({ data: { open: true, severity: 'error', message: response?.data.message } })
            }

        })
        setUserName('')
    }

    return (
        <div className="forgot-main-container">
            <div className='modal-heading'>
                <h4>Forgot Password</h4>
                <CloseIcon
                    className='modal-close-button'
                    onClick={props.onClose}
                />
            </div>
            <div className='modal-body'>

                <form onSubmit={handleSubmit(onSubmit)} style={{ height: '100%' }}>
                    <div className='fields-container'>
                        <FormControl sx={{ minWidth: 120 }} fullWidth>
                            <Controller
                                name=""
                                control={control}
                                render={({ field }) => (

                                    <TextField
                                        {...field}
                                        id="outlined-basic"
                                        variant="outlined"
                                        label="Enter Your Username"
                                        onChange={onUserNameChange}
                                        value={userName}
                                        required
                                    />

                                )}
                            />

                        </FormControl>
                    </div>
                    <div className='forgot-submit-button-container'>
                        <Button
                            id="submit-btn"
                            variant='contained'
                            type="submit"
                        >Submit</Button>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default ForgotPassword
import React, { useEffect, useState } from 'react'
import "./attendance.css"
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import DownloadIcon from '@mui/icons-material/Download';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Fade, Menu, MenuItem, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';
import { utils } from 'xlsx';
import { writeFile } from 'xlsx';
import { getAllEmployeeAttendance } from '../../../api-services/employee-services';
import AttendanceDetails from '../../user/attendance-details/attendance-details';
import ButtonWrapper from '../../../widgets/button/button';
import ModalWrapper from '../../../widgets/modal/modal';
import { useSelector } from 'react-redux';
import { calculateOvertime } from '../../../util_service/overtimeCalculation/overtimeCalculation';
import { formateTimeZone } from '../../../util_service/timeZoneFormater/timeZoneFormater';
import { formateDate } from '../../../util_service/dateFormater/dateFormater';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import CustomDatePicker from '../../../widgets/datePicker/datePicker';



const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        border: '1px solid rgb(39,112,222)',
        borderRadious: "2px",
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 1, 0, 1)',
        fontSize: 11,
    },
}));

interface EmployeeAttendance {
    employee_id: string;
    employee_name: string;
    designation: string;
    attendanceDetails: AttendanceDetail[];
}
interface AttendanceDetail {
    agenda: string
    clockIn: string
    clockInDate: string
    clockOut: string
    employee_id: string
    workUpdate: string
    workingHours: string
}


const AdminAttendance = () => {
    const state = useSelector((state: any) => state.configuration)
    const [inputField, setInputField] = useState([
        { name: 'startDate', label: 'Form Date', type: 'date', isOpen: false },
        { name: 'endDate', label: 'To Date', type: 'date', isOpen: false }
    ])


    const [minDate, setMinDate] = useState()
    const [allAttendance, setAllAttendance] = useState<EmployeeAttendance[]>([])
    const [sortedallAttendance, setSortedAllAttendance] = useState<EmployeeAttendance[]>([])
    const [downloadAttendanceData, setDownloadAttendanceData] = useState<EmployeeAttendance[]>([])
    const [openModal, setOpen] = useState<boolean>(false);
    const handleOpen = () => {
        setOpen(true)
    };
    const handleClose = () => setOpen(false);


    const [selectedDate, setSelectedDate] = useState<any>({
        startDate: '',
        endDate: ''
    });
    const [showDownloadButton, setShowDownloadButton] = useState(false)
    const [elements, setElement] = useState<any>()

    const fetchData = async () => {
        getAllEmployeeAttendance().then((response: any) => {
            if (response?.data.success) {
                const employeesWithOvertime = response.data.payload.map((employee: { attendanceDetails: any; }) => {
                    const attendanceWithOvertime = calculateOvertime(employee.attendanceDetails);
                    return {
                        ...employee,
                        attendanceDetails: attendanceWithOvertime
                    };
                });
                setAllAttendance(employeesWithOvertime)
                filterDataForSelectedDate(null, null, employeesWithOvertime)
                console.log(response.data)

            }
        })
    }

    const dateFormate = (data: any) => {
        const FromatedDate = formateDate(data)
        const newFormateDate = new Date(FromatedDate)
        return newFormateDate;
    }
    useEffect(() => {
        fetchData()



    }, [])

    useEffect(() => {
        if (selectedDate && selectedDate.startDate !== '' && selectedDate.endDate !== '') {
            const startDate = new Date(selectedDate.startDate);
            startDate.setHours(0, 0, 0, 0)
            const endDate = new Date(selectedDate.endDate);
            endDate.setHours(0, 0, 0, 0)
            filterDataForSelectedDate(startDate, endDate)

        }
    }, [selectedDate])

    useEffect(() => {
        if (selectedDate.startDate !== '') {
            setMinDate(selectedDate.startDate)
        }
    }, [selectedDate])

    const filterDataForSelectedDate = (startDateData?: any, endDateData?: any, response?: EmployeeAttendance[]) => {
        if (response !== undefined) {
            var attendanceData = response
            setSortedAllAttendance(response)
        } else {
            var attendanceData = allAttendance
        }
        const today = new Date()
        let startDate: Date = new Date(startDateData !== null ? startDateData : today)
        let endDate: Date = new Date(endDateData !== null ? endDateData : today)
        startDate.setHours(0, 0, 0, 0)
        endDate.setHours(0, 0, 0, 0)
        const timeDifference = endDate.getTime() - startDate.getTime();
        const numberOfDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) + 1;
        if (startDate && endDate) {
            const filteredData = attendanceData?.map((employee: any) => ({
                ...employee,
                attendanceDetails: employee.attendanceDetails.filter((detail: any) => {
                    const detailDate = dateFormate(detail.clockInDate);
                    detailDate.setHours(0, 0, 0, 0)
                    if (numberOfDays > 1) {
                        return detailDate >= startDate && detailDate <= endDate;
                    } else {
                        return detailDate.getTime() === startDate.getTime() && detailDate.getTime() === endDate.getTime();
                    }

                }).sort((a: any, b: any) => {
                    const dateA = new Date(a.clockInDate);
                    const dateB = new Date(b.clockInDate);
                    return dateA.getTime() - dateB.getTime();
                }),
            }));
            if (numberOfDays > 7) {
                setShowDownloadButton(true);
                setDownloadAttendanceData(filteredData)
            } else if (numberOfDays <= 7) {
                setShowDownloadButton(false);
                setSortedAllAttendance(filteredData)
            }
        }
    };

    const handleDownloadAttendanceDetails = (e: any) => {
        const header = ['Employee Id', 'Employee Name', 'Designation', 'Attendance'];

        const ws = utils.aoa_to_sheet([header]);
        const newData: any = [];
        const encounteredEmployeeIds: Set<string> = new Set();
        const addOvertime = state.overtime_config === true;
        downloadAttendanceData.map((employee: any) => {
            // Extract employee information
            const employeeId = employee.employeeId;
            const employeeName = employee.employeeName;
            const designation = employee.designation;
            const rowData = [employeeId, employeeName, designation, 'Date', 'In Time', 'Out Time', 'Working Hours'];
            if (addOvertime) {
                rowData.push('Over Time');
            }
            newData.push(rowData)
            newData.push(["", "", "", "", "", "", "", addOvertime ? "" : undefined].filter(x => x !== undefined))
            // Iterate over the "attendanceDetails" array for each employee
            employee.attendanceDetails.forEach((attendanceDetail: any) => {
                const date = attendanceDetail.clockInDate;
                const inTime = formateTimeZone(attendanceDetail.clockIn);
                const outTime = formateTimeZone(attendanceDetail.clockOut);
                const workingHour = attendanceDetail.workingHours;
                const overTime = attendanceDetail.overtimeHours;


                // Create a row of data and push it to newRowData
                const employeeInfoTuple = `${employeeId}-${employeeName}-${designation}`;
                if (!encounteredEmployeeIds.has(employeeInfoTuple)) {
                    const rowData = ["", "", "", date, inTime, outTime, workingHour];
                    if (addOvertime) {
                        rowData.push(overTime);
                    }
                    newData.push(rowData);
                    encounteredEmployeeIds.add(employeeInfoTuple);
                } else {
                    const rowData = ["", "", "", date, inTime, outTime, workingHour];
                    if (addOvertime) {
                        rowData.push(overTime);
                    }
                    newData.push(rowData);
                }

            });
            newData.push(["", "", "", "", "", "", "", addOvertime ? "" : undefined].filter(x => x !== undefined))
        });


        // Create a worksheet with the header and data
        utils.sheet_add_aoa(ws, newData, { origin: 'A2' });

        ws['!merges'] = [{ s: { r: 0, c: 3 }, e: { r: 0, c: 6 } }];
        // Create a new workbook and add the worksheet
        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, 'Attendance Data');

        // Save the workbook as an Excel file
        writeFile(wb, 'attendance_data.xlsx');

    }

    // useEffect(() => {
    //     const width = window.innerWidth
    //     console.log(width, "Width")
    // }, [])

    useEffect(() => {
        const startDate = new Date(selectedDate.startDate);
        startDate.setHours(0, 0, 0, 0)
        let endDate: Date | undefined = undefined;
        if (selectedDate.endDate !== '') {
            endDate = new Date(selectedDate.endDate);
            endDate.setHours(0, 0, 0, 0)
        }
        if (endDate) {
            if (startDate > endDate) {
                setSelectedDate((preav: any) => {
                    return { ...preav, endDate: '' }
                })
            }
        }


    }, [selectedDate.endDate, selectedDate.startDate])

    const dateFormates = (date: any) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate
    }


    const handleDate = (e: any, field: any) => {
        const date = new Date(e);
        const values = dateFormates(date)

        console.log(field)

        setSelectedDate((preav: any) => {
            return { ...preav, [field]: values }
        })
    }

    const handleDetails = (element: any) => {
        handleOpen()
        setElement(element)
    }

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openFilterModal = Boolean(anchorEl);

    const handleOpenFilter = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFilter = () => {
        setAnchorEl(null);
    };



    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [showFilter, setFilter] = useState(false);


    const handleScreenSize = () => {

        const width = window.innerWidth

        if (width !== screenWidth) {
            setScreenWidth(width)
        }

    }

    useEffect(() => {
        window.addEventListener('resize', handleScreenSize);
    }, [])


    useEffect(() => {
        if (screenWidth < 460 || (screenWidth >= 600 && screenWidth <= 773)) {
            setFilter(true)
        } else {
            setFilter(false)
            setAnchorEl(null)
        }
        window.addEventListener('resize', handleScreenSize);
    }, [screenWidth])

    useEffect(() => {
        if (selectedDate.startDate !== '' && selectedDate.endDate !== '') {
            handleCloseFilter()
        }
    }, [selectedDate])






    return (
        <>

            <div className='main'>
                <div className="main-container">
                    <div className='heading heading-center-space-between height-40'>

                        <h2>Attendance List</h2>

                        <div className='header-sub-container'>
                            <div className='date-range-sub-container'>
                                {
                                    !showFilter ? inputField.map((item: any, index: number) => {
                                        return <div key={index} className='date-sub-container'>
                                            <CustomDatePicker label={item.label} name={item.name} selectedDate={selectedDate} handleDate={handleDate} minDate={item.name === 'endDate' ? minDate : undefined} />
                                        </div>
                                    }) :
                                        <div style={{ display: 'flex' }}
                                            onClick={handleOpenFilter}
                                            aria-controls={openFilterModal ? 'fade-menu' : undefined}
                                            aria-haspopup="true"
                                            aria-expanded={openFilterModal ? 'true' : undefined}
                                        >
                                            < FilterAltOutlinedIcon style={{ color: 'var(--primaryColor)' }} />
                                            <label style={{ fontSize: '10px', marginTop: '9px', color: 'var(--primaryColor)', fontWeight: 'bold' }}>Filter</label>
                                        </div>

                                }
                                <Menu
                                    id="fade-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'fade-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={openFilterModal}
                                    onClose={handleCloseFilter}
                                    TransitionComponent={Fade}

                                >

                                    {
                                        inputField.map((item: any, index: number) => {
                                            return < MenuItem className='menu-item-container'>
                                                <div className='date-picker-container'>
                                                    <CustomDatePicker label={item.label} name={item.name} selectedDate={selectedDate} handleDate={handleDate} />
                                                </div>
                                            </MenuItem>
                                        })
                                    }

                                </Menu>


                            </div>
                            {showDownloadButton ? <div className='download-button-main-container'>
                                <LightTooltip title='Download Excel'><Button
                                    variant='contained'
                                    onClick={(e) => handleDownloadAttendanceDetails(e)}
                                    className='download-button-container download-button'
                                ><DownloadIcon /></Button></LightTooltip>
                            </div> : ''}
                        </div>

                    </div>
                    <div className='cal-40'>
                        <div style={{ height: '100%' }}>
                            <div className='attendance-sub-container'>
                                <div style={{ height: '100%' }}>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th className='table-row-head1'>Id</th>
                                                <th className='table-row-head1'>Name</th>
                                                <th className='table-row-head1'>Designation</th>
                                                <th className='table-row-head'>
                                                    <p className="width20">Date</p>
                                                    <p className="width16">In</p>
                                                    <p className="width16">Out</p>
                                                    <p className="width16">Break</p>
                                                    <p className="width16">Working</p>
                                                    {state.overtime_config && <p className="width16">Over Time</p>}
                                                    <p className="width16">Details</p>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {sortedallAttendance?.length > 0 ? sortedallAttendance.map((ele: any, ind: number) => {
                                                return (
                                                    <tr key={ind}>
                                                        <td className='table-row-head1'>{ele.employeeId}</td>
                                                        <td className='table-row-head1'>{ele.employeeName}</td>
                                                        <td className='table-row-head1'>{ele.designation}</td>
                                                        {ele.attendanceDetails?.map((element: any, i: any) => (
                                                            <td className='table-row-head' key={i}>
                                                                <p className="width20">{element.clockInDate}</p>
                                                                <p className="width16">{formateTimeZone(element.clockIn)}</p>
                                                                <p className="width16">{formateTimeZone(element.clockOut)}</p>
                                                                <p className="width16">{element.breakHours}</p>
                                                                <p className="width16">{element.workingHours}</p>
                                                                {state.overtime_config && <p className="width16">{element.overtimeHours}</p>}
                                                                <p className="width16">
                                                                    <p className='button-class-top'>
                                                                        <ButtonWrapper title='View' onClick={() => handleDetails(element)} />
                                                                    </p>
                                                                </p>

                                                            </td>
                                                        ))}
                                                    </tr>
                                                );
                                            }) : null}
                                            <ModalWrapper open={openModal} close={handleClose} children={<AttendanceDetails data={elements} onClose={handleClose} />} />
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div >
        </>
    )
}

export default AdminAttendance
import React, { useEffect, useState } from 'react'
import './leave.css'
import LeaveDetails from '../../../componant/leave-details/leave-details';
import LeaveApplication from '../../../componant/leave-application/leave-application';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { getEmployeeLeaveDetails } from '../../../api-services/employee-services';
import { modalStyle } from '../../../style/style';
import ButtonWrapper from '../../../widgets/button/button';
import ModalWrapper from '../../../widgets/modal/modal';
import { TokenService } from '../../../services/token.service';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import { setUserLeave } from '../../../redux/actions/actions';

interface userLeaveDetails {
    leaveType: string,
    leaveBreaks: {
        earned: number,
        balance: number,
        carriedOver: number,
        entitled: number,
        taken: number,
        emergecy: number
    }
}
const UserLeave = () => {
    const [userLeveDetails, setUserLeaveDetails] = useState<userLeaveDetails[]>([])
    const [leaveType, setleaveType] = useState()
    const [openModal, setOpenModal] = useState<boolean>(false);
    const handleOpenModal = (item: any) => {
        setOpenModal(true);
        setleaveType(item)
    }
    const handleCloseModal = () => setOpenModal(false);
    const [openLeaveApplicationModal, setOpenLeaveApplicationModal] = useState<boolean>(false);
    const handleCloseLeaveApplicationModal = () => setOpenLeaveApplicationModal(false);
    const handleOpenLeaveApplicationModal = () => setOpenLeaveApplicationModal(true);
    const extractToken = TokenService.extractToken()
    const dispatch: Dispatch = useDispatch()

    useEffect(() => {
        getEmployeeLeaveDetails(extractToken?.sub).then((response: any) => {
            if (response?.data.success)
                setUserLeaveDetails(response.data.payload)
            dispatch(setUserLeave(response.data.payload))
        })
    }, [])

    const handleLeaveApplication = () => {
        setOpenLeaveApplicationModal(true);
    }


    return (
        <>
            <div className='main' >
                <div className='main-container'>
                    <div className="heading heading-center-space-between height-40">
                        <h2>Leave Details</h2>
                        <ButtonWrapper buttonType='circle' className='circular-add-button' onClick={handleLeaveApplication} tooltipTitle='Apply Leave' tooltipPlacement='left' />
                    </div>
                    <div className='cal-40'>
                        <div className='leave-main-container'>
                            <div className='leave-details-heading-container'>
                                <div className='leave-type-head-container'>Type</div>
                                <div className='leave-details-head-container'>Details</div>
                            </div>
                            <div className='user-leave-details-main-container'>
                                <div className='user-leave-details-sub-container'>
                                    {
                                        userLeveDetails.map((item: any, index: number) => {
                                            return (
                                                <div className='leave-sub-container' key={index}>

                                                    <div className='leave-table-sub-container' >
                                                        <div className='leave-table-type-container'>
                                                            <div className='leave-type-container'>
                                                                <div>{item.leaveType}</div>
                                                            </div>
                                                        </div>
                                                        <div className='leave-table-details-container'>
                                                            <div className='leave-table-details-sub-container'>
                                                                <div className='leave-break-sub-container'>
                                                                    <div>
                                                                        <label>Balance</label>
                                                                        <div className='balance-container'>
                                                                            <div className={item.leaveBreaks.balance !== 0 ? 'leave-balance-show-container' : 'leave-balance-show-container1'}>{`${item.leaveBreaks.balance} Days`}</div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <label>Carried Over</label>
                                                                        <div>{item.leaveBreaks.carriedOver}</div>
                                                                    </div>
                                                                    <div>
                                                                        <label>Taken</label>
                                                                        <div>{item.leaveBreaks.taken}</div>
                                                                    </div>
                                                                </div>
                                                                <div className='leave-break-sub-container'>
                                                                    <div>
                                                                        <label>Earned</label>
                                                                        <div>{item.leaveBreaks.earned}</div>
                                                                    </div>
                                                                    <div>
                                                                        <label>Entitled</label>
                                                                        <div>{item.leaveBreaks.entitled}</div>
                                                                    </div>
                                                                    <div>
                                                                        <label>Emergency</label>
                                                                        <div>{item.leaveBreaks.emergency}</div>
                                                                    </div>
                                                                </div>
                                                                <div className='leave-details-container button-class'>
                                                                    <ButtonWrapper className={`view-button`} title='Details' onClick={() => handleOpenModal(item)} titleStyle='button-text-container' />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                                <ModalWrapper open={openLeaveApplicationModal} close={handleOpenLeaveApplicationModal} children={<LeaveApplication onClose={handleCloseLeaveApplicationModal} />} />
                                <ModalWrapper open={openModal} close={handleCloseModal} children={<LeaveDetails items={leaveType} onClose={handleCloseModal} />} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UserLeave
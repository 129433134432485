import React from 'react'
import CardItemContainer from './cardBody/cardItemContainer'
import CardTitleContainer from './cardTitle/cardTitle'
import './card.css'
import { color } from 'framer-motion'
import CardFooter from './cardFooter/cardFooter'

interface CardContainerProps {
    title?: string | React.ReactNode,
    body?: React.ReactNode | string,
    footer?: string | React.ReactNode
}






const CardContainer: React.FC<CardContainerProps> = (props: CardContainerProps) => {


    console.log(props)
    return (
        <div className='card-container'>
            <CardTitleContainer title={props.title} />
            <CardItemContainer item={props.body} />
            <CardFooter item={props.footer} />
        </div>
    )
}

export default CardContainer
import React from 'react'
import ButtonWrapper from '../../widgets/button/button'
import { Button } from '@mui/material'
import { DrawerService } from '../../services/snackBehaviorSubject/snackBehaviorSubject'
import History from '../../componant/auditTask/auditTask'
import ViewTimeline from '../../componant/viewTimeline/viewTimeline'
import './audit-cell-render.css'

interface AuditCellRenderProps {
    value: any
}

const AuditCellRender = (props: AuditCellRenderProps) => {
    console.log(props, "Props in audit cell renderedshgs")
    const drawerService = DrawerService()

    const handleOpen = () => {
        drawerService.next({ data: { open: true, anchor: 'right', children: <div className='audite-cell-view'><History singleTask={props.value} /></div> } })
    }

    return (
        <>
            <div className="button-class">
                <ButtonWrapper className={`view-button`} title={`view`} onClick={handleOpen} />
            </div>
        </>
    )
}

export default AuditCellRender

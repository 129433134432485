import { BehaviorSubject } from 'rxjs';

export const LoaderService = (() => {
    let behaviorSubject: BehaviorSubject<boolean> | null = null;
    return () => {
        if (behaviorSubject === null) {
            behaviorSubject = new BehaviorSubject(false)
        }
        return behaviorSubject
    }
})();
interface SnackbarBehaviorSubjectServiceProps {
    data: {
        open: boolean,
        severity?: string,
        message?: string
    }
}
export const SnackbarService = (() => {
    let snackbarBehaviorSubject: BehaviorSubject<SnackbarBehaviorSubjectServiceProps>;
    return () => {
        if (!snackbarBehaviorSubject) {
            snackbarBehaviorSubject = new BehaviorSubject<SnackbarBehaviorSubjectServiceProps>({ data: { open: false, severity: '', message: '' } })
        }
        return snackbarBehaviorSubject
    }
})();
type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface DrawerBehaviorSubjectServiceProps {
    data: {
        open: boolean;
        anchor: Anchor;
        children: React.ReactNode
    }
}

export const DrawerService = (() => {
    let drawerBehaviorSubject: BehaviorSubject<DrawerBehaviorSubjectServiceProps>
    return () => {
        if (!drawerBehaviorSubject) {
            drawerBehaviorSubject = new BehaviorSubject<DrawerBehaviorSubjectServiceProps>({ data: { open: false, anchor: 'right', children: null } })
        }
        return drawerBehaviorSubject;
    }
})();

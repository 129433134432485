import React from 'react';

interface CurrencyCellRendererProps {
    value: number;
    currencyCode: string;
}

const CurrencyCellRenderer: React.FC<CurrencyCellRendererProps> = ({ value, currencyCode }) => {
    const formattedValue = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currencyCode,
    }).format(value);

    return <span>{formattedValue}</span>;
};

export default CurrencyCellRenderer;

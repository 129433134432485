import React, { useEffect, useState } from 'react'
import './workFromHome.css'
import { getWorkFromHomeDetails } from '../../../../api-services/employee-services';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, Button, Fade, Menu, MenuItem } from '@mui/material';
import ApproveRejectCell from '../../../custom-cell-render/approve-reject-cell';
import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Tooltip, IconButton, Modal } from '@mui/material';
import ViewWorkFromHomeReasonDetails from '../../../../componant/viewWorkFromHomeReasonDetails/viewWorkFromHomeReasonDetails';
import CardActions from '@mui/material/CardActions';
import dayjs from 'dayjs';
import ButtonWrapper from '../../../../widgets/button/button';
import ModalWrapper from '../../../../widgets/modal/modal';
import { formateDate, formateHifDate } from '../../../../util_service/dateFormater/dateFormater';
import CustomDatePicker from '../../../../widgets/datePicker/datePicker';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

interface WorkFromHomeProps {
    employeeId: string,
    employeeName: string,
    wfhDetails: WorkFromHomeDetailsProps[]

}
interface WorkFromHomeDetailsProps {
    wfhId: number,
    fromDate: string,
    toDate: string,
    reasonForWFH: string,
    noOfDays: number,
    leaveStatus: string
}
const WorkFromHome = () => {
    const inputField = [
        { name: 'startDate', label: 'Form Date', type: 'date' },
        { name: 'endDate', label: 'To Date', type: 'date' }
    ]

    const [workFromHomeData, setWorkFromHome] = useState<WorkFromHomeProps[]>([])
    const [sortedWorkFromHomeDate, setSortedWorkFromHomeDate] = useState<WorkFromHomeProps[]>([])
    const [viewData, setViewData] = useState('')
    const [minDate, setMinDate] = useState()

    const [selectedDate, setSelectedDate] = useState<any>({
        startDate: '',
        endDate: ''
    });

    console.log(selectedDate)


    const [openModal, setOpen] = useState<boolean>(false);
    const handleOpen = () => {
        setOpen(true)
    };
    const handleClose = () => setOpen(false);

    const fetchData = () => {
        getWorkFromHomeDetails().then((response: any) => {
            if (response?.data.success) {
                setWorkFromHome(response.data.payload)
                filterDataForSelectedDate(null, null, response.data.payload)
            }
        }).catch((err) => {

        });
    }

    useEffect(() => {
        fetchData()
    }, [])

    function formatDateToCustomFormat(inputDate: any) {
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const customFormat = `${year}-${month}-${day}`; return customFormat;
    }

    useEffect(() => {
        const startDate = new Date(selectedDate.startDate);
        startDate.setHours(0, 0, 0, 0)
        let endDate: Date | undefined = undefined;
        if (selectedDate.endDate !== '') {
            endDate = new Date(selectedDate.endDate);
            endDate.setHours(0, 0, 0, 0)
        }
        if (endDate) {
            if (startDate > endDate) {
                setSelectedDate((preav: any) => {
                    return { ...preav, endDate: '' }
                })
            }
        }


    }, [selectedDate.endDate, selectedDate.startDate])

    useEffect(() => {
        if (selectedDate.startDate !== '') {
            setMinDate(selectedDate.startDate)
        }
    }, [selectedDate])

    useEffect(() => {
        if (selectedDate && selectedDate.startDate !== '' && selectedDate.endDate !== '') {
            const startDate = new Date(selectedDate.startDate);
            startDate.setHours(0, 0, 0, 0)
            const endDate = new Date(selectedDate.endDate);
            endDate.setHours(0, 0, 0, 0)
            filterDataForSelectedDate(startDate, endDate)
        }
    }, [selectedDate])

    const dateFormates = (date: any) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        const formattedDate = `${year}-${month}-${day}`;
        return formattedDate
    }

    const handleDate = (e: any, field: any) => {
        const date = new Date(e);
        const value = dateFormates(date)
        setSelectedDate((preav: any) => {
            return { ...preav, [field]: value }
        })

    }

    const dateFormate = (data: any) => {
        const FormateDate = formateDate(data)
        const newFormateDate = new Date(FormateDate)
        return newFormateDate;
    }

    const filterDataForSelectedDate = (startDate?: any, endDate?: any, response?: WorkFromHomeProps[]) => {
        if (response !== undefined) {
            var workFromHome = response
            setSortedWorkFromHomeDate(response)
        } else {
            var workFromHome = workFromHomeData
        }
        const today = new Date()
        let startDateData: Date = new Date(startDate !== null ? startDate : today)
        let endDateData: Date = new Date(endDate !== null ? endDate : today)
        startDateData.setHours(0, 0, 0, 0)
        endDateData.setHours(0, 0, 0, 0)
        const timeDifference = endDateData.getTime() - startDateData.getTime();
        const numberOfDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24)) + 1;
        if (startDateData && endDateData) {
            const filteredData = workFromHome?.map((employee: any) => ({
                ...employee,
                wfhDetails: employee.wfhDetails.filter((details: any) => {
                    const wfhFromDate = dateFormate(details.fromDate)
                    wfhFromDate.setHours(0, 0, 0, 0)
                    const wfhToDate = dateFormate(details.toDate)
                    wfhToDate.setHours(0, 0, 0, 0)
                    if (numberOfDays >= 1) {
                        // return wfhFromDate >= startDateData && wfhToDate <= endDateData;
                        return (startDateData <= wfhToDate && endDateData >= wfhFromDate);
                    } else {

                    }
                })
            }))
            setSortedWorkFromHomeDate(filteredData)
        }
    }

    const handleDetails = (element: any) => {
        handleOpen()
        setViewData(element)
    }

    const handleEditEmployees = (params: boolean) => {
        if (params) {
            fetchData()
        }
    }


    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openFilterModal = Boolean(anchorEl);

    const handleOpenFilter = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [showFilter, setFilter] = useState(false);



    const handleScreenSize = () => {

        const width = window.innerWidth

        if (width !== screenWidth) {
            setScreenWidth(width)
        }

    }

    useEffect(() => {
        window.addEventListener('resize', handleScreenSize);
    }, [])


    useEffect(() => {
        if (screenWidth < 461 || (screenWidth >= 600 && screenWidth <= 773)) {
            setFilter(true)
        } else {
            setFilter(false)
            setAnchorEl(null)
        }
        window.addEventListener('resize', handleScreenSize);
    }, [screenWidth])

    useEffect(() => {
        if (selectedDate.startDate !== '' && selectedDate.endDate !== '') {
            handleCloseFilter()
        }
    }, [selectedDate])


    return (
        <>
            <div className='main'>
                <div className='main-container'>
                    <div className='heading heading-center-space-between height-40'>
                        <h2 className='single-line-text'>Work From Home</h2>
                        <div className='header-sub-container'>
                            <div className='date-range-sub-container'>
                                {
                                    !showFilter ? inputField.map((item: any, index: number) => {
                                        return <div key={index} className='date-sub-container'>
                                            <CustomDatePicker label={item.label} name={item.name} selectedDate={selectedDate} handleDate={handleDate} minDate={item.name === 'endDate' ? minDate : undefined} />
                                        </div>
                                    }) :
                                        <div style={{ display: 'flex' }} onClick={handleOpenFilter}>
                                            < FilterAltOutlinedIcon style={{ color: 'var(--primaryColor)' }} />
                                            <label style={{ fontSize: '10px', marginTop: '9px', color: 'var(--primaryColor)', fontWeight: 'bold' }}>Filter</label>
                                        </div>

                                }
                                <Menu
                                    id="fade-menu"
                                    MenuListProps={{
                                        'aria-labelledby': 'fade-button',
                                    }}
                                    anchorEl={anchorEl}
                                    open={openFilterModal}
                                    onClose={handleCloseFilter}
                                    TransitionComponent={Fade}
                                >

                                    {
                                        inputField.map((item: any, index: number) => {
                                            return < MenuItem className='menu-item-container' >
                                                <div className='date-picker-container'>
                                                    <CustomDatePicker label={item.label} name={item.name} selectedDate={selectedDate} handleDate={handleDate} />
                                                </div>
                                            </MenuItem>
                                        })
                                    }

                                </Menu>
                            </div>
                        </div>
                    </div>
                    <div className='cal-40'>
                        <div className='work-from-home-sub-container'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th className='wfh-table-head1'>Name</th>
                                        <th className='wfh-table-head1'>Id</th>
                                        <th className='wfh-table-head'>
                                            <p className='width25'>From Date</p>
                                            <p className='width25'>To Date</p>
                                            <p className='width20'>Reason</p>
                                            <p className='width10'>Days</p>
                                            <p className='width20'>Status</p>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        sortedWorkFromHomeDate.length > 0 ? <>
                                            {sortedWorkFromHomeDate.length > 0 && sortedWorkFromHomeDate.map((el: WorkFromHomeProps, ind: number) => {
                                                return <tr key={ind}>
                                                    <td>{el.employeeName}</td>
                                                    <td>{el.employeeId}</td>
                                                    {
                                                        el.wfhDetails.map((ele: any, index: number) => (
                                                            <td style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '50px' }} key={index}>
                                                                <p className="width25">{formateHifDate(ele.fromDate)}</p>
                                                                <p className="width25">{formateHifDate(ele.toDate)}</p>
                                                                <p className="width20">
                                                                    <p className='button-class-top'>
                                                                        <ButtonWrapper title='View' onClick={() => handleDetails(ele)} />
                                                                    </p>
                                                                </p>
                                                                <p className="width10">{ele.noOfDays}</p>
                                                                <p className="width20">
                                                                    <ApproveRejectCell value={ele} handleEditEmployees={handleEditEmployees} actionStaus={true} />
                                                                </p>
                                                            </td>
                                                        ))
                                                    }


                                                </tr>
                                            })
                                            }
                                        </> : null
                                    }
                                </tbody>
                            </table>
                        </div>
                        <ModalWrapper open={openModal} close={handleClose} children={<ViewWorkFromHomeReasonDetails data={viewData} onClose={handleClose} flag={true} title='Reason For Work From Home' />} />
                    </div>
                </div>
            </div>

        </>
    )
}

export default WorkFromHome
import React from 'react'

const sortFromDateToDate = (leaveData: any) => {
    leaveData.sort((a: { leaveStatus: string; fromDate: any; toDate: any; }, b: { leaveStatus: string; fromDate: any; toDate: any; }) => {
        // First, prioritize "Pending" leave requests
        if (a.leaveStatus === "Pending" && b.leaveStatus !== "Pending") return -1;
        if (a.leaveStatus !== "Pending" && b.leaveStatus === "Pending") return 1;

        // Extract year, month, and day from fromDate
        const [yearA, monthA, dayA] = [a.fromDate, a.toDate].map(date => {
            const d = new Date(date);
            return [d.getFullYear(), d.getMonth(), d.getDate()];
        })[0];

        const [yearB, monthB, dayB] = [b.fromDate, b.toDate].map(date => {
            const d = new Date(date);
            return [d.getFullYear(), d.getMonth(), d.getDate()];
        })[0];

        // Compare year first
        if (yearA !== yearB) return yearB - yearA; // Descending order

        // Compare month if year is the same
        if (monthA !== monthB) return monthB - monthA; // Descending order

        // Compare day if both year and month are the same
        return dayB - dayA; // Descending order
    });
    return leaveData
}

export default sortFromDateToDate
import React, { useEffect, useState } from 'react'
import CloseIcon from '@mui/icons-material/Close';
import { detailsField } from '../../services/employeeFieldType';
import ButtonWrapper from '../../widgets/button/button';
import { Controller, useForm } from 'react-hook-form';
import { Autocomplete, FormControl, TextField } from '@mui/material';
import FileDownloader from '../../widgets/fileDownloader/fileDownloader';
import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import './scheduleCandidate.css'
import { searchEmployeeByLetter } from '../../api-services/employee-services';
import { ScheduleCandidateModel } from '../../model/schedule-candidate-model/schedule-candidate-model';

interface Option {
    name: string;
}
interface CandidateDetails {
    [key: string]: ScheduleCandidateModel; // Adjust the type according to the actual structure
}

interface ScheuleCandidateProps {
    onClose: () => void
    scheduleCandidate: CandidateDetails,
    isReschedule?: boolean
}

const fieldMapping = {
    interviewer: 'interviewer',
};


const ScheuleCandidate = (props: ScheuleCandidateProps) => {

    const { control, handleSubmit, setValue, formState: { errors }, register, setError, clearErrors } = useForm();
    const [loader, setLoader] = useState<boolean>(false);
    const [optionData, setOptionData] = useState<any[]>([]);
    const scheduleCandidateField = detailsField().scheduleCandidate
    const scheduleCandidateFieldNames = scheduleCandidateField.map(field => field.name);

    const [candidateDetails, setCandidateDetails] = useState<ScheduleCandidateModel>(
        {
            round: 0,
            candidateId: 0,
            scheduleDate: '',
            rescheduleReason: '',
            interviewer: []
        }
    );
    const [scheduleDateTime, setScheduleDateTime] = React.useState<Dayjs | null>(null);

    useEffect(() => {
        if (candidateDetails.scheduleDate !== '') {
            const date = new Date(candidateDetails.scheduleDate)
            const dayjsDate = dayjs(date)
            console.log(dayjsDate)
            setScheduleDateTime(dayjsDate)
            setValue('scheduleDateTime', dayjsDate)
            clearErrors("scheduleDate");
        }
    }, [candidateDetails.scheduleDate])




    useEffect(() => {
        if (props.scheduleCandidate && props.isReschedule) {
            setCandidateDetails((prev: any) => {
                const filteredCandidate = Object.keys(props.scheduleCandidate)
                    .filter(key => scheduleCandidateFieldNames.includes(key) || key === 'candidateId' || key === 'name')
                    .reduce((obj, key) => {
                        obj[key] = props.scheduleCandidate[key];
                        return obj;
                    }, {} as any);
                return {
                    ...prev,
                    ...filteredCandidate,
                };
            });
        } else {
            setCandidateDetails((prev: any) => {
                const filteredCandidate = Object.keys(props.scheduleCandidate)
                    .filter(key => key === 'candidateId' || key === 'name')
                    .reduce((obj, key) => {
                        obj[key] = props.scheduleCandidate[key];
                        return obj;
                    }, {} as any);
                return {
                    ...prev,
                    ...filteredCandidate,
                };
            })
        }
    }, [])

    const handleAutocompleteChange = (e: any, newValue: any, field: any) => {
        setCandidateDetails((preav: any) => ({
            ...preav,
            [field]: newValue
        }))
        setValue(field, newValue)

    }

    const searchApiCall = async (newValue: any, flag?: boolean) => {
        const result = newValue
        let val;
        try {
            const response: any = await searchEmployeeByLetter(result);

            if (response && response?.data.success) {
                setLoader(false)
                if (flag === true) {
                    val = response.data.payload;
                } else {
                    setOptionData(response.data.payload);
                }
            } else {
                setOptionData([]);
            }
        } catch (error) {
        }
        if (flag === true) {
            return val
        }
    }


    const getSelectedValues = (fieldName: any, fieldValue: any) => {
        switch (fieldName) {
            case fieldMapping.interviewer:
                return [fieldValue.projectLead];
            default:
                return [];
        }
    };

    const debounceTime = function <T>(fn: Function, delay: number) {
        let timer: NodeJS.Timeout;
        return function (this: T, ...args: any[]) {
            setLoader(true)
            clearTimeout(timer);
            timer = setTimeout(() => {
                fn.apply(this, args);
            }, delay);
        }
    }
    const getData = (d1: any, d2: any) => {
        searchApiCall(d1, false)
    }

    const onSearchData = debounceTime(getData, 1000);
    const handleInputChange = (e: any, newValue: any) => {
        if (e && newValue !== "") {
            onSearchData(newValue)
        } else {
            setOptionData([])
        }
    }
    const onDateTimeChange = (e: any) => {
        const dateTime = new Date(e.$d)
        if (dateTime.toString() !== 'Invalid Date') {
            setCandidateDetails((preav: any) => {
                return { ...preav, scheduleDate: e.$d.toString() }
            })
            setScheduleDateTime(e)
            setValue('scheduleDateTime', e)
        } else {
            setCandidateDetails((preav: any) => {
                return { ...preav, scheduleDate: '' }
            })
        }
    }

    const onRoundChange = (e: any) => {
        const { name, value } = e.target
        setCandidateDetails((preav: any) => {
            return { ...preav, [name]: value }
        })
        setValue(name, value)
    }

    const onSubmit = (data: any) => {
        data['candidateId'] = candidateDetails.candidateId
        candidateDetails.scheduleDate = candidateDetails.scheduleDate.toString()
        if (candidateDetails.scheduleDate !== '') {
            if (props.isReschedule) {
                console.log(candidateDetails, "candidate details")
                console.log('Reschedule End point')
            } else {
                console.log('Schedule End point')
                console.log(candidateDetails, "candidate details schedule")
            }
        } else {
            if (!props.isReschedule) {
                setError('scheduleDate', {
                    type: 'manual',
                    message: '*Schedule date time is required',
                });
            } else {
                setError('scheduleDate', {
                    type: 'manual',
                    message: '*Reschedule date time is required',
                });
            }

        }

    }


    let selectedValues: Option[];

    return (
        <div className="main">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="modal-heading">
                    <h4>{props.isReschedule ? 'Reschedule' : 'Schedule'} Interview for {candidateDetails.name}</h4>
                    <CloseIcon className='modal-close-button' onClick={() => props.onClose()} />
                </div>
                <div className="modal-body">
                    <div className='container-100'>
                        <div className='form-field-container'>
                            <div className='form-field-sub-container'>
                                <div style={{ height: '100%', paddingRight: '4px' }}>
                                    {
                                        scheduleCandidateField.map((item: any, index: number) => {
                                            return (
                                                <React.Fragment key={index}>
                                                    {/* Render text fields excluding the 'rescheduleReason' */}
                                                    {item.type !== 'file' && item.type !== 'date' && item.type !== 'select' && item.name !== 'rescheduleReason' ? (
                                                        <FormControl sx={{ mt: 1, minWidth: 120 }} fullWidth key={index}>
                                                            <label className='team-form-container-label'>{item.label}</label>
                                                            <Controller
                                                                name={item.name}
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        type={item.type}
                                                                        id="outlined-basic"
                                                                        variant="outlined"
                                                                        required
                                                                        disabled={item.name === 'round' && props.isReschedule}
                                                                        onChange={onRoundChange}
                                                                        value={item.name === 'round' && candidateDetails.round === 0 ? '' : candidateDetails[item.name as keyof ScheduleCandidateModel]}
                                                                    />
                                                                )}
                                                            />
                                                        </FormControl>
                                                    ) : null}

                                                    {/* Conditionally render the 'rescheduleReason' field */}
                                                    {item.type === 'text' && item.name === 'rescheduleReason' && props.isReschedule ? (
                                                        <FormControl sx={{ mt: 1, minWidth: 120 }} fullWidth key={index}>
                                                            <label className='team-form-container-label'>{item.label}</label>
                                                            <Controller
                                                                name={item.name}
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <TextField
                                                                        {...field}
                                                                        type={item.type}
                                                                        id="outlined-basic"
                                                                        variant="outlined"
                                                                        required
                                                                        disabled={item.disable}
                                                                        onChange={onRoundChange}
                                                                        value={candidateDetails[item.name as keyof ScheduleCandidateModel]}
                                                                    />
                                                                )}
                                                            />
                                                        </FormControl>
                                                    ) : null}

                                                    {/* Render date picker */}
                                                    {item.type === 'date' ? (
                                                        <FormControl sx={{ mt: 1, minWidth: 120 }} fullWidth key={index}>
                                                            <label className='team-form-container-label'>{item.label}</label>
                                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                                <DemoContainer components={['DateTimePicker']}>
                                                                    <DateTimePicker
                                                                        {...register("scheduleDateTime", { required: 'error message' })}
                                                                        value={scheduleDateTime}
                                                                        onChange={onDateTimeChange}
                                                                    />
                                                                </DemoContainer>
                                                            </LocalizationProvider>
                                                        </FormControl>

                                                    ) : null}
                                                    {item.name === 'scheduleDate' && errors.scheduleDate && <span className='error-field'>{(errors.scheduleDate as any).message}</span>}

                                                    {/* Render select */}
                                                    {item.type === 'select' ? (
                                                        <div className='select-type-container'>
                                                            <label className='team-form-container-label'>{item.label}</label>
                                                            <Autocomplete
                                                                limitTags={2}
                                                                id="checkboxes-tags-demo"
                                                                multiple
                                                                options={loader === true ? [{ name: 'Loading...' }] : optionData.filter((option) => {
                                                                    selectedValues = getSelectedValues(item.name, candidateDetails);
                                                                    return !selectedValues.some((selected: { name: any; }) => selected?.name === option.name);
                                                                })}
                                                                disableCloseOnSelect
                                                                disablePortal
                                                                getOptionLabel={(option) => option?.name || ''}
                                                                value={candidateDetails['interviewer']}
                                                                onChange={(e, newValue) => handleAutocompleteChange(e, newValue, item.name)}
                                                                onInputChange={(e, newValue) => handleInputChange(e, newValue)}
                                                                renderOption={(props, option, { selected }) => (
                                                                    <li {...props}>
                                                                        {option.name}
                                                                    </li>
                                                                )}
                                                                renderInput={(params) => (
                                                                    <TextField {...params} required={candidateDetails['interviewer'].length === 0} />
                                                                )}
                                                            />
                                                        </div>
                                                    ) : null}
                                                </React.Fragment>
                                            );
                                        })
                                    }

                                </div>
                            </div>
                        </div>
                        <div className='form-button-container'>
                            <ButtonWrapper title='submit' id='submit-button' variant='contained' type='submit' />
                        </div>

                    </div>
                </div>
            </form>
        </div >
    )
}

export default ScheuleCandidate
import React, { useEffect, useState } from 'react'
import './attendance-details.css'
import CloseIcon from '@mui/icons-material/Close';


interface AttendanceDetailsProps {
    data: {
        clockInDate: string;
        clockIn: Date;
        clockOut: Date;
        workingHours: string,
        agenda: string,
        workUpdate: string
    }
    onClose: () => void;

}
const AttendanceDetails = (props: AttendanceDetailsProps) => {
    return (
        <>
            <div className='main'>
                <div className='modal-heading'>
                    <h4>Work Update</h4>
                    <CloseIcon
                        className='modal-close-button'
                        onClick={props.onClose}
                    />
                </div>
                <div className='modal-body'>
                    <div className='container-100'>
                        <div className='attendance-details-item-container'>
                            <p>Agenda:</p>
                            <p>{props.data.agenda}</p>
                        </div>
                        <div className='attendance-details-item-container'>
                            <p>Work Update:</p>
                            <p>{props.data.workUpdate}</p>
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default AttendanceDetails
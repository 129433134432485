export const getColumnDef = (originalColumnDef: any, fetchColumnDef: any) => {
    const filteredColumnDefs = originalColumnDef.filter((column: any) => {
        if (column.field === "") {
            return true;
        } else if (column.field === 'sno') {
            return true
        } else if (column.field === 'employee_id' || column.field === "employee_name") {
            return true
        } else {
            return fetchColumnDef.some((item: any) => item.name === column.field);

        }
    });
    return filteredColumnDefs
} 
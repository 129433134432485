import axios, { AxiosError } from "axios";
import { LoaderService } from "../services/snackBehaviorSubject/snackBehaviorSubject";
import { TokenService } from "../services/token.service";
import { goToDefaultRoute } from "../_guards/Auth.guard";;
const baseUrl = process.env.REACT_APP_ENDPOINT;
const hideLoaderApi = [`${baseUrl}/team/searchUser`, `${baseUrl}/attendance/isActive`]
const noTokenApi = [`${baseUrl}/employee/generateToken`, `${baseUrl}/employee/forgotPassword`]

const userApi = [`${baseUrl}/attendance/isActive`, `${baseUrl}/team/getTeamDetails`, `${baseUrl}/attendance/getEmpAttendance`, `${baseUrl}/attendance/getAllEmpLOP`, `${baseUrl}/leave/getEmployeeLeave`, `${baseUrl}/leave/getEmpLeveReview`, `${baseUrl}/attendance/getAllWFHForUser`, `${baseUrl}/salary/getEmpSalary`, `${baseUrl}/salary/getEmpAllSalarySlip`, `${baseUrl}/holidays/AllHolidays`, `${baseUrl}/team/getTeamDetails`]

export const get = (url: string, opt?: any): Promise<Response> => {
  return axios.get(url, opt);
};

export const post = (url: string, data?: any, blob?: any): Promise<Response> => {
  return axios.post(url, data, blob);
};

export const deleteData = (url: string): Promise<Response> => {
  return axios.delete(url);
};

export const put = (url: string, data: any): Promise<Response> => {
  return axios.put(url, data);
};

let navigate: ((arg0: string) => void) | null = null;

export const setNavigate = (navigateFunction: any) => {
  navigate = navigateFunction
}



const loader = LoaderService()


axios.interceptors.request.use(
  async (config) => {
    const apiEndpoint = config.url?.split('?')[0];

    if (apiEndpoint && !hideLoaderApi.includes(apiEndpoint)) {
      loader.next(true);
    }
    if (apiEndpoint && noTokenApi.includes(apiEndpoint)) {
      return config
    }
    return tokenBinder(config)

  },
  async (error) => {
    Promise.reject(error);
  }
);

const tokenBinder = (config: any) => {
  const token = TokenService.getToken()
  if (!TokenService.extractToken() || TokenService.isExpired()) {
    checkRoleApi(config)
    return getCancelledApiConfig;

  }
  config.headers.Authorization = `Bearer ${token}`;
  return config;
}

const getCancelledApiConfig = () => {
  const controller = new AbortController()
  const signal = {
    signal: controller.signal,
  }
  controller.abort('We got cancel this')
  return signal;
}






axios.interceptors.response.use(response => {
  const status = response.status
  if (status && status !== null && (status === 200 || status === 204 || status === 201)) {
    loader.next(false)
  }
  return response;
}, error => {
  const status = error.response ? error.response.status : null
  if (status === 401) {
    checkRoleApi(error.config)
    loader.next(false)
  } else if (status === 500) {
    console.log("An ecception occure")
    loader.next(false)
  } else if (status === 400) {
    console.log(error.message)
    loader.next(false)
  } else if (status === null) {
    loader.next(false)
  } else if (status === 403) {
    loader.next(false)
  } else if (status === 404) {
    loader.next(false)
  }
  // return Promise.reject(error);
  return null;
}
);

const checkRoleApi = (config: AxiosError) => {
  const role = TokenService.getRole()
  if (role !== null) {
    goToDefaultRoute()
  } else {
    checkApi(config)
  }
}

const checkApi = (config: any) => {
  const url: string | undefined = config.url
  if (url && userApi.includes(url)) {
    goToDefaultRoute()
    localStorage.clear()
  } else {
    goToDefaultRoute()
    localStorage.clear()
  }
}
export const filterModel = () => {
    const inProgressFilterModel = {
        filterType: 'text',
        type: 'contains',
        filter: 'In Progress'
    };
    const onHoldFilterModel = {
        filterType: 'text',
        type: 'contains',
        filter: 'On Hold'
    };
    const pendingFilterModel = {
        filterType: 'text',
        type: 'contains',
        filter: 'Pending'
    };
    return [inProgressFilterModel, onHoldFilterModel, pendingFilterModel]
}
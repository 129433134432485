import jwtDecode from "jwt-decode";
import { JwtModel } from "../model/token-model/token.model";
import { Role } from "../model/token-model/role.model";

const extractRole = (token: JwtModel | null): Role | null => {
    if (!token) {
        return null;
    }
    return token.roles
}
const isTokenExpired = (token?: JwtModel | null) => {
    if (!token) {
        return null;
    }
    const { exp } = token;
    const currentTimestamp = Math.floor(Date.now() / 1000);
    return currentTimestamp > exp;
}

export const TokenService = {
    setToken: (token: string) => {
        localStorage.setItem('Token', token)
    },

    getToken: (): string | null => {
        const tokenStr = localStorage.getItem('Token');
        if (!tokenStr) {
            return null;
        }
        return tokenStr
    },

    clearToken: () => {
        localStorage.clear();
    },

    extractToken: (): JwtModel | null => {
        const tokenStr = TokenService.getToken();
        if (!tokenStr) {
            return null;
        }
        let token: JwtModel = jwtDecode(tokenStr)
        return token
    },

    getRole: (): Role | null => {
        const token: JwtModel | null = TokenService.extractToken();
        return extractRole(token)
    },

    isExpired: () => {
        const token: JwtModel | null = TokenService.extractToken();
        return isTokenExpired(token)
    },

    isValidRole: (role: Role) => {
        const token: JwtModel | null = TokenService.extractToken();
        if (!token || isTokenExpired(token) || role != token.roles) {
            return false;
        }
        return true;
    }
}
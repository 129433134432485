export const formateDate = (date: any) => {
    console.log(date, "Date inputs")
    const currentDate = date.split('/', 3)
    console.log(currentDate, "Current Dateaaaaa")
    const FormateDate = `${currentDate[2]}-${currentDate[1]}-${currentDate[0]}`
    console.log(FormateDate, "Formated Datea")

    return FormateDate
}
export const formateHifDate = (date: any) => {
    console.log(date, "Date input")
    const currentDate = date.split('-', 3)
    console.log(currentDate, "Current Datea")
    const FormateDate = `${currentDate[2]}/${currentDate[1]}/${currentDate[0]}`
    console.log(FormateDate, "Formated Datea")

    return FormateDate
}
export const formateHifDates = (date: any) => {
    console.log(date, "Date input")
    const currentDate = date.split('-', 3)
    console.log(currentDate, "Current Datea")
    const FormateDate = `${currentDate[0]}/${currentDate[1]}/${currentDate[2]}`
    console.log(FormateDate, "Formated Datea")

    return FormateDate
}


export const formateISODate = (date: any) => {
    if (date !== null) {
        const newDate = new Date(date);

        const year = newDate.getFullYear();
        const month = (newDate.getMonth() + 1).toString().padStart(2, '0');
        const day = newDate.getDate().toString().padStart(2, '0');
        return `${day}-${month}-${year}`;
    }
}



const getMonthName = (monthNumber: any) => {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('en-US', { month: 'long' });
}
export const formateDateWithOutYear = (opt: any) => {
    const newDate = new Date(opt)
    const date = newDate.getDate()
    const month = getMonthName(newDate.getMonth() + 1)
    const updateDate = `${date} ${month}`
    return updateDate;
}

export const formateZoffsetDate = (date: any) => {
    const dateString = date;

    const dateObj = new Date(dateString);

    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, '0'); // Pad month for single digits
    const day = String(dateObj.getDate()).padStart(2, '0'); // Pad day for single digits

    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate
}
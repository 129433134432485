import React, { useEffect, useState } from 'react'
import './payroll.css'
import { useSelector } from 'react-redux'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CurrencyCellRenderer from '../../currency-cell-render/currency-cell-render';
import { currencyCode } from '../../../services/constant';
import { getAllEmployeeSalaryDetails } from '../../../api-services/employee-services';
import { useDispatch } from 'react-redux';
import { setSalary } from '../../../redux/actions/actions';
import AgGridContainer from '../../../componant/gridContainer/gridContainer';
import { minWidth } from '@mui/system';


const AdminPayroll = () => {
    const employeeState = useSelector((state: any) => state.allEmployee)
    const dispatch = useDispatch()
    const [rawData, setRawData] = useState<any[]>([])
    const currency = currencyCode()
    useEffect(() => {
        getAllEmployeeSalaryDetails().then((response: any) => {
            if (response?.data.success) {
                setRawData(response.data.payload)
                dispatch(setSalary(response.data.payload))
            }
        })
    }, [])
    useEffect(() => {
        if (employeeState.salary.length > 0) {
            setRawData(employeeState.salary)
        }
    }, [employeeState])

    const columnDefs = [
        {
            headerName: "ID",
            field: "employee_id",
            minWidth: 80,
            flex: 1,
            resizable: true,
            filter: true,
            pinned: 'left',
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Name",
            field: "name",
            minWidth: 100,
            flex: 1,
            resizable: true,
            filter: true,
            pinned: 'left',
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Designation",
            field: "designation",
            lockPinned: true,
            resizable: true,
            filter: true,
            minWidth: 200,
            flex: 1,
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Gross Salary",
            field: "grossSalary",
            minWidth: 150,
            flex: 1,
            filter: true,
            lockPinned: true,
            cellRenderer: (params: any) => (
                <CurrencyCellRenderer value={params.value} currencyCode={currency} />
            ),
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Basic Salary",
            field: "basicSalary",
            minWidth: 150,
            flex: 1,
            filter: true,
            lockPinned: true,
            cellRenderer: (params: any) => (
                <CurrencyCellRenderer value={params.value} currencyCode={currency} />
            ),
            cellClass: 'flex-start-center',
        },
        {
            headerName: "House Rent Allowance",
            field: "houseRentAllowance",
            minWidth: 200,
            flex: 1,
            filter: true,
            lockPinned: true,
            cellRenderer: (params: any) => (
                <CurrencyCellRenderer value={params.value} currencyCode={currency} />
            ),
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Dearness Allowance",
            field: "dearnessAllowance",
            minWidth: 190,
            flex: 1,
            filter: true,
            lockPinned: true,
            cellRenderer: (params: any) => (
                <CurrencyCellRenderer value={params.value} currencyCode={currency} />
            ),
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Travel Allowance",
            field: "travelAllowance",
            minWidth: 180,
            flex: 1,
            filter: true,
            lockPinned: true,
            cellRenderer: (params: any) => (
                <CurrencyCellRenderer value={params.value} currencyCode={currency} />
            ),
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Medical Allowance",
            field: "medicalAllowance",
            minWidth: 175,
            flex: 1,
            filter: true,
            lockPinned: true,
            cellRenderer: (params: any) => (
                <CurrencyCellRenderer value={params.value} currencyCode={currency} />
            ),
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Special Allowance",
            field: "specialAllowance",
            minWidth: 175,
            flex: 1,
            filter: true,
            lockPinned: true,
            cellRenderer: (params: any) => (
                <CurrencyCellRenderer value={params.value} currencyCode={currency} />
            ),
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Income Tax",
            field: "incomeTax",
            minWidth: 150,
            flex: 1,
            filter: true,
            lockPinned: true,
            cellRenderer: (params: any) => (
                <CurrencyCellRenderer value={params.value} currencyCode={currency} />
            ),
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Professional Tax",
            field: "professionalTax",
            minWidth: 175,
            flex: 1,
            resizable: true,
            filter: true,
            cellRenderer: (params: any) => (
                <CurrencyCellRenderer value={params.value} currencyCode={currency} />
            ),
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Provident Fund",
            field: "providentFund",
            minWidth: 175,
            flex: 1,
            filter: true,
            lockPinned: true,
            cellRenderer: (params: any) => (
                <CurrencyCellRenderer value={params.value} currencyCode={currency} />
            ),
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Employee State Insurance",
            field: "employeeStateInsurance",
            minWidth: 200,
            flex: 1,
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            filter: true,
            lockPinned: true,
            cellRenderer: (params: any) => (
                <CurrencyCellRenderer value={params.value} currencyCode={currency} />
            ),
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Deduction Ammount",
            field: "deductionAmount",
            minWidth: 200,
            flex: 1,
            filter: true,
            lockPinned: true,
            cellRenderer: (params: any) => (
                <CurrencyCellRenderer value={params.value} currencyCode={currency} />
            ),
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Net Salary",
            field: "netSalary",
            minWidth: 170,
            flex: 1,
            filter: true,
            lockPinned: true,
            cellRenderer: (params: any) => (
                <CurrencyCellRenderer value={params.value} currencyCode={currency} />
            ),
            cellClass: 'flex-start-center',
        }
    ]

    return (
        <>
            <div className='main'>
                <div className='main-container'>
                    <div className='heading heading-start-center height-40'>
                        <h2>Payroll</h2>
                    </div>
                    <div className='cal-40'>

                        <div className='wraper'>
                            <AgGridContainer rowData={rawData} columnDefs={columnDefs} />
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}

export default AdminPayroll



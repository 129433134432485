import React, { useEffect, useState } from 'react'
import './viewTimeline.css'
import CloseIcon from '@mui/icons-material/Close';
import TimelineComponent from '../../widgets/timeline/timeline';
import { getStatus } from '../../util_service/status/status';

interface ViewTimelineProps {
    item: any
}

const ViewTimeline = (props: ViewTimelineProps) => {
    console.log(props.item, "Items in viewTimeLine")

    const [taskStatus, setTaskStatus] = useState<any[]>([])

    function formatDate(isoString: string | number | Date) {
        const date = new Date(isoString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
        const year = date.getFullYear();
        return `${day}-${month}-${year}`;
    }

    const mergeByDateTime = (data: any) => {
        console.log(data, "dataaaaaaaaaaaaaaaaaaaaaaaaaaaaaa")

        const combined = [
            ...(data.startDate ? [{
                // value: 'Start Date',
                ...data,
                dateTime: new Date(data.startDate),
                startDate: formatDate(data.startDate),
            }] : []),
            ...data.status.map((item: { statusUpdateDate: string | number | Date; }) => (
                {
                    ...item,
                    statusUpdateDate: formatDate(item.statusUpdateDate),
                    dateTime: new Date(item.statusUpdateDate),
                }
            )),
            ...data.completionTargets.map((item: { completionTargetUpdateDate: string | number | Date; }) => ({
                ...item,
                completionTargetUpdateDate: formatDate(item.completionTargetUpdateDate),
                dateTime: new Date(item.completionTargetUpdateDate),
            })),


        ];
        console.log(combined, "combinedddddddddddddds")
        combined.sort((a, b) => a.dateTime - b.dateTime);

        return combined;
    };

    useEffect(() => {
        const mergedData = mergeByDateTime(props.item);
        console.log(mergedData, "Merged Data");
        setTaskStatus(mergedData)
    }, [props])

    // const getStatus = (value: any) => {
    //     console.log(value, "Valuessssssssssss")
    //     switch (value) {
    //         case 'PENDING':
    //             return { value: 'Pending', color: 'gray' };
    //         case 'COMPLETED':
    //             return { value: 'Completed', color: 'green' };
    //         case 'IN_PROGRESS':
    //             return { value: 'In Progress', color: 'blue' };
    //         case 'ON_HOLD':
    //             return { value: 'Hold', color: 'var(--secondaryColor)' };
    //         case 'DISCARDED':
    //             return { value: 'Discarded', color: 'var(--dangerColor)' };
    //         default:
    //             return value
    //     }
    // };
    console.log(taskStatus, "Task statussss")


    useEffect(() => {
        if (taskStatus.length > 0) {
            taskStatus.forEach((item: any) => {
                console.log(item, "Itemsdd")
                if (item.value !== undefined || item.startDate !== undefined) {
                    const status = getStatus(item.value ? item.value : item.startDate)
                    item.value = status.value
                    item.color = status.color
                }
            })
        }
    }, [taskStatus])

    console.log(taskStatus, "Task statussss after")

    return (
        < >
            <TimelineComponent item={taskStatus} />
        </>
    )
}

export default ViewTimeline

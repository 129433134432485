import React, { useState, useEffect } from 'react';
import "./dashboard.css"
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Box, Stack } from '@mui/system';
import { TextField } from '@mui/material';
import { breakIn, breakOut, getUserTeam, isActiveClock, postClockIn, postClockOut } from '../../../api-services/employee-services';
import jwt from 'jwt-decode';
import { CgDetailsMore } from "react-icons/cg";
import { JwtModel } from '../../../model/token-model/token.model';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';
import { SnackbarService } from '../../../services/snackBehaviorSubject/snackBehaviorSubject';
import ButtonWrapper from '../../../widgets/button/button';
import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import FetchApi from '../../../fetch-api/fetch-api';
import { useNavigate } from 'react-router';
import { TokenService } from '../../../services/token.service';
import { Role } from '../../../model/token-model/role.model';
import { formateTimeZone } from '../../../util_service/timeZoneFormater/timeZoneFormater';
import { formateHifDate } from '../../../util_service/dateFormater/dateFormater';
import CardContainer from '../../../widgets/card/card';
import DashboardCardTitle from '../../../componant/dashboardCardTitle/dashboardCardTitle';
import DashboardCardBodyItem from '../../../componant/dashboardCardBody/dashboardCardBody';

interface ProjectProps {
    label: string,
    name: string,
    summaryDetails: any[]
}



const UserDashboard = () => {
    const navigate = useNavigate()
    const state = useSelector((state: any) => state.team)
    const [project, setProject] = useState<ProjectProps[]>([])
    const fetchApi = FetchApi()

    const role = TokenService.getRole()
    const extractToken = TokenService.extractToken()
    console.log(role, "Roleeeeeeee")

    const sortByDemoDate = (projectSummaryResponse: any) => {
        const currentDate = new Date();
        const thirtyDaysLater = new Date(currentDate.getTime() + 30 * 24 * 60 * 60 * 1000);

        // Custom sorting function
        const sortedProjects = projectSummaryResponse.summaryDetails.sort((a: any, b: any) => {
            // Function to check if projectDemoDate is within next 30 days from today
            const isWithinNext30Days = (demoDate: string): boolean => {
                if (!demoDate) return false;
                const demoDateObj = new Date(demoDate);
                return demoDateObj >= currentDate && demoDateObj <= thirtyDaysLater;
            };

            // Handle projects with Demo In Progress first
            if (a.status === "Demo In Progress" && b.status !== "Demo In Progress") {
                return -1;
            }
            if (a.status !== "Demo In Progress" && b.status === "Demo In Progress") {
                return 1;
            }

            // Both have Demo In Progress status or neither
            if (a.status === "Demo In Progress" && b.status === "Demo In Progress") {
                // Check if projectDemoDate is within next 30 days for both
                const aWithin30Days = isWithinNext30Days(a.projectDemoDate);
                const bWithin30Days = isWithinNext30Days(b.projectDemoDate);

                // Sort by projectDemoDate if both are within next 30 days
                if (aWithin30Days && bWithin30Days) {
                    return new Date(a.projectDemoDate).getTime() - new Date(b.projectDemoDate).getTime();
                }
                // Put project with projectDemoDate within next 30 days first
                if (aWithin30Days && !bWithin30Days) {
                    return -1;
                }
                if (!aWithin30Days && bWithin30Days) {
                    return 1;
                }
            }

            // Otherwise, maintain the original order
            return 0;
        });

        console.log(sortedProjects, "Podddddddddddddddd");
    }

    useEffect(() => {
        const userRole = extractToken?.roles; // Assuming the role is stored in the token

        if (userRole === Role.USER) {
            // If role is user
            Promise.all([fetchApi.getLastSevenDayTask(extractToken?.sub), fetchApi.getSingleUserTeam()])
                .then(([taskResponse, teamResponse]) => {
                    // sortByDemoDate(teamResponse)
                    const mergedSummaryDetails = [teamResponse, taskResponse];
                    setProject(mergedSummaryDetails);
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                    setError('Error fetching data');
                });
        } else {
            Promise.all([fetchApi.getLastSevenDayTask(extractToken?.sub), fetchApi.getSevenDaysProjectSummaryDetails()])
                .then(([taskResponse, projectSummaryResponse]) => {
                    // sortByDemoDate(projectSummaryResponse)
                    const mergedSummaryDetails = [projectSummaryResponse, taskResponse];

                    setProject(mergedSummaryDetails);
                })
                .catch((error) => {
                    setError('Error fetching data');
                });
        }
    }, [extractToken?.sub]);


    const snackbarService = SnackbarService()
    let [isClockActive, setClockActiveStatus] = useState(false);
    let [loggedTime, setloggedTime] = useState<any>({
        agenda: null,
        clockIn: null,
        clockInDate: null,
        clockOut: null,
        employee_id: null,
        workUpdate: null,
        workingHours: null
    });
    const [breakoutStatus, setBreakoutStatus] = useState(false)
    const [openAgenda, setOpenAgenda] = React.useState(false);
    const [openWorkupdate, setOpenWorkupdate] = React.useState(false);
    const [error, setError] = useState('')
    const [agenda, setAgenda] = useState<any>(null)
    const [Workupdate, setWorkupdate] = useState("")
    const todayDate = new Date().toJSON().slice(0, 10);
    const [time, setTimer] = useState({ sec: 0, min: 0, h: 0 })



    useEffect(() => {
        const areAllBreakOutsNull = loggedTime.breaks?.every((breakEntry: { breakOut: null; }) => breakEntry.breakOut !== null);
        if (loggedTime.clockOut === null && areAllBreakOutsNull) {
            setBreakoutStatus(false)
        } else {
            setBreakoutStatus(true)
        }

    }, [loggedTime])


    let timer: any
    const updateCount = () => {
        if (isClockActive) {
            timer = !timer && setInterval(() => {
                setTimer(prevTime => {
                    let currentTime = { ...prevTime };
                    currentTime.sec = currentTime.sec >= 59 ? 0 : currentTime.sec + 1;
                    currentTime.min = currentTime.sec === 0 ? (currentTime.min >= 59 ? 0 : currentTime.min + 1) : currentTime.min;
                    currentTime.h = (currentTime.sec === 0 && currentTime.min === 0) ? (currentTime.h >= 23 ? 0 : currentTime.h + 1) : currentTime.h;
                    return currentTime;
                });

            }, 1000)
        }
    }

    const getCurrentTime = () => {
        return new Date().toISOString();
    }

    const getStatus = () => {
        isActiveClock().then((res: any) => {
            if (res?.data.success) {
                setClockActiveStatus(res.data.payload.body.activeStatus)
                setloggedTime(res.data.payload.body)
                if (res.data.payload.body) {
                    if (res.data.payload.body.clockIn) {
                        getTimeWithBreaks(res.data.payload.body)
                    }
                }
            }
        })
    };
    const handleVisibilityChange = () => {
        if (!document.hidden) {
            getStatus()
        }
        else clearInterval(timer);
    };

    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);

    const formate = (data: any) => {
        const date = new Date(data)
        return date
    }
    const clockIN = () => {
        postClockIn(agenda, extractToken?.sub).then((res: any) => {
            if (res?.data.success) {
                snackbarService.next({ data: { open: true, severity: 'success', message: "Clock In Successfully..." } })
                getStatus()
            }
        })
    }

    const clockOUT = () => {
        postClockOut(Workupdate, extractToken?.sub).then((response: any) => {
            if (response.data.success) {
                snackbarService.next({ data: { open: true, severity: 'success', message: "Clock Out Successfully..." } })
                getStatus()
            }
        })
    }
    const clockAction = (opt: any) => {
        switch (opt) {
            case 'IN':
                if (!extractToken?.agendaRequired) {
                    clockIN()
                } else {
                    setOpenAgenda(true)
                }
                break;
            case 'OUT':
                if (!extractToken?.workUpdateRequired) {
                    clockOUT()
                } else {
                    setOpenWorkupdate(true)
                }
                break;
            case 'BREAK':
                isClockActive = !isClockActive
                if (isClockActive)
                    breakOut(extractToken?.sub).then((response: any) => {
                        if (response.data.success) {
                            getStatus()
                            setBreakoutStatus(false)
                        }
                    })

                else
                    breakIn(extractToken?.sub).then((response: any) => {
                        if (response.data.success) {
                            getStatus()
                            setBreakoutStatus(true)
                        }
                    })

                break;
        }
    }


    const getElapsedTime = (loggedTime: any, currentTime: any) => {
        const time1 = new Date(loggedTime);
        const time2 = new Date(currentTime);

        const timeDifference = new Date(time2.getTime() - time1.getTime());

        let timeInseconds = timeDifference.getUTCHours() * 60 * 60 + timeDifference.getUTCMinutes() * 60 + timeDifference.getUTCSeconds()
        return timeInseconds
    }

    const getTimeFromSeconds = (sec: number) => {
        var hours = Math.floor(sec / 3600);
        var minutes = Math.floor((sec % 3600) / 60);
        var seconds = sec % 60;
        setTimer({ sec: seconds, min: minutes, h: hours })
    }
    const getTimeWithBreaks = (res: any) => {
        let totalSeconds = getElapsedTime(res.clockIn, (res.clockOut && res.clockOut != null) ? res.clockOut : getCurrentTime())
        let elapsedTime = 0
        res.breaks.forEach((br: any) => {
            if (br.breakIn && br.breakOut && br.breakOut != null) {
                elapsedTime += getElapsedTime(br.breakIn, br.breakOut)
            }
            else if (br.breakIn && (!br.breakOut || br.breakOut == null)) {
                totalSeconds = getElapsedTime(res.clockIn, br.breakIn)
            }
        })
        getTimeFromSeconds(totalSeconds - elapsedTime)
    }


    useEffect(() => {
        updateCount()
        return () => clearInterval(timer)
    }, [isClockActive])

    useEffect(() => {
        getStatus()
    }, [])

    useEffect(() => {


    }, [])

    const ClockInWithAgenda = () => {
        if (agenda !== '' && agenda !== null) {
            setOpenAgenda(false);
            clockIN()
        } else {
            setError('*Field is required')
        }
    };

    const clockOutWithAgenda = () => {
        if (Workupdate !== '') {
            setOpenWorkupdate(false)
            clockOUT()
        } else {
            setError('*Field is required')
        }

    }

    const handleCloseAgenda = () => {
        setOpenAgenda(false);
    }



    const handleCloseWorkUpdate = () => {
        setOpenWorkupdate(false)
    }

    const handleNavigate = (actionType: string) => {
        if (actionType === 'attendance') {
            navigate('/user/attendance')
        } else {
            navigate('/user/teamManagement')
        }
    }

    console.log(project, "Projectttttttttttttttttttttttttttttttttttttttttt")

    return (
        <div className='main' >
            <div className='main-container'>
                <div className='heading heading-start-center height-40' >
                    <h2>Dashboard</h2>
                </div>
                <div className='sub-container cal-40'>
                    <div className='sub-container-wrapper'>
                        <Grid container spacing={1} >
                            <Grid item xs={12} sm={6} md={4}>
                                <div className="main-card-container" >
                                    <CardContainer title={<DashboardCardTitle label='Attendance' name='attendance' />} body={

                                        <div className='card-body'>
                                            <div className='card-sub-body'>
                                                <div className='time-count-sub-container'>
                                                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center', flexBasis: '130px' }}>
                                                        <div className='time-count-container'>
                                                            <span>{(time.h >= 10) ? time.h : "0" + time.h}</span><span>&nbsp;:&nbsp;</span>
                                                            <span>{(time.min >= 10) ? time.min : "0" + time.min}</span><span>&nbsp;:&nbsp;</span>
                                                            <span>{(time.sec >= 10) ? time.sec : "0" + time.sec}</span>
                                                        </div>
                                                        <div className="status">
                                                            <>
                                                                <div>Status: {loggedTime?.clockIn && loggedTime?.clockOut === null ? 'Clocked In' : 'Clocked Out'}</div>
                                                            </>
                                                        </div>
                                                        <div className="clock-in-out">

                                                            <div style={{ marginTop: '4px' }}>Clock In Time: {formateTimeZone(loggedTime?.clockIn)}</div>
                                                            <div>Clock Out Time: {formateTimeZone(loggedTime?.clockOut)}</div>

                                                        </div>
                                                    </div>
                                                    <div className='time-count-button-container'>
                                                        {loggedTime && loggedTime.clockIn && !loggedTime.clockOut &&
                                                            < >
                                                                {<div className='dashboard-button-class-top '>
                                                                    <ButtonWrapper title='Clock Out' className='clock-out-button button-width' variant='contained' backgroundColor='var(--dangerColor)' onClick={() => { clockAction('OUT') }} disable={breakoutStatus} />
                                                                </div>
                                                                }
                                                                <Dialog
                                                                    open={openWorkupdate}
                                                                    onClose={handleCloseWorkUpdate}
                                                                    aria-labelledby="alert-dialog-title"
                                                                    aria-describedby="alert-dialog-description"
                                                                >
                                                                    <DialogTitle id="alert-dialog-title">
                                                                        {"Please write your workupdate here!"}
                                                                    </DialogTitle>
                                                                    <DialogContent>
                                                                        <Box>
                                                                            <TextField fullWidth
                                                                                id="standard-textarea"
                                                                                label={`My Workupdate ${todayDate}`}
                                                                                placeholder="Placeholder"
                                                                                multiline
                                                                                variant="standard"
                                                                                onChange={(e) => {
                                                                                    setWorkupdate(e.target.value)
                                                                                }} />

                                                                        </Box>
                                                                        {
                                                                            Workupdate === '' ? <span className='error-field'>{error}</span> : ''
                                                                        }
                                                                    </DialogContent>
                                                                    <DialogActions>
                                                                        <Button onClick={handleCloseWorkUpdate}>Disagree</Button>
                                                                        <Button onClick={clockOutWithAgenda} autoFocus>
                                                                            Agree
                                                                        </Button>
                                                                    </DialogActions>
                                                                </Dialog>
                                                            </>
                                                        }
                                                        {loggedTime && !loggedTime.clockIn && !loggedTime.clockOut && <div className="dashboard-button-class-top">
                                                            <ButtonWrapper title='Clock In' className='clock-in-button' variant='contained' onClick={() => clockAction('IN')} backgroundColor='var(--primaryColor)' />
                                                            <Dialog
                                                                open={openAgenda}
                                                                onClose={handleCloseAgenda}
                                                                aria-labelledby="alert-dialog-title"
                                                                aria-describedby="alert-dialog-description"
                                                            >
                                                                <DialogTitle id="alert-dialog-title">
                                                                    {"Please write your agenda here!"}
                                                                </DialogTitle>
                                                                <DialogContent>
                                                                    <Box>

                                                                        <TextField fullWidth
                                                                            id="standard-textarea"
                                                                            label={`My Agenda ${todayDate}`}
                                                                            placeholder="Placeholder"
                                                                            multiline
                                                                            variant="standard"
                                                                            onChange={(e) => {
                                                                                setAgenda(e.target.value)
                                                                            }} />

                                                                    </Box>
                                                                    {
                                                                        agenda === '' ? <span className='error-field'>{error}</span> : ''
                                                                    }
                                                                </DialogContent>
                                                                <DialogActions>
                                                                    <Button onClick={handleCloseAgenda}>Disagree</Button>
                                                                    <Button onClick={ClockInWithAgenda} autoFocus>
                                                                        Agree
                                                                    </Button>
                                                                </DialogActions>
                                                            </Dialog>
                                                        </div>
                                                        }
                                                        {loggedTime && loggedTime && loggedTime.clockIn && !loggedTime.clockOut
                                                            && <div className='dashboard-button-class-top'>
                                                                <ButtonWrapper title={loggedTime.activeStatus ? 'BREAK' : 'RESUME'} className='break-button' variant='contained' onClick={() => clockAction('BREAK')} backgroundColor='var(--primaryColor)' />
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    } />


                                </div>
                            </Grid>

                            {
                                project.map((element: ProjectProps, index: number) => {
                                    return <Grid item xs={12} sm={6} md={4}>
                                        <div className='main-card-container'>
                                            <CardContainer title={<DashboardCardTitle label={element.label} name={element.name} />} body={
                                                <div className='card-body'>
                                                    <div className='card-sub-body'>
                                                        {
                                                            element.summaryDetails && element.summaryDetails.length > 0 ? (
                                                                element.summaryDetails.map((ele: any, ind: number) => {
                                                                    return <DashboardCardBodyItem element={element} subElement={ele} key={ind} />
                                                                })) : null
                                                        }
                                                    </div>
                                                </div>
                                            } />
                                        </div>
                                    </Grid>
                                })
                            }
                        </Grid>
                    </div>
                </div>
            </div >
        </div >
    );
};

export default UserDashboard;


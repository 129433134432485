import React, { useState } from 'react'
import './addFeedback.css'
import CloseIcon from '@mui/icons-material/Close';
import Rating from '@mui/material/Rating';
import { FormControl, TextareaAutosize } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import ButtonWrapper from '../../widgets/button/button';

interface AddFeedbackProps {
    onClose: () => void
}

interface FeedbackValueProps {
    interviewerFeedback: string,
    rating: string
}

const AddFeedback = (props: AddFeedbackProps) => {
    const { control, handleSubmit, register, setValue } = useForm();
    const [feedbackValue, setFeedbackValue] = useState<FeedbackValueProps>({
        interviewerFeedback: '',
        rating: ''
    })
    const handleField = (e: any) => {
        const { name, value } = e.target
        setFeedbackValue((preav: any) => {
            return { ...preav, [name]: value }
        })
        setValue(name, value)


    }


    const onSubmit = (data: any) => {
        console.log(data, "Dataaaaaaaaaaaaaaaaa")
    }

    return (
        <div className='main'>
            <div className='modal-heading'>
                <h4>Feedback</h4>
                <CloseIcon className='modal-close-button' onClick={() => props.onClose()} />
            </div>
            <div className='modal-body'>
                <form onSubmit={handleSubmit(onSubmit)} style={{ height: 'fit-content' }}>
                    <div className='container-100'>
                        <div className='rating-container'>
                            <Controller
                                name="rating"
                                control={control}
                                rules={{ required: '*Rating is required' }} // Make the rating required
                                render={({ field, fieldState: { error } }) => (
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <Rating
                                            {...field}
                                            name="rating"
                                            precision={0.5}
                                            value={Number(feedbackValue.rating)} // Ensure the rating is controlled
                                            onChange={handleField}
                                            aria-required
                                        />
                                        {error && <div className='error-container'>{error.message}</div>} {/* Show error if required */}
                                    </div>
                                )}
                            />
                        </div>
                        <FormControl sx={{ minHeight: '20px' }} fullWidth>
                            <Controller
                                name='interviewerFeedback'
                                control={control}
                                render={({ field }) => (
                                    <TextareaAutosize
                                        {...field}
                                        // value={taskDetails}
                                        aria-label="empty textarea"
                                        minRows={5}
                                        maxRows={5}
                                        maxLength={200}
                                        value={feedbackValue.interviewerFeedback}
                                        style={{ padding: '4px', paddingLeft: '12px' }}
                                        placeholder='Feedback'
                                        onChange={handleField}
                                        required
                                    />
                                )}
                            />
                        </FormControl>

                        <div className='leave-application-button-container'>
                            <ButtonWrapper title='submit' id='submit-button' variant='contained' type='submit' />
                        </div>
                    </div>
                </form>

            </div>
        </div>
    )
}

export default AddFeedback
import React from 'react'
import { CardBody, Item } from '../../../model/card-model/cardBody.model'

interface CardItemContainerProps {
    item: string | React.ReactNode
}

const CardItemContainer = (props: CardItemContainerProps) => {
    return (
        <>{props.item}</>
    )
}

export default CardItemContainer

import React, { useEffect, useRef, useState } from 'react'
import { MuiFileInput } from 'mui-file-input';
import { Button } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import styled from '@emotion/styled';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
});


interface FileUploaderProps {
    label: string,
    required: boolean,
    onFileUpload: (file: File) => void,
    file?: string
}

const FileUploader = (props: FileUploaderProps) => {
    const [fileName, setFileName] = useState<string>('')



    useEffect(() => {
        if (props.file) {
            setFileName(props.file)
        }
    }, [props.file])

    const handleFileChange = (event: any) => {
        const file = event.target.files?.[0];
        const fileName = file.name
        if (file) {
            setFileName(fileName)

            props.onFileUpload(file)
        }
    };


    return (

        <>
            <Button
                component="label"
                role={undefined}
                variant="contained"
                tabIndex={-1}
                startIcon={<CloudUploadIcon />}
            >
                {
                    !fileName ? 'Upload Resume' : fileName
                }

                <VisuallyHiddenInput type="file" onChange={handleFileChange} required={props.required && fileName === ''} />
            </Button>
        </>
    )
}

export default FileUploader
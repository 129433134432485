/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react'
import "./admin.css"
import { Outlet } from 'react-router';
import { Stack } from '@mui/material';
import FetchApi from '../../fetch-api/fetch-api';
import SideBar from '../../componant/layout/sideBar';

const AdminLayout = () => {
    return (
        <>
            <SideBar>
                <Stack style={{ height: "90vh" }}>
                    <Outlet ></Outlet >
                </Stack>
            </SideBar>
        </>
    )
}

export default AdminLayout
import React from 'react'
import { Role } from "../model/token-model/role.model";
import { TokenService } from '../services/token.service';

interface AuthGuardProps {
    element: any,
    role: Role
}



export const AuthGuard = ({ element, role }: AuthGuardProps) => {
    if (!TokenService.isValidRole(role)) {
        goToDefaultRoute()
        return false
    }
    return element
}

export const goToDefaultRoute = () => {
    TokenService.clearToken();
    window.location.href = '/'
}


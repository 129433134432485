const workingTime: number = 8;
export function calculateOvertime(attendanceDetails: any[]) {
    console.log(attendanceDetails, "Attendance details")
    return attendanceDetails.map(attendance => {
        const overtimeHours = calculateOvertimeForAttendance(attendance);
        return {
            ...attendance,
            overtimeHours: overtimeHours
        };
    });
}

function calculateOvertimeForAttendance(attendance: { workingHours: string; }) {
    if (attendance.workingHours) {
        // Split the working hours into hours, minutes, and seconds
        const workingHoursParts = attendance.workingHours.split(':');
        const hours = parseInt(workingHoursParts[0]);
        const minutes = parseInt(workingHoursParts[1]);
        const seconds = parseInt(workingHoursParts[2]);

        // Calculate total seconds worked
        const totalSecondsWorked = hours * 3600 + minutes * 60 + seconds;

        // Calculate total overtime seconds
        const overtimeSeconds = totalSecondsWorked - (workingTime * 3600);

        if (overtimeSeconds > 0) {
            // Convert overtime seconds to hours, minutes, and seconds
            const overtimeHours = Math.floor(overtimeSeconds / 3600);
            const overtimeMinutes = Math.floor((overtimeSeconds % 3600) / 60);
            const remainingSeconds = overtimeSeconds % 60;

            // Return the formatted overtime hours, minutes, and seconds
            return `${overtimeHours.toString().padStart(2, '0')}:${overtimeMinutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
        } else {
            // If there's no positive overtime, return zero
            return '00:00:00';
        }
    }

    return null;
}


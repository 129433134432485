import React, { useEffect, useRef, useState } from 'react'
import './leave-application.css'
import CloseIcon from '@mui/icons-material/Close';
import { Controller, useForm } from 'react-hook-form';
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField, TextareaAutosize } from '@mui/material';
import { userleaveDetails } from '../../services/constant'
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { Dispatch } from 'redux';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import { DateValidationError } from '@mui/x-date-pickers/models';
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { postLeaveApply, uploadLeaveAttachment, editEmployeeDetails, getNoOfLeaveDay } from '../../api-services/employee-services';
import jwt from 'jwt-decode';
import { JwtModel } from '../../model/token-model/token.model';
import dayjs from 'dayjs';
import { SnackbarService } from '../../services/snackBehaviorSubject/snackBehaviorSubject';
import { AiFillCloseCircle } from 'react-icons/ai';
import ButtonWrapper from '../../widgets/button/button';
import { TokenService } from '../../services/token.service';




interface leaveDetails {
    leaveType: string,
}

interface fieldValueProps {
    leaveType: string,
    session: string,
    fromDate: string,
    toDate: string,
    noOfDays: number,
    attachment: null,
    name: string,
    reasonForLeave: string,
    leavePeriod: string,
}
interface LeaveApplicationProps {
    onClose: () => void
}

const LeaveApplication = (props: LeaveApplicationProps) => {
    const state = useSelector((state: any) => state.configuration)
    const userLeave = useSelector((state: any) => state.userLeaveDetails)
    const snackbarService = SnackbarService()
    const [leveDetails, setLeaveDetails] = useState<leaveDetails[]>([])
    const { control, handleSubmit, setValue, register } = useForm();
    const extractToken = TokenService.extractToken()
    const leaveDetails = userleaveDetails();
    console.log(leaveDetails)
    console.log(state, "State")

    const [file, setFile] = useState<File>()

    const [fieldValue, setFieldValue] = useState<fieldValueProps>({
        leaveType: "",
        session: "",
        fromDate: "",
        toDate: "",
        name: '',
        noOfDays: 0,
        leavePeriod: "",
        attachment: null,
        reasonForLeave: ""
    })
    const today = dayjs()
    const maxDayOfStartDay = dayjs(fieldValue.toDate)
    const minDateOfToDate = dayjs(fieldValue.fromDate)

    useEffect(() => {

        const matchingLeaves = leaveDetails.filter(getItem =>
            state.leave_config.some((item: any) => item.id === getItem.id)
        );

        console.log(matchingLeaves);
        setLeaveDetails(matchingLeaves)
    }, [])

    useEffect(() => {
        if (fieldValue.fromDate !== "" && fieldValue.toDate !== "") {
            const start = new Date(fieldValue.fromDate);
            const end = new Date(fieldValue.toDate);
            start.setHours(0, 0, 0, 0);
            end.setHours(0, 0, 0, 0);
            getNoOfLeaveDay(fieldValue.fromDate, fieldValue.toDate).then((response: any) => {
                if (response?.data) {
                    setValue("noOfDays", response.data)
                    setFieldValue((d: any) => {
                        return { ...d, ["noOfDays"]: response.data }
                    })
                }
            })
        }
    }, [fieldValue.fromDate, fieldValue.toDate])

    useEffect(() => {
        if (fieldValue.fromDate !== "" && fieldValue.session === "Half Day") {
            const timeDifference = 1 - 0.5;
            let convertNumToStr = timeDifference.toString()
            setValue("noOfDays", convertNumToStr)
            setFieldValue((d: any) => {
                return { ...d, ["noOfDays"]: convertNumToStr }
            })
        } else {

            setValue("noOfDays", "")
            setFieldValue((d: any) => {
                return { ...d, ["noOfDays"]: "" }
            })
        }
    }, [fieldValue.fromDate, fieldValue.session, setValue])


    console.log(file)
    const onSubmit = (data: any) => {
        const randomId = Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;
        data["name"] = extractToken?.name
        data['leaveId'] = randomId
        console.log(file)
        console.log(data)
        if (data.toDate !== 'DD/MM/YYYY' && data.fromDate !== 'DD/MM/YYYY') {
            console.log(data)
            postLeaveApply(data, extractToken?.sub).then((res: any) => {
                if (res?.data.success) {
                    if (fieldValue.leaveType === 'Medical' && file) {
                        uploadLeaveAttachment(randomId, file).then((response: any) => {
                            if (response.data.success) {
                                snackbarService.next({ data: { open: true, severity: 'success', message: res.data.message } })
                            }
                        })
                    }
                    snackbarService.next({ data: { open: true, severity: 'success', message: res.data.message } })
                }

            }
            ).catch()

            setFieldValue({
                leaveType: "",
                session: "",
                fromDate: "",
                toDate: "",
                name: '',
                noOfDays: 0,
                leavePeriod: "",
                attachment: null,
                reasonForLeave: ""
            })
            setValue('session', '')
            setValue('fromDate', '')
            setValue('id', '')
            setValue('noOfDays', '')
            setValue('attachment', '')
            setValue('reasonForLeave', '')
        } else {
            snackbarService.next({ data: { open: true, severity: 'error', message: 'Please select leave application date' } })
        }


    }

    function formatDateToCustomFormat(inputDate: any) {
        const date = new Date(inputDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        const customFormat = `${year}-${month}-${day}`; return customFormat;
    }

    const handleField = (e: any, field: any, type?: string) => {
        if (type === "date") {
            var value: String = formatDateToCustomFormat(e.$d.toLocaleDateString("en-US")).toString()
        } else if (type === "file") {
            const file = e.target.files[0]
            setFile(file)
            var value: String = file.name
        } else {
            var value: String = e.target.value
        }

        if (field === 'leaveType') {
            const leaveType = e.target.value
            const leaveBalance = userLeave.leave.find((item: any) => item.leaveType === leaveType).leaveBreaks.balance
            if (leaveBalance === 0) {
                snackbarService.next({ data: { open: true, severity: 'warning', message: `Your ${leaveType} leave balance is ${leaveBalance}` } })
            }
        }

        if (value !== "DD-MM-YYYY" || value !== "") {



            setFieldValue((d: any) => {
                return { ...d, [field]: value }
            })
            setValue(field, value)


        } else {
        }
    }
    const setDateValue = (field: any, value: any) => {

    }
    useEffect(() => {
        const startDate = new Date(fieldValue.fromDate)
        const endDate = new Date(fieldValue.toDate)
        console.log(startDate, "Satrtr date", endDate, 'End Date')
    }, [fieldValue.fromDate, fieldValue.toDate])

    const handleFieldFile = (e: any, field: any, type: String) => {
        const file = e.target.files[0]
        var value: String = file.name
        setFieldValue((d: any) => {
            return { ...d, [field]: value }
        })



    }


    return (
        <>
            <div className='main'>

                <form onSubmit={handleSubmit(onSubmit)} className='leave-application-form-container'>

                    <div className='modal-heading'>
                        <h4>Apply Leave</h4>
                        <CloseIcon
                            className='modal-close-button'
                            onClick={props.onClose}
                        />
                    </div>
                    <div className='modal-body'>
                        <div className='conatiner-100'>
                            <div className='leave-application-field-container'>

                                <FormControl required sx={{ minWidth: 120 }} fullWidth>
                                    <InputLabel id="demo-simple-select-required-label">Leave Type</InputLabel>
                                    <Controller
                                        name="leaveType"
                                        control={control}
                                        render={({ field }) => (
                                            <Select
                                                name="leaveType"
                                                labelId="leave-type-label"
                                                id="leave-type"
                                                label="Leave Type *"
                                                defaultValue=""
                                                onChange={(e) => handleField(e, "leaveType")}
                                                value={fieldValue.leaveType}
                                            >
                                                {leveDetails.map((item: any, index: number) => (
                                                    <MenuItem key={index} value={item.leaveType}>
                                                        {item.leaveType}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                                <FormControl required sx={{ mt: 1, minWidth: 120 }} fullWidth>
                                    <InputLabel id="demo-simple-select-required-label">Session</InputLabel>
                                    <Controller
                                        name="session"
                                        control={control}
                                        render={({ field }) => (
                                            <>
                                                <Select
                                                    name='session'
                                                    labelId="session-label"
                                                    id="session"
                                                    label="session *"
                                                    onChange={(e) => handleField(e, "session")}
                                                    value={fieldValue.session}
                                                >

                                                    <MenuItem value="Full Day">Full Day </MenuItem>
                                                    {fieldValue.leaveType !== 'Vacation' ? < MenuItem value="Half Day">Half Day </MenuItem> : ''}
                                                </Select>

                                            </>
                                        )}
                                    />
                                </FormControl>

                                {fieldValue.session === "Half Day" && <>
                                    <FormControl required sx={{ mt: 1, minWidth: 120 }} fullWidth>
                                        <Grid container >
                                            <Grid item xs={12}>
                                                <FormControl required fullWidth >
                                                    <InputLabel id="demo-simple-select-required-label">Half Schedule</InputLabel>
                                                    <Controller
                                                        name="Working_Period"
                                                        control={control}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                labelId="leavePeriod"
                                                                id="leavePeriod"
                                                                label="Working Period *"
                                                                onChange={(e) => handleField(e, "leavePeriod")}
                                                                value={fieldValue.leavePeriod}
                                                                fullWidth
                                                            >
                                                                <MenuItem value="First Half">First Half</MenuItem>
                                                                <MenuItem value="Second Half">Second Half</MenuItem>
                                                            </Select>
                                                        )}
                                                    />
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormControl required fullWidth >
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DemoContainer components={['DatePicker']}>
                                                            <DatePicker
                                                                {...register("fromDate", {
                                                                    required: 'error message'
                                                                })}
                                                                minDate={today}
                                                                label="Date"
                                                                format='DD/MM/YYYY'
                                                                onChange={(e) => handleField(e, "fromDate", "date")}
                                                                value={dayjs(fieldValue.fromDate)}

                                                            />
                                                        </DemoContainer>
                                                    </LocalizationProvider>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </FormControl>
                                </>}
                                {fieldValue.session === "Full Day" && <>
                                    <div className='leave-application-date-range-container'>
                                        <FormControl required fullWidth >
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer
                                                    components={['DatePicker']}
                                                >
                                                    <DatePicker
                                                        {...register("fromDate", {
                                                            required: 'error message'
                                                        })}
                                                        label='Form Date'
                                                        minDate={today}
                                                        maxDate={maxDayOfStartDay}
                                                        value={dayjs(fieldValue.fromDate)}
                                                        format='DD/MM/YYYY'
                                                        onChange={(e) => handleField(e, 'fromDate', 'date')}
                                                    />
                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </FormControl>
                                        <FormControl required fullWidth >
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DemoContainer
                                                    components={['DatePicker']}
                                                >
                                                    <DatePicker
                                                        {...register("toDate", {
                                                            required: 'error message'
                                                        })}
                                                        label='To Date'
                                                        disabled={fieldValue.fromDate !== '' ? false : true}
                                                        format='DD/MM/YYYY'
                                                        value={dayjs(fieldValue.toDate)}
                                                        minDate={minDateOfToDate}
                                                        onChange={(e) => handleField(e, 'toDate', 'date')}
                                                    />

                                                </DemoContainer>
                                            </LocalizationProvider>
                                        </FormControl>
                                    </div>

                                </>}
                                {(fieldValue.session === "Half Day" || fieldValue.session === "Full Day") && <FormControl required sx={{ mt: 1, minWidth: 120 }} fullWidth>

                                    <Controller
                                        name="noOfDays"
                                        control={control}
                                        render={({ field }) => (

                                            <TextField
                                                {...field}
                                                id="outlined-basic"
                                                variant="outlined"
                                                label="No of Days"
                                                disabled={true}
                                                value={fieldValue.noOfDays}
                                            />

                                        )}
                                    />
                                </FormControl>
                                }
                                {
                                    fieldValue.leaveType === 'Medical' && fieldValue.noOfDays > 2 && <>
                                        <FormControl required sx={{ mt: 1, minWidth: 120, border: "1px solid #C4C4C4", borderRadius: "4px" }} fullWidth>
                                            <InputLabel id="demo-simple-select-required-label">Attachment</InputLabel>
                                            <Controller
                                                name="attachment"
                                                control={control}
                                                render={({ field }) => (
                                                    <div className='fileuplod-field-main-container'>
                                                        <TextField
                                                            {...field}
                                                            id="outlined-basic"
                                                            variant="outlined"
                                                            type="file"
                                                            sx={{ opacity: 0, position: "absolute", width: "100%", height: "100%", top: "0px", left: "0px" }}
                                                            onChange={(e) => handleField(e, "attachment", "file")}
                                                            value={""}
                                                            required={file == null}
                                                        />
                                                        <span style={{ marginLeft: "65%", display: "flex" }}>
                                                            {
                                                                fieldValue.attachment !== "" ? <div className='fileuplod-file-label-container'>{fieldValue.attachment}</div> : ""
                                                            }
                                                            <AttachFileIcon
                                                                sx={{ marginTop: "15px", marginLeft: "auto", }}
                                                            />
                                                        </span>

                                                    </div>
                                                )}
                                            />
                                        </FormControl>
                                    </>}
                                <FormControl required sx={{ mt: 1, minWidth: 120, minHeight: '20px' }} fullWidth>
                                    <Controller
                                        name="reasonForLeave"
                                        control={control}
                                        render={({ field }) => (
                                            <TextareaAutosize
                                                {...field}
                                                {...register("reasonForLeave", {
                                                    required: 'error message'
                                                })}
                                                value={fieldValue.reasonForLeave}
                                                aria-label="empty textarea"
                                                minRows={5}
                                                maxRows={5}
                                                maxLength={200}
                                                style={{ padding: '4px', paddingLeft: '12px' }}
                                                placeholder="Reason*(200 Character Max)"
                                                onChange={(e) => handleField(e, "reasonForLeave")}
                                            />
                                        )}
                                    />
                                </FormControl>

                            </div >
                            <div className='leave-application-button-container'>
                                <ButtonWrapper title='submit' id='submit-button' variant='contained' type='submit' />
                            </div>
                        </div>
                    </div>
                </form >


            </div >
        </>
    )
}

export default LeaveApplication
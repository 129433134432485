import React from 'react'

interface CardFooterProps {
    item: string | React.ReactNode
}

const CardFooter = (props: CardFooterProps) => {
    return (
        <>
            {props.item}
        </>
    )
}

export default CardFooter
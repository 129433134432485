import React, { useState } from 'react'
import './createHoliday.css'
import CloseIcon from '@mui/icons-material/Close';
import { detailsField } from '../../services/employeeFieldType';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@mui/material';
import { SnackbarService } from '../../services/snackBehaviorSubject/snackBehaviorSubject';
import { createHoliday } from '../../api-services/employee-services';
import { setHoliday } from '../../redux/actions/actions';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';
import FetchApi from '../../fetch-api/fetch-api';

interface CreateHolidayProps {
    onClose: () => void,
    handleAllHoliday?: (params: boolean) => void
}
interface CreateFieldProps {
    date: string,
    reason: string;
}

const CreateHoliday = (props: CreateHolidayProps) => {
    const field = detailsField()
    const fetchApi = FetchApi()
    const snackbarService = SnackbarService()
    const dispatch: Dispatch = useDispatch()
    const [fieldValue, setFieldValue] = useState<CreateFieldProps>({
        date: "",
        reason: ""
    })

    const { control, handleSubmit, register } = useForm();

    const handleHolidayField = (e: any) => {
        const { name, value } = e.target

        setFieldValue((preav: any) => {
            return { ...preav, [name]: value }
        })

    }
    const onSubmit = (data: any) => {
        createHoliday(fieldValue).then((response: any) => {
            console.log(response, "Response")
            if (response?.data.success) {
                snackbarService.next({ data: { open: true, severity: 'success', message: response.data.message } })
                fetchApi.fetchHoliday()
            } else {
                snackbarService.next({ data: { open: true, severity: 'error', message: response.data.message } })
            }
        }).catch((err) => {

        });

        dispatch(setHoliday(data))
        fieldValue.date = ''
        fieldValue.reason = ''


    }
    return (
        <>
            <div className='main'>
                <div className='modal-heading'>
                    <h4>Create Holiday</h4>
                    <CloseIcon className='modal-close-button' onClick={() => props.onClose()} />
                </div>
                <div className='modal-body'>
                    <form className='create-holiday-form-container' onSubmit={handleSubmit(onSubmit)}>
                        <div className='create-holiday-sub-form-container'>
                            {
                                field.createHoliday.map((element: any, index: number) => {
                                    return <>
                                        <label className='label'>{element.label}</label>
                                        <Controller
                                            name={element.name}
                                            control={control}
                                            render={({ field }) => (

                                                <>
                                                    <input
                                                        type={element.type}
                                                        {...field}
                                                        disabled={element.name === 'noOfDays' ? true : false}
                                                        onChange={handleHolidayField}
                                                        value={fieldValue[element.name as keyof CreateFieldProps]}
                                                        required
                                                    />

                                                </>
                                            )}
                                        />
                                    </>
                                })
                            }
                        </div>
                        <div className='create-wfh-button-container'>
                            <Button
                                id="submit-btn"
                                variant='contained'
                                type="submit"
                            >  Submit</Button>
                        </div>
                    </form>
                </div>

            </div>
        </>
    )
}

export default CreateHoliday
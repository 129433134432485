import React from 'react'
import './viewTaskReason.css'
import CloseIcon from '@mui/icons-material/Close';

interface ViewTaskReasonProps {
    reason: any
    onClose: () => void
    title: string
}

const ViewTaskReason = (props: ViewTaskReasonProps) => {

    return (
        <div className='main-modal-container'>
            <div className="modal-heading">
                <h4>{props.title}</h4>
                <CloseIcon
                    className='modal-close-button'
                    onClick={props.onClose}
                />
            </div>
            <div className="modal-body">
                {/* <div style={{ border: '1px solid red', height: '100%' }}> */}
                {props.reason}
                {/* </div> */}
            </div>

        </div>

    )
}

export default ViewTaskReason
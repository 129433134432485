import React from 'react'
import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Tooltip } from '@mui/material';
import { height, margin } from '@mui/system';

const LightTooltip = styled(({ className, placement = 'top', ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} placement={placement} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        border: '1px solid rgb(39,112,222)',
        borderRadious: "2px",
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        // boxShadow: theme.shadows[1],
        fontSize: 12,
    },
}));
export default LightTooltip
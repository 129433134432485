import React from 'react'
import './dashboardCardTitle.css'
import { CgAnchor, CgDetailsMore, CgDisc } from 'react-icons/cg';
import { useNavigate } from 'react-router';
import { TokenService } from '../../services/token.service';
import { Role } from '../../model/token-model/role.model';
import { Path } from '../../util_service/path/path';
import { RoutePathModel } from '../../model/route-path-model/route.path.model';


interface DashboardCardTitleProps {
    label: string;
    name: string;
}

const DashboardCardTitle = (props: DashboardCardTitleProps) => {
    const navigate = useNavigate()
    const role: Role | null = TokenService.getRole()
    let path = '';
    console.log(role, "Role")
    let route_path: RoutePathModel[] = []

    const onNavigate = (params: string) => {
        console.log(params, "Params")

        if (role === Role.ADMIN) {
            route_path = Path().admin
        } else {
            route_path = Path().user
        }

        console.log(route_path, "PAth")

        route_path.map((element: any) => {
            if (element.name === params) {
                path = element._path
            }
        })
        navigate(path);
    }
    return (
        <div className='card-title'>

            <p className='single-line-text'>{props.label}</p>
            <div>
                {['pendingLeaveForApproval', 'team', 'project', 'workFromHome', 'attendance', 'taskList'].includes(props.name) ? <CgDetailsMore onClick={() => onNavigate(props.name)} className='cursor-pointer' /> : ''}
            </div>
        </div>
    )
}

export default DashboardCardTitle

import React from 'react'
import { CardTitle } from '../../../model/card-model/cardTitle.model'
import './cardTitle.css'

interface CardTitleContainerProps {
    title: string | React.ReactNode
}

const CardTitleContainer = (props: CardTitleContainerProps) => {
    return (

        <>
            {props.title}
        </>
    )
}

export default CardTitleContainer
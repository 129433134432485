import { useDispatch } from "react-redux"
import { deleteTeam, getAllEmployee, getAllLeaveOfEmployee, getHoliday, getLastSeveDaysReaminingTask, getProjectSummaryDetails, getSingleEmployeeTeamDetails, getSingleUserTask, getTeamDetails, getUserTeam } from "../api-services/employee-services"
import { setAdminTeam, setEmployee, setHoliday, setUserLeaveReview, setUserTask, setUserTeam } from "../redux/actions/actions"
const FetchApi = () => {
  const dispatch = useDispatch()
  const fetchAllEmployee = () => {
    getAllEmployee().then((response: any) => {
      if (response?.data.success) {
        dispatch(setEmployee(response.data.payload))
      }
    })
  }


  const fetchHoliday = () => {
    getHoliday().then((response: any) => {
      if (response?.data) {
        const data = response.data
        data.forEach((element: any, index: number) => {
          element['sno'] = index + 1
        });
        dispatch(setHoliday(response.data))
      }
    })
  }

  const fetchAdminTeam = () => {
    getTeamDetails().then((response: any) => {
      if (response?.data.success) {
        const data = response.data.payload
        data.forEach((element: any, index: number) => {
          element['sno'] = index + 1
        });
        dispatch(setAdminTeam(response.data.payload))
      } else {
        dispatch(setAdminTeam([]))
      }
    })
  }

  const fetchUserTeam = () => {
    getSingleEmployeeTeamDetails().then((response: any) => {
      console.log(response)
      if (response?.data.success) {
        const data = response.data.payload
        data.forEach((element: any, index: number) => {
          element['sno'] = index + 1
        });
        dispatch(setUserTeam(response.data.payload))
      }
    })
  }

  const deleteTeams = (id: any) => {
    deleteTeam(id).then((response: any) => {

    })
  }


  const getUserTask = (username: string) => {
    getSingleUserTask(username).then((response: any) => {
      const data = response.data.payload
      data.forEach((element: any, index: number) => {
        element.uniqueId = index + 1;
      });
      dispatch(setUserTask(response.data.payload))
    })
  }


  const getLastSevenDayTask = async (empId: any) => {
    try {
      const response: any = await getLastSeveDaysReaminingTask(empId);
      const task = {
        label: 'Task',
        name: 'taskList',
        summaryDetails: response.data.payload.map((item: { completionTargets: { completionDate: string | number | Date }[]; description: any }) => {
          const latestCompletionTarget = item.completionTargets.sort((a: { completionDate: string | number | Date }, b: { completionDate: string | number | Date }) =>
            new Date(b.completionDate).getTime() - new Date(a.completionDate).getTime()
          )[0];
          return {
            name: item.description || "N/A",
            status: latestCompletionTarget ? latestCompletionTarget.completionDate : "N/A"
          };
        })
      };
      return task;
    } catch (error) {
      console.error('Error fetching task data:', error);
      throw error; // Re-throw the error if you want to handle it elsewhere
    }

  }

  const getSingleUserTeam = async () => {
    const response: any = await getUserTeam();
    return response.data.payload
  }

  const getSevenDaysProjectSummaryDetails = async () => {
    const response: any = await getProjectSummaryDetails();
    console.log(response, "Responseeeeeeeeeeeeeeee")
    return response.data.payload
  }


  const getSingleUserLeave = async (empId: any) => {
    getAllLeaveOfEmployee(empId).then((response: any) => {
      if (response?.data.success) {
        dispatch(setUserLeaveReview(response.data.payload))
      }
    })
  }

  return { fetchAllEmployee, fetchHoliday, fetchAdminTeam, deleteTeams, fetchUserTeam, getUserTask, getLastSevenDayTask, getSingleUserTeam, getSingleUserLeave, getSevenDaysProjectSummaryDetails };
}
export default FetchApi;
import React, { useEffect, useState } from 'react'
import './lopAttendanceSummary.css'
import { getEmpAttendanceSummaryWithLop } from '../../../../api-services/employee-services'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { detailsField } from '../../../../services/employeeFieldType';
import AgGridContainer from '../../../../componant/gridContainer/gridContainer';
import { formateHifDate, formateHifDates } from '../../../../util_service/dateFormater/dateFormater';
import { searchByYearMonth } from '../../../../util_service/searchByYearMonth/searchByYearMonth';
import { salarySlipYear } from '../../../../util_service/generateYear/generateYear';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Fade, Menu } from '@mui/material';

interface SelectedYearMonth {
    month: string,
    year: string,
    monthName: string
}

const UserLopAttendanceSummary = () => {
    const [rowData, setRowData] = useState<any[]>([])
    const [filterRowData, setFilterRowData] = useState<any[]>([])
    const detailsYearField = detailsField()
    const [selectedYearMonth, setSelectedYearMonth] = useState<SelectedYearMonth>({
        month: '',
        year: '',
        monthName: ''
    })
    const formatDate = (dateString: string) => {
        const [year, month, day] = dateString.split('T')[0].split('-');
        return `${day}-${month}-${year}`;
    };
    const fetchData = () => {
        getEmpAttendanceSummaryWithLop().then((response: any) => {
            if (response.data.success) {
                response.data.payload.lopDetails.forEach((item: any) => {
                    item.date = formatDate(item.date);
                })
                setRowData(response.data.payload.lopDetails)
            } else {
                setRowData([])
            }
        }).catch((err) => {

        });
    }

    useEffect(() => {
        const currentDate = new Date()
        const year = currentDate.getFullYear()
        const month = currentDate.getMonth() + 1
        setSelectedYearMonth((preav: any) => {
            return { ...preav, month: `${month}`, year: `${year}`, monthName: getMonthName(month) }
        })
    }, [])

    useEffect(() => {
        if (rowData.length > 0 && selectedYearMonth.year !== '' && selectedYearMonth.month !== '') {
            const filterData = searchByYearMonth(rowData, selectedYearMonth.year, selectedYearMonth.month).sort((a: { date: string; }, b: { date: string; }) => {
                const dateA = new Date(a.date.split('-').reverse().join('-')).getTime();
                const dateB = new Date(b.date.split('-').reverse().join('-')).getTime();
                return dateB - dateA;
            });
            setFilterRowData(filterData)
        }
    }, [rowData, selectedYearMonth])

    const getMonthName = (monthNumber: any) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);
        return date.toLocaleString('en-US', { month: 'long' });
    }

    const onMenuChange = (e: SelectChangeEvent) => {
        const { name, value } = e.target
        setSelectedYearMonth((preav: any) => {
            return { ...preav, [name]: value.toString(), monthName: getMonthName(name === "month" ? value : selectedYearMonth.month) }
        })
    }

    useEffect(() => {
        fetchData()
    }, [])

    const [columnDefs]: any[] = useState([
        // {
        //     headerName: "S.No",
        //     field: "sno",
        //     minWidth: 100,
        //     flex: 1,
        //     resizable: true,
        //     filter: true,
        // },
        {
            headerName: "Date",
            field: "date",
            width: 130,
            minWidth: 110,
            flex: 1,
            resizable: true,
            filter: true,
            pinned: 'left',
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            lockPinned: true,
            cellRenderer: (params: any) => {
                const formattedValue = formateHifDates(params.value);
                return formattedValue;
            },
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Present",
            field: "present",
            minWidth: 130,
            flex: 1,
            filter: true,
            lockPinned: true,
        },
        {
            headerName: "Leave",
            field: "leave",
            minWidth: 130,
            flex: 1,
            filter: true,
            lockPinned: true,
        },
        {
            headerName: "Loss of Pay",
            field: "lossOfPay",
            minWidth: 130,
            flex: 1,
            filter: true,
            lockPinned: true,
        }
    ])

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openFilterModal = Boolean(anchorEl);

    const handleOpenFilter = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [showFilter, setFilter] = useState(false);


    const handleScreenSize = () => {

        const width = window.innerWidth

        if (width !== screenWidth) {
            setScreenWidth(width)
        }

    }

    useEffect(() => {
        window.addEventListener('resize', handleScreenSize);
    }, [])


    useEffect(() => {
        if (screenWidth < 460 || (screenWidth >= 600 && screenWidth <= 773)) {
            setFilter(true)
        } else {
            setFilter(false)
            setAnchorEl(null)
        }
        window.addEventListener('resize', handleScreenSize);
    }, [screenWidth])

    useEffect(() => {
        if (selectedYearMonth.month !== '' && selectedYearMonth.year !== '') {
            handleCloseFilter()
        }
    }, [selectedYearMonth])
    return (
        <div className='main'>
            <div className='main-container'>
                <div className='heading heading-center-space-between height-40'>
                    <h2 className='single-line-text'>Attendance Summary</h2>
                    <div className='user-header-sub-container'>
                        {!showFilter ?
                            <div className='year-month-picker'>
                                <FormControl fullWidth >
                                    <InputLabel id="demo-simple-select-label">Year</InputLabel>
                                    <Select
                                        name='year'
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Year"
                                        value={selectedYearMonth.year}
                                        onChange={onMenuChange}
                                        sx={{ height: '30px' }}
                                    >{
                                            salarySlipYear.map((item: any, index: number) => {
                                                return <MenuItem value={item.value} key={index}>{item.name}</MenuItem>
                                            })
                                        }

                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Month</InputLabel>
                                    <Select
                                        name='month'
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Month"
                                        value={selectedYearMonth.month}
                                        onChange={onMenuChange}
                                        sx={{ height: '30px' }}
                                    >
                                        {
                                            detailsYearField.salarySlipMonth.map((item: any, index: number) => {
                                                return <MenuItem value={item.value} key={index}>{item.name}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl></div> :
                            <div className='filter-date-picker'
                                onClick={handleOpenFilter}
                                aria-controls={openFilterModal ? 'fade-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openFilterModal ? 'true' : undefined}
                            >
                                < FilterAltOutlinedIcon style={{ color: 'var(--primaryColor)' }} />
                                <label style={{ fontSize: '10px', marginTop: '9px', color: 'var(--primaryColor)', fontWeight: 'bold' }}>Filter</label>
                            </div>}
                        <Menu
                            id="fade-menu"
                            MenuListProps={{
                                'aria-labelledby': 'fade-button',
                            }}
                            anchorEl={anchorEl}
                            open={openFilterModal}
                            onClose={handleCloseFilter}
                            TransitionComponent={Fade}

                        >

                            <MenuItem className='date-pickers'>
                                <FormControl fullWidth >
                                    <InputLabel id="demo-simple-select-label">Year</InputLabel>
                                    <Select
                                        name='year'
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Year"
                                        value={selectedYearMonth.year}
                                        onChange={onMenuChange}
                                        sx={{ height: '30px' }}
                                    >{
                                            salarySlipYear.map((item: any, index: number) => {
                                                return <MenuItem value={item.value} key={index}>{item.name}</MenuItem>
                                            })
                                        }

                                    </Select>
                                </FormControl>
                            </MenuItem>
                            <MenuItem className='pickers'>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Month</InputLabel>
                                    <Select
                                        name='month'
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Month"
                                        value={selectedYearMonth.month}
                                        onChange={onMenuChange}
                                        sx={{ height: '30px' }}
                                    >
                                        {
                                            detailsYearField.salarySlipMonth.map((item: any, index: number) => {
                                                return <MenuItem value={item.value} key={index}>{item.name}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </MenuItem>
                        </Menu>
                    </div>
                </div>
                <div className='cal-40'>
                    <AgGridContainer rowData={filterRowData} columnDefs={columnDefs} />
                </div>
            </div>

        </div>
    )
}

export default UserLopAttendanceSummary
import { Grid } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import ButtonWrapper from '../../../widgets/button/button'
import ModalWrapper from '../../../widgets/modal/modal'
import AddCandidate from '../../../componant/addCandidate/addCandidate'
import AgGridContainer from '../../../componant/gridContainer/gridContainer'
import { Button, Fade, Menu } from '@mui/material'
import './onBoarding.css'
import { ButtonList } from 'sweetalert/typings/modules/options/buttons'
import swal from 'sweetalert';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import LightTooltip from '../../../widgets/tooltip/tooltip'
import ScheduleIcon from '@mui/icons-material/Schedule';
import ScheuleCandidate from '../../../componant/scheduleCandidate/scheduleCandidate'
import { downloadLeaveAttachment, getAppliedCandidate } from '../../../api-services/employee-services'
import HistoryIcon from '@mui/icons-material/History';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { TokenService } from '../../../services/token.service'
import { Role } from '../../../model/token-model/role.model'
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import { OnboardingStatusModel } from '../../../model/onboarding-status-model/onboarding-status-model'
import AddFeedback from '../../../componant/addFeedback/addFeedback'

interface Candidate {
    candidateId: number;
    name: string;
    emailId: string;
    contactNo: string;
    resume: string;
    status: string;
    referral: any[];
    referralComment: string;
    actionStatus: string | null;
    round: number;
    scheduleDate: string;
    interviewer: { name: string; employee_id: string }[];
}

const OnBoarding = () => {
    const newCandidate: any = [
        // {
        //     candidateId: 1, name: 'Biswajit Kumar Bera', emailId: 'biswajit@gmail.com', contactNo: '1234567890', resume: 'xyz.pdf', status: 'Pass', referral: [], referralComment: '', actionStatus: 'feedback', round: 1, scheduleDate: 'Tue Aug 13 2024 01:05:00 GMT+0530 (India Standard Time)', interviewer: [
        //         { name: 'Testa', employeeId: 'TTPL00022' }
        //     ]
        // },
        // {
        //     candidateId: 2, name: 'Biswajit1', emailId: 'biswajit1@gmail.com', contactNo: '1234567890', resume: 'xyz.pdf', status: 'Interview Schedule For round 1', referral: [
        //         { name: 'Arjun Shaw', employee_id: 'TTPL00015' }], referralComment: 'Hi', actionStatus: 'schedule', round: 1, scheduleDate: 'Tue Aug 13 2024 01:05:00 GMT+0530 (India Standard Time)', interviewer: [{ name: 'Subhendu', employee_id: 'TTPL001' }]
        // },
        { candidateId: 3, name: 'Biswajit22', emailId: 'biswajit2@gmail.com', contactNo: '1234567890', resume: 'xyz.pdf', status: 'Applied', referral: '', referralComment: '', actionStatus: null, round: '', scheduleDate: '', interviewer: [{ name: '', employee_id: '' }] },
        // { candidateId: 4, name: 'Biswajit3', emailId: 'biswajit3@gmail.com', contactNo: '1234567890', resume: 'xyz.pdf', status: 'Applied', referral: [], referralComment: '', actionStatus: 'schedule', round: 1, scheduleDate: 'Tue Aug 13 2024 01:05:00 GMT+0530 (India Standard Time)', interviewer: [{ name: 'Subhendu', employee_id: 'TTPL001' }] },
        // {
        //     candidateId: 5, name: 'Biswajit4', emailId: 'biswajit4@gmail.com', contactNo: '1234567890', resume: 'xyz.pdf', status: 'Applied', referral: [
        //         { name: 'Arjun Shaw', employee_id: 'TTPL00015' }], referralComment: '', actionStatus: 'schedule', round: 1, scheduleDate: 'Tue Aug 13 2024 01:05:00 GMT+0530 (India Standard Time)', interviewer: [{ name: 'Subhendu', employee_id: 'TTPL001' }]
        // },
        // { candidateId: 6, name: 'Biswajit5', emailId: 'biswajit5@gmail.com', contactNo: '1234567890', resume: 'xyz.pdf', status: 'Pass', referral: [], referralComment: '', actionStatus: 'schedule', round: 1, scheduleDate: 'Tue Aug 13 2024 01:05:00 GMT+0530 (India Standard Time)', interviewer: [{ name: 'Subhendu', employee_id: 'TTPL001' }] },
        // { candidateId: 7, name: 'Biswajit6', emailId: 'biswajit6@gmail.com', contactNo: '1234567890', resume: 'xyz.pdf', status: 'Interview Schedule For round 1', referral: [], referralComment: '', actionStatus: 'schedule', round: 1, scheduleDate: 'Tue Aug 13 2024 01:05:00 GMT+0530 (India Standard Time)', interviewer: [{ name: 'Testa', employee_id: 'TTPL00022' }] },
        // {
        //     candidateId: 8, name: 'Biswajit7', emailId: 'biswajit7@gmail.com', contactNo: '1234567890', resume: 'xyz.pdf', status: 'Interview Schedule For Round 2', referral: [
        //         { name: 'Arjun Shaw', employee_id: 'TTPL00015' }], referralComment: 'Hiiiiiiiiiii iiiiiiiiiiiiiiiii iiiiiiiii', actionStatus: null, round: 1, scheduleDate: 'Tue Aug 13 2024 01:05:00 GMT+0530 (India Standard Time)', interviewer: [{ name: 'Subhendu', employee_id: 'TTPL001' }]
        // }
    ]
    const interviewScheduleCandidate: any = [
        { candidateId: 1, name: 'Biswajit Kumar Bera', emailId: 'biswajit@gmail.com', contactNo: '1234567890', resume: 'xyz.pdf', status: 'Interview Schedule', referral: [], referralComment: '', actionStatus: 'schedule', round: 1, scheduleDate: 'Tue Aug 13 2024 01:05:00 GMT+0530 (India Standard Time)', interviewer: [{ name: 'Testa', employee_id: 'TTPL00022' }] },
        {
            candidateId: 2, name: 'Biswajit1', emailId: 'biswajit1@gmail.com', contactNo: '1234567890', resume: 'xyz.pdf', status: 'Applied', referral: [
                { name: 'Arjun Shaw', employee_id: 'TTPL00015' }], referralComment: 'Hi', actionStatus: 'schedule', round: 1, scheduleDate: 'Tue Aug 13 2024 01:05:00 GMT+0530 (India Standard Time)', interviewer: [{ name: 'Subhendu', employee_id: 'TTPL001' }]
        },
    ]
    const statusOrder: string[] = [OnboardingStatusModel.INTERVIEW_SCHEDULE, OnboardingStatusModel.APPLIED, OnboardingStatusModel.PASS];
    const token = TokenService.extractToken()
    const [rowData, setRowData] = useState<any[]>([])
    // const [newCandidate, setnewCandidate] = useState<any[]>([])
    const [openScheduleModal, setOpenScheduleModal] = useState<boolean>(false)
    const [scheduleCandidateDetails, setScheduleCandidateDetails] = useState({})
    const [isEditable, setEditable] = useState<boolean>(false)
    const [isReschedule, setIsReschedule] = useState<boolean>(false)
    const [editCandidate, setEditCandidate] = useState({})
    const [openModal, setOpenModal] = useState<boolean>(false)
    const [openFeedbackModal, setOpenFeedbackModal] = useState<boolean>(false)
    const handleOpenModal = () => {
        setOpenModal(true)
    }
    const handleCloseModal = () => {
        setOpenModal(false)
        setEditable(false)
    }
    const handleAddCandidate = () => {
        handleOpenModal()
    }

    const handleOpenScheduleModal = () => {
        setOpenScheduleModal(true)
    }
    const handleCloseScheduleModal = () => {
        setOpenScheduleModal(false)
    }

    const handleOpenFeedbackModal = () => {
        setOpenFeedbackModal(true)
    }

    const handleCloseFeedbackModal = () => {
        setOpenFeedbackModal(false)
    }

    console.log(editCandidate, "editCandidateeditCandidateeditCandidateeditCandidate")

    const onDownloadAttachment = (e: any, id: any, fileName: any, name: any) => {
        const filesName = `${name}_${fileName}`
        downloadLeaveAttachment(id).then((response: any) => {
            if (response?.data) {
                console.log(response)
                const linkSource = window.URL.createObjectURL(response.data);
                const downloadLink = document.createElement("a");
                downloadLink.href = linkSource;
                downloadLink.download = filesName;
                downloadLink.click();
            }
        })
    }



    const columnDefs = [
        {
            headerName: 'Name',
            field: 'name',
            pinned: 'left',
            minWidth: 100,
            maxWidth: 200,
            flex: 1,
            resizable: true,
            filter: true,
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
        },
        {
            headerName: 'Email Id',
            field: 'emailId',
            minWidth: 150,
            flex: 1,
            resizable: true,
            filter: true,
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
        },
        {
            headerName: 'Contact No',
            field: 'contactNo',
            minWidth: 150,
            flex: 1,
            resizable: true,
            filter: true,
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
        },
        {
            headerName: 'Resume',
            field: 'resume',
            minWidth: 150,
            flex: 1,
            resizable: true,
            filter: true,
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
            cellRenderer: (params: any) => {
                let content;
                if (params.data.attachment !== 'NA') {
                    const parts = params.data.resume.split('_');
                    const filename = parts[parts.length - 1].trim();
                    const fileNames = filename;
                    content = (
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <div
                                style={{ cursor: 'pointer' }}
                                onClick={(e) => onDownloadAttachment(e, params.data.leaveId, filename, params.data.name)}
                            >{fileNames}</div>
                        </div>
                    );
                } else {
                    content = (
                        <div className='leave-review-field-container-no-file'>
                            {params.data.attachment}
                        </div>
                    );
                }

                return (
                    <div style={{ width: '100%' }}>
                        {content}
                    </div>
                );
            }
        },
        {
            headerName: 'Status',
            field: 'status',
            minWidth: 150,
            flex: 1,
            resizable: true,
            filter: true,
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
        },
        {
            headerName: 'Reffered',
            field: 'referral',
            minWidth: 150,
            flex: 1,
            resizable: true,
            filter: true,
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
            cellRenderer: (params: any) => {
                console.log(params, "PAramssssssss")
                let name;
                if (params.data.referral.length > 0) {
                    name = params.data.referral[0].name
                    console.log(name)
                }

                return <>
                    <div>{name}</div>
                </>
            }
        },
        {
            headerName: 'How To Know',
            field: 'referralComment',
            minWidth: 150,
            flex: 1,
            resizable: true,
            filter: true,
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
        },
        {
            headerName: 'Action',
            field: '',
            lockPinned: true,
            width: token?.roles === Role.USER ? 90 : 145,
            cellRenderer: (params: any) => {
                return <>
                    <div className='candidate-button-container'>

                        {

                            token?.roles !== Role.USER ? <>
                                {
                                    /*Schedule icon and reschedule icon*/
                                    params.data.actionStatus !== 'schedule' ? <LightTooltip title='Schedule' placement='top' >
                                        <ScheduleIcon style={{ fontSize: '18px', cursor: 'pointer', color: 'var(--primaryColor1)' }} onClick={(e) => onSchedule(e, params)} />
                                    </LightTooltip> : <LightTooltip title='Reschedule' placement='top' >
                                        <HistoryIcon style={{ fontSize: '18px', cursor: 'pointer', color: 'var(--primaryColor1)' }} onClick={() => onReschedule(params)} />
                                    </LightTooltip>

                                }
                                {
                                    /*Edit Icon and select Icon*/
                                    params.data.actionStatus !== 'feedback' ? <ButtonWrapper buttonType='edit' className={params.data.actionStatus === null ? 'edit-employee-button cursor-pointer' : 'disable'} tooltipTitle={params.data.actionStatus === null ? "Edit" : 'Disable'} tooltipPlacement='top' onClick={() => onEdit(params)} /> : <LightTooltip title='Select' placement='top'><CheckCircleOutlineIcon style={{ fontSize: '18px', cursor: 'pointer', color: 'var(--successColor)', flexBasis: '23px', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={() => onSelect(params)} /></LightTooltip>
                                }
                                {
                                    /*Hold Icon */
                                    <LightTooltip title={params.data.actionStatus === 'feedback' ? 'Hold' : 'Disable'} placement='top'>
                                        <HourglassEmptyIcon style={{ fontSize: '18px', cursor: params.data.actionStatus === null ? 'pointer' : undefined, color: 'var(--secondaryColor)' }} className={params.data.actionStatus === 'feedback' ? '' : 'disable'} onClick={() => onHold(params)} />
                                    </LightTooltip>
                                }
                                {
                                    /*Reject Icon*/
                                    <LightTooltip title='Reject' placement='top' >
                                        <HighlightOffIcon style={{ color: 'red', fontSize: '18px', cursor: 'pointer' }} onClick={() => {
                                            swal({
                                                title: "Are you sure?",
                                                text: `Do you want to reject this Candidate?`,
                                                icon: "warning",
                                                buttons: true as unknown as ButtonList | (string | boolean)[] | undefined,
                                                dangerMode: true,
                                            })
                                                .then((willDelete) => {
                                                    if (willDelete !== null) {
                                                        swal("This Candidate has been Rejected!", {
                                                            icon: "success",
                                                        });
                                                        onReject()
                                                    }
                                                });
                                        }} />
                                    </LightTooltip>
                                }
                                {

                                    params.data.actionStatus === 'schedule' && params.data.interviewer.some((interviewer: { employee_id: any }) => interviewer.employee_id === token?.employee_id) && <LightTooltip title='Feedback' placement='top'>
                                        <ChatBubbleOutlineIcon style={{ fontSize: '18px', cursor: 'pointer', color: 'var(--purpleColor)' }} onClick={() => onFeedback(params)} />
                                    </LightTooltip>
                                }
                            </> :
                                <>
                                    {
                                        <>
                                            <LightTooltip title='Reschedule' placement='top' >
                                                <HistoryIcon style={{ fontSize: '18px', cursor: 'pointer', color: 'var(--primaryColor1)' }} onClick={() => onReschedule(params)} />
                                            </LightTooltip>
                                            <LightTooltip title='Feedback' placement='top'>
                                                <ChatBubbleOutlineIcon style={{ fontSize: '18px', cursor: 'pointer', color: 'var(--purpleColor)' }} onClick={() => onFeedback(params)} />
                                            </LightTooltip>
                                        </>

                                    }
                                </>}
                    </div>
                </>
            }
        }
    ]


    useEffect(() => {
        if (token?.roles !== Role.USER) {
            getAppliedCandidate().then((result: any) => {
                console.log(result, 'New Candidateeeeeeeeeeeee')
                if (result.data.success) {
                    // setnewCandidate(result.data.payload)
                }
            })
        } else {

        }
    }, [])

    const onFeedback = (params: any) => {
        handleOpenFeedbackModal()
    }

    const onSelect = (params: any) => {
        console.log('Select')
    }

    const onHold = (params: any) => {
        if (params.data.actionStatus === 'feedback') {
            console.log('On Hold')
        }
    }

    const onSchedule = (event: any, scheduleCandidate: any) => {
        handleOpenScheduleModal()
        setScheduleCandidateDetails(scheduleCandidate.data)
        setIsReschedule(false)
    }

    const onReschedule = (params: any) => {
        setScheduleCandidateDetails(params.data)
        setIsReschedule(true)
        handleOpenScheduleModal()
    }

    const onEdit = (params: any) => {
        if (params.data.actionStatus === null) {
            handleOpenModal()
            setEditCandidate(params.data)
            setEditable(true)
        }
    }

    const onReject = () => {

    }

    useEffect(() => {

        if (token?.roles !== Role.USER && newCandidate.length > 0) {
            const sortedCandidates = newCandidate.sort((a: { interviewer: { employee_id: string | undefined }[]; actionStatus: string; status: string }, b: { interviewer: { employee_id: string | undefined }[]; actionStatus: string; status: string }) => {
                // First, sort by interviewer and actionStatus 'schedule'
                if (a.interviewer[0].employee_id === token?.employee_id && a.actionStatus === 'schedule' &&
                    !(b.interviewer[0].employee_id === token?.employee_id && b.actionStatus === 'schedule')) {
                    return -1;
                }
                if (!(a.interviewer[0].employee_id === token?.employee_id && a.actionStatus === 'schedule') &&
                    b.interviewer[0].employee_id === token?.employee_id && b.actionStatus === 'schedule') {
                    return 1;
                }

                // Then, sort by status 'Interview Schedule'

                const aIsInterviewSchedule = a.status.includes(OnboardingStatusModel.INTERVIEW_SCHEDULE)
                const bIsInterviewSchedule = b.status.includes(OnboardingStatusModel.INTERVIEW_SCHEDULE)

                if (aIsInterviewSchedule && !bIsInterviewSchedule) {
                    return -1;
                }
                if (!aIsInterviewSchedule && bIsInterviewSchedule) {
                    return 1;
                }

                // Then, sort by status 'Applied'
                if (a.status === 'Applied' && b.status !== 'Applied') {
                    return -1;
                }
                if (a.status !== 'Applied' && b.status === 'Applied') {
                    return 1;
                }

                // Finally, sort by status 'Pass'
                if (a.status === 'Pass' && b.status !== 'Pass') {
                    return -1;
                }
                if (a.status !== 'Pass' && b.status === 'Pass') {
                    return 1;
                }

                return 0; // If all criteria are the same, maintain original order
            });

            // console.log(newCandidate);



            // console.log(sortedCandidates, "Sorted Dataaaaaaaaaa")

            // setRowData(newCandidate)
            setRowData(sortedCandidates)
        } else {
            // setRowData(interviewScheduleCandidate)
        }
    }, [])

    console.log(newCandidate, "New Candidate state")

    const getRowStyle = (params: any) => {
        if (token?.roles !== Role.USER) {
            const isInterviewer = params.data.interviewer.some((interviewer: { employee_id: string | undefined }) => interviewer.employee_id === token?.employee_id)

            if (isInterviewer && params.data.status.includes(OnboardingStatusModel.INTERVIEW_SCHEDULE)) {
                return {
                    backgroundColor: 'var(--lightSuccessColor)'
                }
            } else if (params.data.status.includes(OnboardingStatusModel.INTERVIEW_SCHEDULE)) {
                return {
                    backgroundColor: 'var(--lightSuccessColor1)'
                }
            } else if (params.data.status.includes(OnboardingStatusModel.APPLIED)) {
                return {
                    backgroundColor: 'rgba(33, 150, 243, 0.1)'
                }
            }
        }



    };

    return (
        <div className='main'>
            <div className="main-container">
                <div className='heading heading-center-space-between height-40'>
                    <Grid item xs={6} sm={6}>
                        <h2>Candidate List</h2>
                    </Grid>
                    {<Grid item xs={6} sm={6} className='flex-end-center'>
                        <ButtonWrapper buttonType='circle' className='circular-add-button' tooltipTitle='Add Candidate' tooltipPlacement='left' onClick={handleAddCandidate} />
                    </Grid>}
                </div>
                <div className='sub-container cal-40'>
                    <AgGridContainer rowData={rowData} columnDefs={columnDefs} rowStyle={getRowStyle} />
                </div>

            </div>
            <ModalWrapper open={openModal} close={handleOpenModal} children={<AddCandidate onClose={handleCloseModal} data={editCandidate} isEditable={isEditable} />} />
            <ModalWrapper open={openScheduleModal} close={handleOpenScheduleModal} children={<ScheuleCandidate onClose={handleCloseScheduleModal} scheduleCandidate={scheduleCandidateDetails} isReschedule={isReschedule} />} />
            <ModalWrapper open={openFeedbackModal} close={handleCloseFeedbackModal} children={<AddFeedback onClose={handleCloseFeedbackModal} />} />
        </div>

    )
}

export default OnBoarding
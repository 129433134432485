import { Outlet } from 'react-router'
import { Stack } from '@mui/material'
import SideBar from '../../componant/layout/sideBar'

const User = () => {
    return (
        <>
            <SideBar>
                <Stack style={{ height: "90vh" }}>
                    <Outlet />
                </Stack>
            </SideBar>
        </>
    )
}

export default User
import React, { useEffect, useState } from 'react'
import './teamManagement.css'
import AddTeamProject from '../../../componant/addTeamProject/addTeamProject';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import CustomTeamDetailsCell from '../../custom-team-details-cell-render/customTeamDetailsCell';
import FetchApi from '../../../fetch-api/fetch-api';
import { useSelector } from 'react-redux';
import ButtonWrapper from '../../../widgets/button/button';
import ModalWrapper from '../../../widgets/modal/modal';
import AgGridContainer from '../../../componant/gridContainer/gridContainer';
import { formateHifDate } from '../../../util_service/dateFormater/dateFormater';
import CustomCellRender from '../../custom-cell-render/custom-cell-render';
import { deleteTeam } from '../../../api-services/employee-services';
import { SnackbarService } from '../../../services/snackBehaviorSubject/snackBehaviorSubject';
import { getColumnDef } from '../../../util_service/filterGridColumnDefination/filterGridColumnDefination';
import CustomFilter from '../../../componant/customFilter/customFilter';

const TeamManagement = () => {
    const snackbarService = SnackbarService()
    const state = useSelector((state: any) => state.team)
    const fetchApi = FetchApi()
    const [actionStatus, setActionStatus] = useState<boolean>(false)
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [rawData, setRawData] = useState([])
    const [selectedRow, setSelectedRow] = useState(null)
    const [colDef, setColDef] = useState<any[]>([])


    const handleOpen = () => {
        setOpenModal(true)
    }

    const handleClose = () => {
        setOpenModal(false)
        setSelectedRow(null)
    };

    const onCreateTeam = () => {
        setOpenModal(true)
        setActionStatus(false)
    }

    useEffect(() => {
        fetchApi.fetchAdminTeam()
    }, [])
    useEffect(() => {

        if (state?.admin_team !== null) {
            setRawData(state.admin_team)
        } else {
            setRawData([])
        }
    }, [state])

    useEffect(() => {
        if (state.team_config.length > 0) {
            const filteredColumnDefs = getColumnDef(columnDefs, state.team_config)
            setColDef(filteredColumnDefs)
        }
    }, [state])



    const columnDefs: any = [
        {
            headerName: "S.No",
            field: "sno",
            resizable: true,
            width: 70,
            flex: 1,
            pinned: 'left',
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Team Name",
            field: "teamName",
            minWidth: 200,
            flex: 1,
            resizable: true,
            filter: true,
            pinned: 'left',
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            lockPinned: true,
            cellClass: 'flex-start-center',

        },
        {
            headerName: "Project Name",
            field: "projectName",
            minWidth: 110,
            flex: 1,
            filter: true,
            resizable: true,
            pinned: 'left',
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Client",
            field: "projectClient",
            minWidth: 200,
            flex: 1,
            filter: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        },
        {
            headerName: "Details",
            field: "projectDetails",
            minWidth: 200,
            flex: 1,
            filter: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        },
        {
            headerName: "Start Date",
            field: "projectStartDate",
            minWidth: 130,
            flex: 1,
            filter: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
            cellRenderer: (params: any) => {
                const formattedValue = formateHifDate(params.value);
                return formattedValue;
            }
        },
        {
            headerName: "End Date",
            field: "projectDeliveryDate",
            minWidth: 130,
            flex: 1,
            filter: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
            cellRenderer: (params: any) => {
                const formattedValue = formateHifDate(params.value);
                return formattedValue;
            }
        },
        {
            headerName: "Demo Date",
            field: "projectDemoDate",
            minWidth: 130,
            flex: 1,
            filter: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
            cellRenderer: (params: any) => {
                const formattedValue = params.value ? formateHifDate(params.value) : null;
                return formattedValue;
            }
        },
        {
            headerName: "Project Leader",
            field: "projectLead",
            minWidth: 200,
            flex: 1,
            resizable: true,
            filter: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        },
        {
            headerName: "Frontend Member",
            field: "projectFrontEndMembers",
            minWidth: 170,
            flex: 1,
            lockPinned: true,
            filter: 'agTextColumnFilter',
            valueGetter: (params: any) => {
                return params.data.projectFrontEndMembers
                    .map((member: any) => member.name)
                    .join(', ');
            },
            cellRenderer: (params: any) => (
                <CustomTeamDetailsCell value={params} actionStatus={true} title={"Frontend Members"} />
            )

        },
        {
            headerName: "Backend Member",
            field: "projectBackEndMembers",
            minWidth: 170,
            flex: 1,
            lockPinned: true,
            filter: 'agTextColumnFilter',
            valueGetter: (params: any) => {
                return params.data.projectBackEndMembers
                    .map((member: any) => member.name)
                    .join(', ');
            },
            cellRenderer: (params: any) => (
                <CustomTeamDetailsCell value={params} title={"Backend Members"} />
            )

        },
        {
            headerName: "Team Member",
            field: "teamMembers",
            minWidth: 200,
            flex: 1,
            resizable: true,
            filter: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
            cellRenderer: (params: any) => (
                <CustomTeamDetailsCell value={params} title={"Team Members"} />
            )

        },
        {
            headerName: "Status",
            field: "projectWorkingStatus",
            minWidth: 200,
            flex: 1,
            filter: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        },
        {
            headerName: "Remarks",
            field: "projectRemark",
            minWidth: 200,
            flex: 1,
            resizable: true,
            filter: true,
            lockPinned: true,
            cellClass: 'flex-start-center',
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
        },
        {
            headerName: "Action",
            field: "",
            width: 100,
            lockPinned: true,
            cellClass: 'flex-start-center',
            cellRenderer: (params: any) => (
                <CustomCellRender value={params} onDelete={handleDeleteTeam} onEdit={handleEditTeam} />
            )

        }
    ]

    const handleDeleteTeam = (team: any) => {
        deleteTeam(team.projectId).then((response: any) => {
            if (response?.data.success) {
                snackbarService.next({ data: { open: true, severity: 'success', message: response.data.message } })
                fetchApi.fetchAdminTeam()

            } else {
                snackbarService.next({ data: { open: true, severity: 'error', message: response.data.message } })
            }
        })
    }

    const handleEditTeam = (team: any) => {
        setSelectedRow(team)
        setOpenModal(true)
        setActionStatus(true)
    }


    return (
        <div className='main'>
            <div className='main-container'>
                <div className='heading heading-center-space-between height-40'>
                    <h2>Team Management</h2>
                    <div className='flex-end-center'>
                        <ButtonWrapper buttonType='circle' className='circular-add-button' onClick={onCreateTeam} tooltipPlacement='left' tooltipTitle='Create Team' />
                    </div>
                    <ModalWrapper open={openModal} close={handleOpen} children={<AddTeamProject onClose={handleClose} actionStatus={actionStatus} data={selectedRow} />} />
                </div>
                <div className='cal-40'>
                    <AgGridContainer rowData={rawData} columnDefs={colDef} />
                </div>

            </div>
        </div >
    )
}

export default TeamManagement
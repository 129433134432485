import { useNavigate, } from 'react-router-dom';
import './App.css';
import AllRoutes from './routes/all-routes';
import { useEffect, useState } from 'react';
import { setNavigate } from './api-services/api-services';
import { DrawerService, LoaderService, SnackbarService } from './services/snackBehaviorSubject/snackBehaviorSubject';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import SnackbarWidget from './widgets/snackbar/snackbar';
import SideBar from './componant/layout/sideBar';
import jwt from 'jwt-decode';
import { JwtModel } from './model/token-model/token.model';
import globalRouter from './routes/global-router';
import Loader from './widgets/loader/loader';
import FetchApi from './fetch-api/fetch-api';
import { TokenService } from './services/token.service';
import { Role } from './model/token-model/role.model';
import SwipeableTemporaryDrawer from './widgets/drawer/drawer';

type Anchor = 'top' | 'left' | 'bottom' | 'right';


interface SnackbarServiceProps {
  data: {
    open: boolean,
    severity?: string,
    message?: string
  }
}

interface DrawerServiceProps {
  data: {
    open: boolean,
    anchor: Anchor,
    children: React.ReactNode
  }
}

function App() {
  const navigate = useNavigate()
  globalRouter.navigate = navigate;
  const [loader, setLoader] = useState<boolean>(false)
  const [drawer, setDrawer] = useState<DrawerServiceProps>({ data: { open: false, anchor: 'right', children: null } })
  const [snackbar, setSnackbar] = useState<SnackbarServiceProps>({ data: { open: false, severity: '', message: '' } })
  useEffect(() => {
    setNavigate(navigate)
  }, [navigate])



  const loaderService = LoaderService()
  const snackbarService = SnackbarService()
  const drawerService = DrawerService()
  useEffect(() => {
    loaderService.subscribe((subscribeValue: boolean) => setLoader(subscribeValue))
  }, [loaderService])

  useEffect(() => {
    snackbarService?.subscribe((subscribeValue: SnackbarServiceProps) => {
      setSnackbar((preav: any) => {
        return { ...preav, data: { open: subscribeValue.data.open, severity: subscribeValue.data.severity, message: subscribeValue.data.message } }
      })
    })

  }, [snackbarService])

  useEffect(() => {
    drawerService.subscribe((subscribeValue: DrawerServiceProps) => {
      setDrawer((preav: DrawerServiceProps) => {
        return { ...preav, data: { open: subscribeValue.data.open, anchor: subscribeValue.data.anchor, children: subscribeValue.data.children } }
      })
    })
  }, [drawerService])







  return (
    <div className="App">
      <AllRoutes />
      <Loader loader={loader} />
      <SnackbarWidget data={{ open: snackbar.data.open, severity: snackbar.data.severity, message: snackbar.data.message }} />
      <SwipeableTemporaryDrawer data={{ open: drawer.data.open, anchor: drawer.data.anchor, children: drawer.data.children }} />

    </div>
  );
}

export default App;

import React, { useEffect, useState } from 'react'
import './edit-employee.css'
import { Controller, useForm } from 'react-hook-form';
import { Button, Checkbox, Grid, MenuItem, Select, Stack, useMediaQuery } from '@mui/material'
import { BsInfo } from "react-icons/bs";
import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Tooltip, IconButton } from '@mui/material';
import { detailsField } from '../../services/employeeFieldType';
import { editEmployeeDetails, getEmployee } from '../../api-services/employee-services'
import FetchApi from '../../fetch-api/fetch-api';
import { SnackbarService } from '../../services/snackBehaviorSubject/snackBehaviorSubject';
import ButtonWrapper from '../../widgets/button/button';




const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        border: '1px solid rgb(39,112,222)',
        borderRadious: "2px",
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 1, 0, 1)',
        // boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

interface EditEmployeeProps {
    editableEmployee: any;
    renderEditEmployee: boolean;
    handleRenderEditEmployee: (params: boolean) => void;
    employeeName: string
}


const EditEmployee = (props: EditEmployeeProps) => {
    const isWidth995 = useMediaQuery('(max-width:996px)');
    const snackbarService = SnackbarService()
    const fetchApi = FetchApi()
    const fieldDetails = detailsField()
    const [fieldValue, setFieldValue] = useState<any>({
        employeeRole: 'Admin'
    })
    let salaryStructure: any[] = []
    let initialFields = [
        {
            name: "grossSalary",
            formula: (grossSalary: any) => { return grossSalary },
            value: 0,
            label: 'Gross Salary',
            type: "number",
            isIncluded: true,
            isEnable: true
        },
        {
            name: "BasicSalary",
            formula: (grossSalary: any) => { return grossSalary * Number(percentageValue.BasicSalary) / 100 },
            value: 0,
            label: 'Basic Salary',
            type: "number",
            percentage: [
                { label: 'Percentage', type: "number", name: 'BasicSalaryPercentage', isEnable: false, tag: true }
            ],
            isIncluded: true
        },
        {
            name: "HouseRentAllowance",
            formula: function (x: any) { return (x * Number(percentageValue.BasicSalary) / 100) * Number(percentageValue.HouseRentAllowance) / 100 },
            value: 0,
            label: 'House Rent Allowance',
            type: "number",
            isIncluded: true
        },
        {
            name: "DearnessAllowance",
            formula: function (x: any) { return (x * Number(percentageValue.BasicSalary) / 100) * Number(percentageValue.DearnessAllowance) / 100 },
            value: 0,
            label: 'Dearness Allowance',
            type: "number",
            isIncluded: true
        },
        {
            name: "MedicalAllowance",
            value: 0,
            label: 'Medical Allowance',
            type: "number",
            isIncluded: true
        },
        {
            name: "TravelAllowance",
            value: 0,
            label: 'Travel Allowance',
            type: "number",
            isIncluded: true
        },
        {
            name: "ProfessionalTaxs",
            formula: function (x: any) { return calculateProfessionalTax(x) },
            value: 0,
            label: 'Professional Taxs',
            type: "number",
            isIncluded: true
        },
        {
            name: "SpecialAllowance",
            formula: function (x: any) {
                return x - ((x * Number(percentageValue.BasicSalary) / 100) + ((x * Number(percentageValue.BasicSalary) / 100) * Number(percentageValue.HouseRentAllowance) / 100) + ((x * Number(percentageValue.BasicSalary) / 100) * Number(percentageValue.DearnessAllowance) / 100) + Number(getFieldValues('MedicalAllowance')) + Number(getFieldValues('TravelAllowance')))
            },
            value: 0,
            label: 'Special Allowance',
            type: "number",
            isIncluded: true
        },
        {
            name: "IncomeTax",
            value: 0,
            label: 'Income Tax',
            type: 'number',
            checkbox: { isEnable: true, tag: true },
            isIncluded: false
        },
        {
            name: "ProvidentFund",
            formula: (x: any) => { return x * Number(percentageValue.ProvidentFund) / 100 },
            value: 0,
            label: 'Provident Fund',
            type: 'number',
            checkbox: { isEnable: true, tag: true },
            isIncluded: false
        },
        {
            name: "EmployeesStateInsurance",
            formula: (x: any) => { return x * Number(percentageValue.EmployeesStateInsurance) / 100 },
            value: 0,
            label: 'Employees State Insurance',
            type: 'number',
            checkbox: { isEnable: true, tag: true },
            isIncluded: false
        },
        {
            name: "NetSalary",
            formula: (x: any) => { return x - calculateProfessionalTax(x) - Number(getFieldValues('IncomeTax')) - getFieldValues('ProvidentFund') - getFieldValues('EmployeesStateInsurance') },
            value: 0,
            label: 'Net Salary',
            type: 'number',
            isIncluded: true
        }
    ]
    const [grossSalary, setGrossSalary] = useState<any>()
    const [percentageValue, setPercentageValue] = useState<any>({
        BasicSalary: 40,
        HouseRentAllowance: 40,
        DearnessAllowance: 30,
        ProvidentFund: 12,
        EmployeesStateInsurance: 10,
    })
    const [salaryFieldValue, setSalaryFieldValue] = useState<any[]>([])

    const { control, handleSubmit, setValue, getValues } = useForm();
    const [equalAdressCheckboxStatus, setEqualAdressCheckboxStatus] = useState<boolean>(false)

    const indicatorField = [
        { name: 'BasicSalary' },
        { name: 'HouseRentAllowance' },
        { name: 'DearnessAllowance' },
        { name: 'ProvidentFund' },
        { name: 'EmployeesStateInsurance' },
    ]

    useEffect(() => {
        getEmployee(props.editableEmployee.employee_id).then((result: any) => {
            console.log(result)
            if (result.data.success) {
                initialFields.forEach((item) => {
                    item.value = result.data.payload.salaryDetails[item.name];
                    if (item.value !== 0) {
                        item.isIncluded = true;
                    }
                })
                percentageValue.BasicSalary = result.data.payload.salaryDetails['BasicPercentage']
                setPercentageValue(percentageValue)
            } else {

            }
            const otherDetailsArray = Object.keys(result.data.payload).reduce((acc: any, key: any) => {
                if (key !== 'salaryDetails') {
                    acc[key] = result.data.payload[key];
                }
                return acc;
            }, {});

            setFieldValue(otherDetailsArray)

        }).catch((err) => {

        });
    }, [])


    const calculateProfessionalTax = (grossSalarys: number) => {
        const grossSalary = Number(grossSalarys)
        switch (true) {
            case (10000 <= grossSalary && grossSalary <= 15000):
                return 110;
            case (15001 <= grossSalary && grossSalary <= 25000):
                return 130
            case (25001 <= grossSalary && grossSalary <= 40000):
                return 150
            case (grossSalary >= 40001):
                return 200
            default:
                return 0;
        }

    }
    const getFieldValues = (opt: string) => {
        if (salaryStructure.length > 0) {
            return salaryStructure.filter((a: any) => a.name === opt)[0].value
        }
        return 0
    }

    const handleSalaryField = (ev: any, item: any) => {
        let gross;
        item.value = Number(ev.target.value)
        if (item.name === 'grossSalary') {
            gross = Number(ev.target.value)
            setGrossSalary(Number(ev.target.value))
        }
        calculateFormula(salaryFieldValue, gross)
    }

    const calculateFormula = (fields?: any, gross?: number) => {
        let grossInc = gross ? gross : grossSalary
        setSalaryFieldValue((prevState: any) => {
            let newState = fields ? [...fields] : [...prevState]
            newState.map((a: any) => {
                if ((a.formula && !a.isEnable && !a.checkbox) || (a.formula && a.checkbox && a.isIncluded)) {
                    a.value = a.formula(grossInc)
                }
                else if ((a.formula && a.checkbox && !a.isIncluded)) {
                    a.value = 0
                }
            })
            salaryStructure = newState
            return newState
        });
    }

    const handleCheckBoxStatus = (e: any, item: any) => {
        salaryFieldValue.filter((a: any) => a.name === item.name)[0].isIncluded = e.target.checked
        salaryFieldValue.filter((a: any) => a.name === item.name)[0].value = 0
        calculateFormula(salaryFieldValue)
    }
    const onBasicPercentageChange = (e: any, item: any) => {
        percentageValue.BasicSalary = e.target.value
        setPercentageValue(percentageValue)
        setValue('BasicPercentage', percentageValue.BasicSalary)
        calculateFormula()
    }
    useEffect(() => {
        setGrossSalary(Number(props.editableEmployee.grossSalary))
        initialFields.filter((a: any) => a.name === 'grossSalary')[0].value = Number(props.editableEmployee.grossSalary)
        calculateFormula(initialFields, Number(props.editableEmployee.grossSalary))
    }, [])

    const handleField = (e: any) => {
        const { name, value, checked } = e.target
        if (name !== 'agendaRequired' && name !== 'workupdateRequired') {
            setFieldValue((d: any) => {
                return { ...d, [name]: value }
            })

            setValue(name, value)

        } else {
            if (checked) {
                setFieldValue((d: any) => {
                    return { ...d, [name]: checked }
                })
                setValue(name, checked)
            } else {
                setFieldValue((d: any) => {
                    return { ...d, [name]: checked }
                })
                setValue(name, checked)

            }
        }
        if (equalAdressCheckboxStatus) {
            hanldeSameAddressFill()
        }
    }

    const hanldeSameAddressFill = () => {
        setFieldValue((prevFieldValues: any) => ({
            ...prevFieldValues,
            Residential_City: prevFieldValues.Permanent_City,
            Residential_Country: prevFieldValues.Permanent_Country,
            Residential_Pincode: prevFieldValues.Permanent_Pincode,
            Residential_State: prevFieldValues.Permanent_State,
            Residential_Street: prevFieldValues.Permanent_Street,
        }));
    }

    const handleSameAddress = (e: any, values: any) => {
        const { value, checked } = e.target
        if (checked) {
            setEqualAdressCheckboxStatus(checked)
            hanldeSameAddressFill()
        } else {
            setEqualAdressCheckboxStatus(checked)
            setFieldValue((prevFieldValues: any) => ({
                ...prevFieldValues,
                Residential_City: "",
                Residential_Country: "",
                Residential_Pincode: "",
                Residential_State: "",
                Residential_Street: "",
            }));
        }
    }



    const onSubmit = (data: any) => {
        console.log(fieldValue)
        const updateSalary = salaryFieldValue.reduce((acc, item) => {
            acc[item.name] = item.value;
            return acc;
        }, {} as { [key: string]: number });
        updateSalary['BasicPercentage'] = percentageValue.BasicSalary
        fieldValue['salaryDetails'] = updateSalary
        editEmployeeDetails(fieldValue.employee_id, fieldValue).then((result: any) => {
            if (result.data.success) {
                fetchApi.fetchAllEmployee()
                snackbarService?.next({ data: { open: true, severity: 'success', message: result.data.message } })
            } else {
                snackbarService.next({ data: { open: true, severity: 'error', message: result.data.message } })
            }
        }).catch((err) => {

        });
    }
    return (
        <div className='edit-main-container'>
            <Grid container style={{ height: '100%' }}>
                <Grid xs={12} style={{ height: '58px' }}>
                    <h3 className='edit-employee-heading'><span>{` ${props.employeeName}`}</span></h3>
                </Grid>
                <Grid xs={12} style={{ height: 'calc(100% - 58px)' }}>
                    <div className='edit-sub-container'>
                        <form onSubmit={handleSubmit(onSubmit)} style={{ height: '90%', paddingRight: '40px', paddingLeft: '40px' }}>
                            <Stack className='form-field-stack-container'>
                                <Grid container style={{ height: "100%" }}>
                                    <Grid xs={12} sm={6} md={isWidth995 ? 6 : 4} style={{ height: '100%' }}>
                                        <div className='edit-sub-form-container'>
                                            <div className='edit-form-container'>
                                                <h4>Personal Details</h4>
                                                <div className='edit-field-container'>

                                                    {
                                                        fieldDetails.personalDetailsFields.map((item: any, index: number) => {
                                                            return <div key={index}>
                                                                <label>{item.label}</label>
                                                                <Controller
                                                                    name={item.name}
                                                                    control={control}
                                                                    defaultValue=""
                                                                    render={({ field, fieldState }) => (
                                                                        <div>
                                                                            <input
                                                                                type={item.type}
                                                                                {...field}
                                                                                value={fieldValue[item.name] !== null && fieldValue[item.name] !== undefined ? String(fieldValue[item.name]) : ""}
                                                                                onChange={handleField}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        })
                                                    }
                                                    <div>
                                                    </div>
                                                    <div>
                                                        <p>Permanent Address:</p>
                                                        {
                                                            fieldDetails.permanentAddressField.map((item: any, index: number) => {
                                                                return <div key={index}>
                                                                    <label>{item.label}</label>
                                                                    <Controller
                                                                        name={item.name}
                                                                        control={control}
                                                                        defaultValue=""
                                                                        render={({ field, fieldState }) => (
                                                                            <div>
                                                                                <input
                                                                                    type={item.type}
                                                                                    {...field}
                                                                                    value={fieldValue[item.name] !== null && fieldValue[item.name] !== undefined ? String(fieldValue[item.name]) : ""}
                                                                                    onChange={handleField}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    />
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                    <div>
                                                        <div style={{ display: "flex" }}>
                                                            <p>Residential Address</p>
                                                            <Checkbox
                                                                sx={{ marginTop: "-3%" }}
                                                                onChange={(e) => handleSameAddress(e, getValues())}
                                                                checked={equalAdressCheckboxStatus}
                                                            />
                                                        </div>
                                                        {
                                                            fieldDetails.residentialAddressField.map((item: any, index: number) => {
                                                                return <div key={index}>
                                                                    <label>{item.label}</label>
                                                                    <Controller
                                                                        name={item.name}
                                                                        control={control}
                                                                        defaultValue=""
                                                                        render={({ field, fieldState }) => (
                                                                            <div>
                                                                                <input
                                                                                    type={item.type}
                                                                                    {...field}
                                                                                    value={fieldValue[item.name] !== null && fieldValue[item.name] !== undefined ? String(fieldValue[item.name]) : ""}
                                                                                    disabled={equalAdressCheckboxStatus}
                                                                                    onChange={handleField}
                                                                                />
                                                                            </div>
                                                                        )}
                                                                    />
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={6} md={4} style={{ height: '100%' }}>
                                        <div className='edit-sub-form-container'>
                                            <div className="edit-form-container">
                                                <h4>Employee Details</h4>
                                                <div className='edit-field-container'>
                                                    {
                                                        fieldDetails.employeeDetailsField.map((item: any, index: number) => {
                                                            return <div key={index} className={item.type === 'checkbox' ? "edit-employee-details-container" : item.type === 'select' ? "edit-employee-details-container1" : ""} >
                                                                {
                                                                    item.type !== 'checkbox' ? <label>{item.label}</label> : ""
                                                                }
                                                                {
                                                                    item.type === 'select' ? <>
                                                                        <Controller
                                                                            name={item.name}
                                                                            control={control}
                                                                            defaultValue=""
                                                                            render={({ field, fieldState }) => (
                                                                                <>
                                                                                    <Select
                                                                                        type={item.type}
                                                                                        {...field}
                                                                                        value={fieldValue[item.name] !== null && fieldValue[item.name] !== undefined ? String(fieldValue[item.name]) : ""}
                                                                                        onChange={handleField}
                                                                                        style={{ height: '45px', marginTop: '3px', marginBottom: '10px' }}

                                                                                    >
                                                                                        {
                                                                                            item.options?.map((element: any, ind: number) => {
                                                                                                return <MenuItem key={ind} value={element.value}>
                                                                                                    {element.label}
                                                                                                </MenuItem>
                                                                                            })
                                                                                        }

                                                                                    </Select>

                                                                                </>
                                                                            )}
                                                                        />
                                                                    </> : <>
                                                                        <Controller
                                                                            name={item.name}
                                                                            control={control}
                                                                            defaultValue=""
                                                                            render={({ field, fieldState }) => (
                                                                                <div className={item.type === 'checkbox' ? "edit-employee-details-sub-container" : ""}>
                                                                                    <input
                                                                                        type={item.type}
                                                                                        {...field}
                                                                                        checked={(item.type === 'checkbox' && fieldValue[item.name] !== undefined) ? (fieldValue[item.name]) : ''}
                                                                                        value={fieldValue[item.name] !== null && fieldValue[item.name] !== undefined ? String(fieldValue[item.name]) : ""}
                                                                                        onChange={handleField}
                                                                                    />
                                                                                </div>
                                                                            )}
                                                                        />
                                                                    </>
                                                                }


                                                                {
                                                                    item.type === 'checkbox' ? <label>{item.label}</label> : ""
                                                                }
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                    <Grid xs={12} sm={6} md={4} style={{ height: '100%' }}>
                                        <div className='edit-sub-form-container'>
                                            <div className="edit-form-container">
                                                <h4>Salary Details</h4>
                                                <div className='edit-field-container'>
                                                    {
                                                        salaryFieldValue && salaryFieldValue.map((item: any, index: number) => {
                                                            return <div key={index} className={item.name == 'BasicSalary' ? 'basic-salary-container' : ''} >{
                                                                <>
                                                                    <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '5px' }}>
                                                                        <div style={{ flexBasis: '300px' }}>
                                                                            <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                                                <div >
                                                                                    <label>{item.label}</label>
                                                                                    {
                                                                                        indicatorField.map((ele: any, ind: any) => {
                                                                                            return <React.Fragment key={ind}>
                                                                                                {
                                                                                                    ele.name === item.name ?
                                                                                                        <LightTooltip title={ele.name === 'BasicSalary' || ele.name === 'ProvidentFund' || ele.name === 'EmployeesStateInsurance' ? `${percentageValue[ele.name]}% of Gross Salary` : `${percentageValue[ele.name]}% of Basic Salary`} placement='right-start' ><IconButton ><BsInfo className='info-icon' /></IconButton></LightTooltip>
                                                                                                        : ""
                                                                                                }
                                                                                            </React.Fragment>
                                                                                        })
                                                                                    }
                                                                                </div>
                                                                                {
                                                                                    item.checkbox ? <Checkbox
                                                                                        checked={item.isIncluded}
                                                                                        onChange={(e) => handleCheckBoxStatus(e, item)}
                                                                                    /> : ''
                                                                                }
                                                                            </div>

                                                                            <Controller
                                                                                name={item.name}
                                                                                control={control}
                                                                                defaultValue=""
                                                                                render={({ field, fieldState }) => (
                                                                                    <div >
                                                                                        <input
                                                                                            type={item.type}
                                                                                            name={item.name}
                                                                                            value={item.value === null ? "" : item.value}
                                                                                            disabled={(item.formula && !item.isEnable) || (item.checkbox && !item.isIncluded) ? true : false}
                                                                                            onChange={(e) => handleSalaryField(e, item)
                                                                                            }
                                                                                        />
                                                                                    </div>
                                                                                )}
                                                                            />
                                                                        </div>
                                                                        {
                                                                            item.name == 'BasicSalary' &&

                                                                            <div style={{ display: 'flex', flexDirection: 'column', flexBasis: '30px', marginTop: '6px' }}>
                                                                                <label>Percentage</label>
                                                                                <Controller
                                                                                    name='basicpercentage'
                                                                                    control={control}
                                                                                    defaultValue=""
                                                                                    render={({ field, fieldState }) => (
                                                                                        <div style={{ display: 'flex', paddingTop: '2px' }}>
                                                                                            <input
                                                                                                value={percentageValue.BasicSalary}
                                                                                                placeholder='Basic salary percentage'
                                                                                                onChange={(e) => onBasicPercentageChange(e, item)}
                                                                                            />
                                                                                            <span style={{ marginTop: '15px', paddingLeft: '4px' }}>%</span>
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </div>

                                                                        }

                                                                    </div>
                                                                </>
                                                            }
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </Stack>

                            <Stack className='form-button-stack-container'>
                                <div className='edit-employee-button-container'>
                                    <ButtonWrapper title='Update' variant='contained' type='submit' backgroundColor='var(--primaryColor)' />
                                </div>
                            </Stack>
                        </form>
                    </div>
                </Grid>
            </Grid>
        </div >
    )
}

export default EditEmployee
import React, { useState, useEffect } from 'react'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import swal from 'sweetalert';
import { ButtonList } from 'sweetalert/typings/modules/options/buttons';
import './cell-renderer.css'
import { Button } from '@mui/material';
import { approvedLeave, approvedWorkFromHome, canceledLeave, rejectLeave, rejectWorkFromHome } from '../../api-services/employee-services';
import { FcApproval } from "react-icons/fc";
import { AiFillCloseCircle, AiOutlineRedo } from "react-icons/ai";
import './approve-reject-cell.css'
import ButtonWrapper from '../../widgets/button/button';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { SnackbarService } from '../../services/snackBehaviorSubject/snackBehaviorSubject';
import FetchApi from '../../fetch-api/fetch-api';
import { TokenService } from '../../services/token.service';


const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        border: '1px solid rgb(39,112,222)',
        borderRadious: "2px",
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        // boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

interface ApproveRejectCellProps {
    value?: any;
    handleEditEmployees?: (param: any) => void;
    actionStaus?: boolean;
    onButtonAction?: boolean;
}


const ApproveRejectCell: React.FC<ApproveRejectCellProps> = ({ value, handleEditEmployees, actionStaus, onButtonAction }) => {
    const [leaveStatus, setLeaveStatus] = useState("")
    const snackbar = SnackbarService()
    const token = TokenService.extractToken()
    const fetchApi = FetchApi()
    useEffect(() => {
        if (value) {

            setLeaveStatus(value.leaveStatus)

        }
    }, [value])

    const handleApprove = (para: any) => {
        if (!actionStaus) {
            approvedLeave(para).then((response: any) => {
                if (response?.data.success) {
                    if (handleEditEmployees)
                        handleEditEmployees(true)
                }
            })
        } else {
            approvedWorkFromHome(para).then((response: any) => {
                if (response?.data.success) {
                    if (handleEditEmployees)
                        handleEditEmployees(true)
                }
            }).catch((err) => {

            });
        }

    }

    const handleReject = (para: any) => {
        if (!actionStaus) {
            rejectLeave(para).then((response: any) => {
                if (response?.data.success) {
                    if (handleEditEmployees)
                        handleEditEmployees(true)
                }
            })
        } else {
            rejectWorkFromHome(para).then((response: any) => {
                if (response?.data.success) {
                    if (handleEditEmployees)
                        handleEditEmployees(true)
                }
            }).catch((err) => {

            });
        }

    }

    const onCancel = (value: any) => {
        canceledLeave(value.leaveId).then((response: any) => {
            if (response.data.success) {
                fetchApi.getSingleUserLeave(token?.sub)
                snackbar.next({ data: { open: true, severity: 'success', message: response.data.message } })
            }
        })

    }

    return (
        <>
            {
                leaveStatus === 'approved' || leaveStatus === 'rejected' ? <div style={{ display: 'flex', alignItems: 'center' }}>{leaveStatus === 'approved' ? <FcApproval /> : <AiFillCloseCircle color='red' />}{leaveStatus}</div> :
                    onButtonAction && leaveStatus === 'Pending' ?
                        <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', gap: '20px' }}><div><AiOutlineRedo color="var(--secondaryColor)" />{leaveStatus}</div>
                            <LightTooltip title='Cancel' placement='right' >
                                <HighlightOffIcon style={{ color: 'red', fontSize: '17px', cursor: 'pointer' }} onClick={() => onCancel(value)} />
                            </LightTooltip>
                        </div> :
                        < div className='pending-button-container'>
                            <Button size="small" variant="contained"
                                className='background-success button-height'
                                onClick={() => {
                                    swal({
                                        title: "Are you sure?",
                                        text: `Do you want to approve this leave?`,
                                        icon: "warning",
                                        buttons: true as unknown as ButtonList | (string | boolean)[] | undefined,
                                        dangerMode: true,
                                    })
                                        .then((willDelete) => {
                                            if (willDelete !== null) {
                                                swal("This leave has been Approved!", {
                                                    icon: "success",
                                                });
                                                if (value.hasOwnProperty('leaveId')) {
                                                    handleApprove(value.leaveId);
                                                } else if (value.hasOwnProperty('wfhId')) {
                                                    handleApprove(value.wfhId);
                                                }
                                            }
                                        });
                                }}
                            >Accept</Button>
                            <Button size="small" variant="contained"
                                className='background-danger button-height'
                                onClick={() => {
                                    swal({
                                        title: "Are you sure?",
                                        text: `Do you want to reject this leave?`,
                                        icon: "warning",
                                        buttons: true as unknown as ButtonList | (string | boolean)[] | undefined,
                                        dangerMode: true,
                                    })
                                        .then((willDelete) => {
                                            if (willDelete !== null) {
                                                swal("This leave has been Rejected!", {
                                                    icon: "info",
                                                });

                                                if (value.hasOwnProperty('leaveId')) {
                                                    handleReject(value.leaveId)
                                                } else if (value.hasOwnProperty('wfhId')) {
                                                    handleReject(value.wfhId);
                                                }
                                            }
                                        });
                                }}
                            >Reject</Button>
                        </div >

            }
        </>
    )
}

export default ApproveRejectCell;


import React, { useEffect, useState } from 'react'
import './userAttendance.css'
import { border, display, Stack, textAlign } from '@mui/system'
import { getAttendance } from '../../../api-services/employee-services'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { detailsField } from '../../../services/employeeFieldType'
import CustomDetailsCellRender from '../../custom-details-cell-render/custom-details-cell-render'
import AgGridContainer from '../../../componant/gridContainer/gridContainer'
import { calculateOvertime } from '../../../util_service/overtimeCalculation/overtimeCalculation'
import { formateTimeZone } from '../../../util_service/timeZoneFormater/timeZoneFormater'
import { searchByYearMonth } from '../../../util_service/searchByYearMonth/searchByYearMonth';
import { salarySlipYear } from '../../../util_service/generateYear/generateYear';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Fade, Menu } from '@mui/material';
import { formateHifDate, formateHifDates } from '../../../util_service/dateFormater/dateFormater';

interface ClockSateProps {
    clockIn: string,
    clockOut: string,
    clockInDate: string,
    workingHours: string,
    employee_id: string
}

interface SelectedYearMonth {
    month: string,
    year: string,
    monthName: string
}

const UserAttendance = () => {
    const detailsYearField = detailsField()
    const [selectedYearMonth, setSelectedYearMonth] = useState<SelectedYearMonth>({
        month: '',
        year: '',
        monthName: ''
    })
    const [clockState, setClockState] = useState<ClockSateProps[]>([])
    const [filterRowData, setFilterRowData] = useState<any[]>([])


    console.log(filterRowData, "Filter Data")

    useEffect(() => {
        getAttendance().then((response: any) => {
            if (response?.data.success) {
                response.data.payload.forEach((item: any, index: number) => {
                    const date = item?.clockInDate.split('/');
                    const formatedDate = `${date[0]}-${date[1]}-${date[2]}`
                    item.date = formatedDate;
                    delete item.clockInDate;
                });

            }
            if (response !== null) {
                const overTimes = calculateOvertime(response.data.payload)
                setClockState(overTimes)
            } else {
                setClockState([])
            }
        })
    }, [])

    console.log(clockState, "Clock State")

    useEffect(() => {
        const currentDate = new Date()
        const year = currentDate.getFullYear()
        const month = currentDate.getMonth() + 1
        setSelectedYearMonth((preav: any) => {
            return { ...preav, month: `${month}`, year: `${year}`, monthName: getMonthName(month) }
        })
    }, [])

    useEffect(() => {
        if (clockState.length > 0 && selectedYearMonth.year !== '' && selectedYearMonth.month !== '') {
            const filterData = searchByYearMonth(clockState, selectedYearMonth.year, selectedYearMonth.month).sort((a: { date: string; }, b: { date: string; }) => {
                const dateA = new Date(a.date.split('-').reverse().join('-')).getTime();
                const dateB = new Date(b.date.split('-').reverse().join('-')).getTime();
                return dateB - dateA;
            });
            console.log(filterData, "FilterDataaaaaaaaaaaaaaa")
            setFilterRowData(filterData)
        }
    }, [clockState, selectedYearMonth])

    console.log(filterRowData, "Filter Data")
    console.log(clockState, "Clock State")

    const getMonthName = (monthNumber: any) => {
        const date = new Date();
        date.setMonth(monthNumber - 1);
        return date.toLocaleString('en-US', { month: 'long' });
    }

    const onMenuChange = (e: SelectChangeEvent) => {
        const { name, value } = e.target
        setSelectedYearMonth((preav: any) => {
            return { ...preav, [name]: value.toString(), monthName: getMonthName(name === "month" ? value : selectedYearMonth.month) }
        })
    }

    const [columnDefs]: any[] = useState([
        // {
        //     headerName: "S.No",
        //     field: "sno",
        //     minWidth: 100,
        //     flex: 1,
        //     resizable: true,
        //     filter: true,
        // },
        {
            headerName: "Date",
            field: "date",
            width: 120,
            minWidth: 110,
            flex: 1,
            resizable: true,
            filter: true,
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            lockPinned: true,
            cellRenderer: (params: any) => {
                const formattedValue = formateHifDates(params.value);
                return formattedValue;
            },
            cellClass: 'flex-start-center',
            pinned: 'left'
        },
        {
            headerName: "Clock In",
            field: "clockIn",
            minWidth: 120,
            flex: 1,
            filter: true,
            lockPinned: true,
            valueFormatter: (params: any) => formateTimeZone(params.value),
        },
        {
            headerName: "Clock Out",
            field: "clockOut",
            minWidth: 120,
            flex: 1,
            filter: true,
            lockPinned: true,
            valueFormatter: (params: any) => formateTimeZone(params.value),
        },
        {
            headerName: "Total Hours",
            field: "loginHours",
            minWidth: 135,
            flex: 1,
            lockPinned: true,
            filter: true,
        },
        {
            headerName: "Break Hour",
            field: "breakHours",
            minWidth: 135,
            flex: 1,
            lockPinned: true,
            filter: true,
        },
        {
            headerName: "Working Hour",
            field: "workingHours",
            minWidth: 150,
            flex: 1,
            lockPinned: true,
            filter: true,
        },
        {
            headerName: "Over Time",
            field: "overtimeHours",
            minWidth: 150,
            flex: 1,
            filter: true,
            lockPinned: true,
        },
        {
            headerName: "Action",
            field: "",
            minWidth: 100,
            flex: 1,
            lockPinned: true,
            cellRenderer: (params: any) => (<CustomDetailsCellRender data={params} />)
        }
    ])
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const openFilterModal = Boolean(anchorEl);

    const handleOpenFilter = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleCloseFilter = () => {
        setAnchorEl(null);
    };

    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [showFilter, setFilter] = useState(false);


    const handleScreenSize = () => {

        const width = window.innerWidth

        if (width !== screenWidth) {
            setScreenWidth(width)
        }

    }

    useEffect(() => {
        window.addEventListener('resize', handleScreenSize);
    }, [])


    useEffect(() => {
        if (screenWidth < 460 || (screenWidth >= 600 && screenWidth <= 773)) {
            setFilter(true)
        } else {
            setFilter(false)
            setAnchorEl(null)
        }
        window.addEventListener('resize', handleScreenSize);
    }, [screenWidth])

    useEffect(() => {
        if (selectedYearMonth.month !== '' && selectedYearMonth.year !== '') {
            handleCloseFilter()
        }
    }, [selectedYearMonth])

    return (
        <>
            <div className='main'>
                <div className="main-container">
                    <div className='heading heading-center-space-between height-40'>
                        <h2>Attendance List</h2>
                        <div className='salary-slip-search-container'>
                            {!showFilter ? <div className='year-month-picker'>
                                <FormControl fullWidth >
                                    <InputLabel id="demo-simple-select-label">Year</InputLabel>
                                    <Select
                                        name='year'
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Year"
                                        value={selectedYearMonth.year}
                                        onChange={onMenuChange}
                                        sx={{ height: '30px' }}
                                    >{
                                            salarySlipYear.map((item: any, index: number) => {
                                                return <MenuItem value={item.value} key={index}>{item.name}</MenuItem>
                                            })
                                        }

                                    </Select>
                                </FormControl>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Month</InputLabel>
                                    <Select
                                        name='month'
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="Month"
                                        value={selectedYearMonth.month}
                                        onChange={onMenuChange}
                                        sx={{ height: '30px' }}
                                    >
                                        {
                                            detailsYearField.salarySlipMonth.map((item: any, index: number) => {
                                                return <MenuItem value={item.value} key={index}>{item.name}</MenuItem>
                                            })
                                        }
                                    </Select>
                                </FormControl>
                            </div> :
                                <div className='filter-date-picker'
                                    onClick={handleOpenFilter}
                                    aria-controls={openFilterModal ? 'fade-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openFilterModal ? 'true' : undefined}
                                >
                                    < FilterAltOutlinedIcon style={{ color: 'var(--primaryColor)' }} />
                                    <label style={{ fontSize: '10px', marginTop: '9px', color: 'var(--primaryColor)', fontWeight: 'bold' }}>Filter</label>
                                </div>
                            }

                            <Menu
                                id="fade-menu"
                                MenuListProps={{
                                    'aria-labelledby': 'fade-button',
                                }}
                                anchorEl={anchorEl}
                                open={openFilterModal}
                                onClose={handleCloseFilter}
                                TransitionComponent={Fade}

                            >

                                <MenuItem className='date-pickers'>
                                    <FormControl fullWidth >
                                        <InputLabel id="demo-simple-select-label">Year</InputLabel>
                                        <Select
                                            name='year'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Year"
                                            value={selectedYearMonth.year}
                                            onChange={onMenuChange}
                                            sx={{ height: '30px' }}
                                        >{
                                                salarySlipYear.map((item: any, index: number) => {
                                                    return <MenuItem value={item.value} key={index}>{item.name}</MenuItem>
                                                })
                                            }

                                        </Select>
                                    </FormControl>
                                </MenuItem>
                                <MenuItem className='date-pickers'>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">Month</InputLabel>
                                        <Select
                                            name='month'
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="Month"
                                            value={selectedYearMonth.month}
                                            onChange={onMenuChange}
                                            sx={{ height: '30px' }}
                                        >
                                            {
                                                detailsYearField.salarySlipMonth.map((item: any, index: number) => {
                                                    return <MenuItem value={item.value} key={index}>{item.name}</MenuItem>
                                                })
                                            }
                                        </Select>
                                    </FormControl>
                                </MenuItem>
                            </Menu>

                        </div>
                    </div>
                    <div className='cal-40'>
                        <AgGridContainer rowData={filterRowData} columnDefs={columnDefs} />
                    </div>
                </div >

            </div >
        </>
    )
}

export default UserAttendance
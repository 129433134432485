import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DownloadIcon from '@mui/icons-material/Download';
import './leave-review.css';
import { useSelector } from 'react-redux';
import ApproveRejectCell from '../../../custom-cell-render/approve-reject-cell';
import { useParams } from 'react-router';
import { getAllLeaveOfEmployee } from '../../../../api-services/employee-services';
import jwt from 'jwt-decode';
import { JwtModel } from '../../../../model/token-model/token.model';
import { FcApproval } from "react-icons/fc";
import { AiFillCloseCircle, AiOutlineRedo } from "react-icons/ai";
import { minWidth, Stack, width } from '@mui/system';
import LeaveReviewContainer from '../../../../componant/gridContainer/gridContainer';
import CustomDetailsCellRender from '../../../custom-details-cell-render/custom-details-cell-render';
import AgGridContainer from '../../../../componant/gridContainer/gridContainer';
import { TokenService } from '../../../../services/token.service';
import CustomTeamDetailsCell from '../../../custom-team-details-cell-render/customTeamDetailsCell';
import { formateHifDate } from '../../../../util_service/dateFormater/dateFormater';
import FetchApi from '../../../../fetch-api/fetch-api';
import sortFromDateToDate from '../../../../util_service/sortFromDateTodate/sortFromDateToDate';


const UserLeaveReview = () => {
    const [rowData, setRowData] = useState([]);
    const state = useSelector((state: any) => state.userLeaveDetails);
    console.log(state, "StateStateStateStateState")
    const params: any = useParams();
    const fetchApi = FetchApi()
    const extractToken = TokenService.extractToken()

    useEffect(() => {
        fetchApi.getSingleUserLeave(extractToken?.sub)
    }, [])

    useEffect(() => {
        const sortedData = sortFromDateToDate(state.leaveReview)
        setRowData(sortedData)
    }, [state]);




    const columnDefs: any = [
        // {
        //     headerName: "Name",
        //     field: "name",
        //     resizable: true,
        //     filter: true,
        // },

        {
            headerName: "From Date",
            field: "fromDate",
            resizable: true,
            minWidth: 100,
            flex: 1,
            filter: true,
            pinned: 'left',
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            lockPinned: true,
            cellRenderer: (params: any) => {
                const formattedValue = formateHifDate(params.value);
                return formattedValue;
            },
            cellClass: 'flex-start-center',
        },
        {
            headerName: "To Date",
            field: "toDate",
            resizable: true,
            filter: true,
            minWidth: 100,
            flex: 1,
            pinned: 'left',
            wrapText: true,
            autoHeight: true,
            wrapHeaderText: true,
            autoHeaderHeight: true,
            lockPinned: true,
            cellRenderer: (params: any) => {
                const formattedValue = formateHifDate(params.value);
                return formattedValue;
            },
            cellClass: 'flex-start-center',
        },
        {
            headerName: "Days",
            field: "noOfDays",
            lockPinned: true,
            filter: true,
            minWidth: 100,
            flex: 1
        },
        {
            headerName: "Leave Type",
            field: "leaveType",
            filter: true,
            minWidth: 130,
            flex: 1,
            lockPinned: true,
        },
        {
            headerName: "Session",
            field: "session",
            minWidth: 130,
            flex: 1,
            lockPinned: true,
            filter: true,
        },
        {
            headerName: "Schedule ",
            field: "leavePeriod",
            lockPinned: true,
            filter: true,
            minWidth: 130,
            flex: 1,
        },
        {
            headerName: "Reason",
            field: "reasonForLeave",
            lockPinned: true,
            minWidth: 130,
            flex: 1,
            cellRenderer: (params: any) => (
                <CustomDetailsCellRender data={params} flag={false} title='Reason For Leave' />
            ),
        },
        {
            headerName: "Attachment",
            field: "attachment",
            minWidth: 130,
            flex: 1,
            filter: true,
            lockPinned: true,
            cellRenderer: (params: any) => {
                let content;
                if (params.data.attachment !== 'NA') {
                    const parts = params.data.attachment.split('_');
                    const filename = parts[parts.length - 1].trim();
                    const fileNames = filename;
                    content = (
                        <div className='leave-review-field-container-file'>
                            <div className='leave-review-field-sub-container-file'>{fileNames}</div>
                        </div>
                    );
                } else {
                    content = (
                        <div className='leave-review-field-container-no-file'>
                            {params.data.attachment}
                        </div>
                    );
                }

                return (
                    <div style={{ width: '100%', height: '100%' }}>
                        {content}
                    </div>
                );
            },
        },
        {
            headerName: "Action",
            field: "leaveStatus",
            filter: true,
            lockPinned: true,
            width: 130,
            cellRenderer: (params: any) => (
                <>
                    < div style={{ height: '100%', display: 'flex' }}>
                        <ApproveRejectCell value={params.data} handleEditEmployees={handleEditEmployees} onButtonAction={true} />
                    </div >
                </>

            ),
        },
    ];

    const handleEditEmployees = () => {

    }

    return (
        <div className='main'>
            <div className='main-container'>
                <div className='heading heading-start-center height-40'>
                    <h2>Leave Review</h2>
                </div>
                <div className='cal-40'>
                    <AgGridContainer rowData={rowData} columnDefs={columnDefs} />
                </div>
            </div >
        </div >
    )
}

export default UserLeaveReview
export function searchByYearMonth(data: any, year: any, month: any) {
    const filteredData = data.filter((entry: any) => {
        const entryDate = entry.date.split('-');
        return parseInt(entryDate[1]) === parseInt(month) && parseInt(entryDate[2]) === parseInt(year);
    });
    filteredData.sort((a: any, b: any) => {
        const dateA = new Date(a.date.split('-').reverse().join('-'));
        const dateB = new Date(b.date.split('-').reverse().join('-'));

        return dateA.getTime() - dateB.getTime();
    });
    filteredData.filter((entry: any, index: number) => {
        entry.sno = index + 1;
    })
    return filteredData;

}
import React, { useEffect, useState } from 'react'
import './auditTask.css'
import { Collapse, Tooltip } from '@mui/material';
import ViewTimeline from '../viewTimeline/viewTimeline';
import FetchApi from '../../fetch-api/fetch-api';
import { TokenService } from '../../services/token.service';
import { getTaskHistory } from '../../api-services/employee-services';
import { Timeline } from 'antd';
import { styled } from '@mui/material/styles';
import { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { IconButton } from '@mui/material';
import { width } from '@mui/system';

interface HistoryProps {
    date?: string;
    singleTask?: any
}

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        border: '1px solid rgb(39,112,222)',
        borderRadious: "2px",
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 1, 0, 1)',
        // boxShadow: theme.shadows[1],
        fontSize: 11,
        width: "100px"
    },
}));

const History = (props: HistoryProps) => {
    console.log(props, "Props in history")
    const fetchApi = FetchApi()
    const token = TokenService.extractToken()
    const [collapseStatus, setCollapseStatus] = useState<number>(-1)
    const [data, setData] = useState<any[]>([])

    function groupTasksByProject(tasks: any[]) {


        return tasks.reduce((acc, task) => {
            let projectName = task.projectName || "Others";
            let project = acc.find((p: { projectName: any; }) => p.projectName === projectName);
            if (!project) {
                project = { projectName, taskDetails: [] };
                acc.push(project);
            }
            project.taskDetails.push(task);
            return acc;
        }, []);
    }

    const fetchData = (date: string) => {
        const currentDate = new Date(date);
        const startDate = currentDate.toISOString().split('.')[0] + ".000";
        if (token)
            getTaskHistory(token.sub, startDate).then((result: any) => {
                const groupByTaskData = groupTasksByProject(result.data.payload)
                setData(groupByTaskData)
                if (result.data.success) {

                }
            })
    }

    useEffect(() => {
        if (props.date) {
            fetchData(props.date)
        } else {
            const data = groupTasksByProject([props.singleTask])
            console.log(data, 'Dataaaaaaaaaaaaaa')
            setData(data)
        }
    }, [props])

    console.log(data, "Dataffffff")


    const onItemClick = (_e: React.MouseEvent<HTMLDivElement, MouseEvent>, key: number) => {
        if (collapseStatus === key) {
            setCollapseStatus(-1)
        } else {
            setCollapseStatus(key)
        }

    }

    return (
        <div className={`container-100 ${!props.singleTask ? 'audit-task-main-container' : 'audit-task-main-container-padding0'}`}>
            {
                data.map((item: any, index: number) => {
                    return <div className={!props.singleTask ? 'audit-task-sub-container' : 'audit-task-sub-container-margin0'} key={index}>
                        {!props.singleTask ? <h4>{item.projectName}</h4> : ''}
                        {
                            item.taskDetails.map((ele: any, ind: number) => {
                                return <div className={!props.singleTask ? 'task-item' : 'task-item-padding-right0'
                                } key={ind} >

                                    <div onClick={(e) => onItemClick(e, ele.id)} className='task-sub-item-container'>
                                        <Timeline
                                        >
                                            <Timeline.Item className='task-list-item'><LightTooltip title={ele.description} placement='top'>{ele.description}</LightTooltip></Timeline.Item>
                                        </Timeline>
                                    </div>
                                    <Collapse
                                        in={!props.singleTask ? collapseStatus === ele.id : true}
                                    >
                                        <div className='view-timeline-container'>
                                            <ViewTimeline item={ele} />
                                        </div>
                                    </Collapse>

                                </div>

                            })
                        }
                    </div>

                })
            }
        </div >

    )
}

export default History

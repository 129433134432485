import React from 'react'
import { AiOutlineRedo } from 'react-icons/ai'
import { FaCircle } from 'react-icons/fa'
import { MdOutlineHomeWork } from 'react-icons/md'
import { formateDateWithOutYear, formateHifDate } from '../../util_service/dateFormater/dateFormater'
import './dashboardCardBody.css'
import { ProjectStatusModel } from '../../model/project-status-model/project-status-model'

interface DashboardCardBodyItemProps {
    element: any,
    subElement: any
}

const DashboardCardBodyItem = (props: DashboardCardBodyItemProps) => {
    console.log(props, "Dashboard Body Props")
    return (


        <div className='card-item'>
            <div className='single-line-text'><span className={`${props.element.name === 'project' && props.subElement.projectDemoDate !== ''}`} >{props.subElement.name}</span></div>
            <div>
                {props.subElement && ((props.element.name === 'leave' || props.element.name === 'absent') && props.subElement.status) ?
                    <FaCircle className={props.element.name === 'absent' ? 'status-circle-red' : 'status-circle-yellow'} />
                    :
                    (props.element.name === 'pendingLeaveForApproval' && props.subElement.status === 'Pending' ?
                        <AiOutlineRedo className='status-outline-redo' />
                        : (props.element.name === 'workFromHome' && props.subElement.status === 'WFH' ? <MdOutlineHomeWork className='work-from-home-icon' /> :
                            (props.subElement.status ? (props.element.name === 'birthday' ? formateDateWithOutYear(props.subElement.status) : formateHifDate(props.subElement.projectDeliveryDate ? props.subElement.projectDeliveryDate : props.subElement.status))
                                : '')))
                }

            </div>
        </div>
    )
}

export default DashboardCardBodyItem

import React, { useEffect, useState } from 'react'
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import './salarySlip.css'
import { downloadSalarySlip, getEmployeeAllSalarySlip } from '../../../../api-services/employee-services';
import { SnackbarService } from '../../../../services/snackBehaviorSubject/snackBehaviorSubject';
import AgGridContainer from '../../../../componant/gridContainer/gridContainer';
import { TokenService } from '../../../../services/token.service';
import { salarySlipYear } from '../../../../util_service/generateYear/generateYear';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { Fade, Menu } from '@mui/material';

interface SelectedYearMonth {
  year: string,
}

export const SalarySlip = () => {
  const snackbarService = SnackbarService()
  const [rawData, setRawData] = useState([])
  const [selectedYear, setSelectedYear] = useState<SelectedYearMonth>({ year: '' })

  const extractToken = TokenService.extractToken()

  useEffect(() => {
    if (selectedYear.year !== '') {
      getEmployeeAllSalarySlip(selectedYear.year).then((res: any) => {
        console.log(res, "Salary slip response")

        if (res.data.success === true) {
          setRawData(res.data.payload)
        } else {
          setRawData(res.data.payload)
          snackbarService.next({ data: { open: true, severity: 'warning', message: `${res.data.message} please contact to responsible authority!` } })
        }

      }).catch((err: any) => {
        console.log(err)
      })
    }
  }, [selectedYear])
  useEffect(() => {
    const currentDate = new Date()
    const currentYear = currentDate.getFullYear()
    setSelectedYear((preav: any) => {
      return { ...preav, ['year']: currentYear.toString() }
    })
  }, [])
  const onDownloadSalarySlip = (params: any) => {
    const month = new Date(`${params.data.month} 1,${params.data.year}`).getMonth() + 1
    const date = `${month}/${params.data.year}`

    downloadSalarySlip(extractToken?.employee_id, date).then((res: any) => {
      if (res?.data) {
        const linkSource = window.URL.createObjectURL(res.data);
        const downloadLink = document.createElement("a");
        const fileName = `${extractToken?.name}_${date}_salarySlip`;
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
        snackbarService.next({ data: { open: true, severity: 'success', message: "Salary Slip Downloaded" } })
      }
    })

  }
  const columnDefs: any = [
    {
      headerName: "Year",
      field: "year",
      width: 422,
      minWidth: 100,
      flex: 1,
      filter: true,
    },
    {
      headerName: "Month",
      field: "month",
      width: 422,
      minWidth: 100,
      flex: 1,
      filter: true,
    },
    {
      headerName: "Action",
      field: "",
      minWidth: 100,
      flex: 1,
      cellRenderer: (params: any) => {
        return (
          <>
            <FileDownloadIcon style={{ color: 'var(--primaryColor)', marginTop: '10px', fontSize: '20px' }} onClick={() => onDownloadSalarySlip(params)} />

          </>
        )
      },
    }

  ]
  const onMenuChange = (e: SelectChangeEvent) => {
    const { value, name } = e.target
    setSelectedYear((preav: any) => {
      return { ...preav, [name]: value.toString() }
    })
  }

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openFilterModal = Boolean(anchorEl);

  const handleOpenFilter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [showFilter, setFilter] = useState(false);


  const handleScreenSize = () => {

    const width = window.innerWidth

    if (width !== screenWidth) {
      setScreenWidth(width)
    }

  }

  useEffect(() => {
    window.addEventListener('resize', handleScreenSize);
  }, [])


  useEffect(() => {
    if (screenWidth < 460 || (screenWidth >= 600 && screenWidth <= 773)) {
      setFilter(true)
    } else {
      setFilter(false)
      setAnchorEl(null)
    }
    window.addEventListener('resize', handleScreenSize);
  }, [screenWidth])

  useEffect(() => {
    if (selectedYear.year !== '') {
      handleCloseFilter()
    }
  }, [selectedYear])


  return (
    <div className='main'>
      <div className='main-container'>
        <div className='heading heading-center-space-between height-40'>
          <h2>Salary Slip</h2>
          <div className='user-salary-header-sub-container'>
            {
              !showFilter ?
                <div className='year-month-picker'>
                  <FormControl fullWidth >
                    <InputLabel id="demo-simple-select-label">Year</InputLabel>
                    <Select
                      name='year'
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Year"
                      value={selectedYear.year}
                      onChange={onMenuChange}
                      sx={{ height: '30px' }}
                    >{
                        salarySlipYear.map((item: any, index: number) => {
                          return <MenuItem value={item.value} key={index}>{item.name}</MenuItem>
                        })
                      }

                    </Select>
                  </FormControl>
                </div> :
                <div className='filter-date-picker'
                  onClick={handleOpenFilter}
                  aria-controls={openFilterModal ? 'fade-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openFilterModal ? 'true' : undefined}
                >
                  < FilterAltOutlinedIcon style={{ color: 'var(--primaryColor)' }} />
                  <label style={{ fontSize: '10px', marginTop: '9px', color: 'var(--primaryColor)', fontWeight: 'bold' }}>Filter</label>
                </div>
            }
            <Menu
              id="fade-menu"
              MenuListProps={{
                'aria-labelledby': 'fade-button',
              }}
              anchorEl={anchorEl}
              open={openFilterModal}
              onClose={handleCloseFilter}
              TransitionComponent={Fade}

            >
              <MenuItem className='date-pickers'>
                <FormControl fullWidth >
                  <InputLabel id="demo-simple-select-label">Year</InputLabel>
                  <Select
                    name='year'
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Year"
                    value={selectedYear.year}
                    onChange={onMenuChange}
                    sx={{ height: '30px' }}
                  >{
                      salarySlipYear.map((item: any, index: number) => {
                        return <MenuItem value={item.value} key={index}>{item.name}</MenuItem>
                      })
                    }

                  </Select>
                </FormControl>
              </MenuItem>
            </Menu>


          </div>
        </div>
        <div className='cal-40'>
          <div className='salaryslip-sub-container'>
            <h4>Salary Slip of {selectedYear.year}</h4>
            <div className='user-salary-grid-details-container'>
              <AgGridContainer rowData={rawData} columnDefs={columnDefs} editType='fullRow' suppressRowClickSelection={true} stopEditingWhenCellsLoseFocus={true} singleClickEdit={true} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

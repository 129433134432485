import { Box, Button, Checkbox, Modal, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import './account.css'
import ResetPassword from '../../../componant/reset-password/reset-password';
import jwt from 'jwt-decode';
import { JwtModel } from '../../../model/token-model/token.model';
import { getEmployee, getSingleEmployeeDetails, updateSingleEmployeeDetails } from '../../../api-services/employee-services';
import { detailsField } from '../../../services/employeeFieldType';
import { Controller, useForm } from 'react-hook-form';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { MdEditNote } from "react-icons/md";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { SnackbarService } from '../../../services/snackBehaviorSubject/snackBehaviorSubject';
import { modalStyle } from '../../../style/style';
import ButtonWrapper from '../../../widgets/button/button';
import ModalWrapper from '../../../widgets/modal/modal';
import { TokenService } from '../../../services/token.service';



const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    border: '1px solid rgb(39,112,222)',
    borderRadious: "2px",
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    // boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

const UserAccount = () => {
  const style = modalStyle()
  const snackbarService = SnackbarService()
  const fields = detailsField()
  const extractToken = TokenService.extractToken();
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const handleModalOpen = () => setModalOpen(true);
  const handleModalClose = () => setModalOpen(false);
  const onResetPassword = () => {
    handleModalOpen()
  }
  const [fieldValue, setFieldValue] = useState<any>({})
  const { control, handleSubmit, setValue, getValues, reset } = useForm();
  const [equalAdressCheckboxStatus, setEqualAdressCheckboxStatus] = useState<boolean>(false)
  const [enableEditingField, setEnableEditingField] = useState(true)

  const fetch = () => {
    getSingleEmployeeDetails(extractToken?.sub).then((response: any) => {
      if (response?.data.success)
        setFieldValue(response.data.payload)
    })
  }
  useEffect(() => {
    fetch()
  }, [])
  const hanldeSameAddressFill = () => {
    setFieldValue((prevFieldValues: any) => ({
      ...prevFieldValues,
      Residential_City: prevFieldValues.Permanent_City,
      Residential_Country: prevFieldValues.Permanent_Country,
      Residential_Pincode: prevFieldValues.Permanent_Pincode,
      Residential_State: prevFieldValues.Permanent_State,
      Residential_Street: prevFieldValues.Permanent_Street,
    }));
  }
  const handleSameAddress = (e: any) => {
    const { value, checked } = e.target
    if (checked) {
      setEqualAdressCheckboxStatus(checked)
      hanldeSameAddressFill()
    } else {
      setEqualAdressCheckboxStatus(checked)
      setFieldValue((prevFieldValues: any) => ({
        ...prevFieldValues,
        Residential_City: "",
        Residential_Country: "",
        Residential_Pincode: "",
        Residential_State: "",
        Residential_Street: "",
      }));
    }
  }
  const onFieldChange = (e: any) => {
    const { value, name } = e.target
    setFieldValue((prev: any) => {
      return { ...prev, [name]: value }
    })
    setValue(name, value)
    if (equalAdressCheckboxStatus) {
      hanldeSameAddressFill()
    }
  }
  const onSubmit = () => {
    snackbarService.next({ data: { open: true, severity: 'warning', message: 'Details updated please wait...' } })
    setEnableEditingField(true)
    updateSingleEmployeeDetails(extractToken?.sub, fieldValue).then((response: any) => {
      if (response?.data.success) {
        fetch()
        snackbarService.next({ data: { open: true, severity: 'success', message: 'Details updated Successfully' } })

      }
    })
  }

  const onEditClick = () => {
    setEnableEditingField(false)
  }
  return (
    <div className='main'>
      <div className='main-container'>
        <div className='heading4'>
          <h2>Account</h2>
          <div className='reset-button-container'>
            <ButtonWrapper id='submit-btn' title='CHANGE PASSWORD' onClick={onResetPassword} titleStyle='reset-button' />
          </div>
        </div>
        <ModalWrapper open={modalOpen} close={handleModalClose} children={extractToken && <ResetPassword onClose={handleModalClose} user={extractToken} />} />
        <div className='accounts-sub-container' >
          <div style={{ height: '100%' }} >
            <form style={{ height: '100%' }} onSubmit={handleSubmit(onSubmit)}>
              <div style={{ display: 'flex', gap: '10px', height: '25px' }}>
                <div className='edit-account-editing'>Personal Details</div>
                <ButtonWrapper buttonType='edit' onClick={onEditClick} />
              </div>
              <div className='main-form-container'>
                <div style={{ height: '100%' }}>
                  <div className='form-container-editing'>
                    <div style={{ height: '100%' }}>
                      {
                        fields.personalDetailsFields.map((item: any, index: number) => {
                          return (
                            <div key={index} style={{ marginTop: '8px' }}>
                              <label>{item.label}</label>
                              <Controller
                                name={item.name}
                                control={control}
                                defaultValue=""
                                render={({ field, fieldState }) => (
                                  <div>
                                    <input
                                      disabled={(item.name === 'name' || item.name === 'employee_id') ? true : enableEditingField}
                                      type={item.type}
                                      {...field}
                                      value={fieldValue[item.name] !== null && fieldValue[item.name] !== undefined ? String(fieldValue[item.name]) : ""}
                                      onChange={onFieldChange}
                                    />
                                  </div>
                                )}
                              />
                            </div>
                          )
                        })
                      }

                      <div style={{ marginTop: '15px' }}>
                        <p>Permanent Address:</p>
                        {
                          fields.permanentAddressField.map((item: any, index: number) => {
                            return (
                              <div key={index} style={{ marginTop: '8px' }}>
                                <label>{item.label}</label>
                                <Controller
                                  name={item.name}
                                  control={control}
                                  defaultValue=""
                                  render={({ field, fieldState }) => (
                                    <div>
                                      <input
                                        disabled={enableEditingField}
                                        type={item.type}
                                        {...field}
                                        value={fieldValue[item.name] !== null && fieldValue[item.name] !== undefined ? String(fieldValue[item.name]) : ""}
                                        onChange={onFieldChange}
                                      />
                                    </div>
                                  )}
                                />
                              </div>
                            )
                          })
                        }
                      </div>
                      <div style={{ marginTop: '15px' }}>
                        <div style={{ display: 'flex' }}>
                          <p>Residential Address:</p>
                          {!enableEditingField ?
                            <div style={{ display: 'flex' }}>
                              <div>
                                <input
                                  type='checkbox'
                                  style={{
                                    border: "1px solid red",
                                    height: "24px",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    fontSize: "38px",
                                    marginTop: '2px'
                                  }}
                                  onChange={(e) => handleSameAddress(e)}
                                />
                              </div>
                              <div style={{ fontSize: '10px', marginTop: '7px' }}>Same as Permanent Address</div>
                            </div>
                            : ''
                          }
                        </div>
                        {
                          fields.residentialAddressField.map((item: any, index: number) => {
                            return (
                              <div key={index} style={{ marginTop: '8px' }}>
                                <label>{item.label}</label>
                                <Controller
                                  name={item.name}
                                  control={control}
                                  defaultValue=""
                                  render={({ field, fieldState }) => (
                                    <div>
                                      <input
                                        disabled={enableEditingField || equalAdressCheckboxStatus}
                                        type={item.type}
                                        {...field}
                                        value={fieldValue[item.name] !== null && fieldValue[item.name] !== undefined ? String(fieldValue[item.name]) : ""}
                                        onChange={onFieldChange}
                                      />
                                    </div>
                                  )}
                                />
                              </div>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>

                  <div className='editing-button-container'>
                    <ButtonWrapper id='submit-btn' type='submit' title='Submit' variant='contained' />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

    </div >
  )
}

export default UserAccount;
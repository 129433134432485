function generateSalarySlipYears(startYear: number) {
    const currentYear = new Date().getFullYear();
    const salarySlipYear = [];

    for (let year = startYear; year <= currentYear + 1; year++) {
        salarySlipYear.push({ name: `${year}`, value: year });
    }

    return salarySlipYear
}

const startYear = 2022;

export const salarySlipYear = generateSalarySlipYears(startYear)
salarySlipYear.sort((a: any, b: any) => b.value - a.value)